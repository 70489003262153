import {
  IsInvalid,
  FormatSeriesTime,
  getPatientName,
  FormatSeriesDate,
} from 'helpers';
export const useStylesSplit = (theme, props) => ({
  root: {
    display: 'flex',
    // marginBottom: 32,
    border: '1px solid grey',
    borderRadius: 8,
    overflow: 'hidden',
    height: props ? props.height - 255 : null,
    width: '100%',
  },
  row: {
    width: '100%',
    height: '100%',
    // display: 'flex',
    // overflowY: 'auto',
    // overflowX: 'auto',
  },
  splitter: {
    '@global': {
      '.gutter': {
        backgroundColor: '#eee',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
      },
      '.gutter.gutter-horizontal': {
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==")',
        cursor: 'col-resize',
      },
      '.gutter.gutter-vertical': {
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=")',
        cursor: 'row-resize',
      },
    },
  },
});
export const columns_study = [
  { field: 'idStudy', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'Patient',
    headerName: 'Patient Name',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('Patient');
      if (value === undefined || !value) return '';
      return getPatientName(value, false);
    },
  },
  {
    field: 'PatientID',
    headerName: 'PatientID',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('Patient');
      if (value === undefined || !value) return '';
      const { PatientID } = value;
      if (PatientID === undefined || !PatientID) return '';
      return PatientID;
    },
  },
  {
    field: 'StudyDescription',
    headerName: 'Description',
    align: 'left',
  },
  {
    field: 'NumberOfStudyRelatedSeries',
    headerName: '#Series',
    align: 'left',
  },
  {
    field: 'NumberOfStudyRelatedInstances',
    headerName: '#Files',
    align: 'left',
  },
  {
    field: 'sequence',
    headerName: '#Sequences',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let series = params.getValue('Series');
      if (series === undefined || !series) return '0';
      let f = [];
      const sequences = series
        .map(x => x.sequence)
        .filter(x => x)
        .map(x => {
          if (f.indexOf(x.name) !== -1) return null;
          f.push(x.name);
          return x.name;
        })
        .filter(x => x)
        .join(', ');
      return sequences;
    },
  },
  {
    field: 'StudyDate',
    headerName: 'Dates',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('StudyDate');
      if (value === undefined || !value) return '';
      return FormatSeriesDate(value, 'yyyy/MM/DD');
    },
  },
  {
    field: 'StudyTime',
    headerName: 'Times',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('StudyTime');
      if (value === undefined || !value) return '';
      return FormatSeriesTime(value, 'h:mm a');
    },
  },
  {
    field: 'AccessionNumber',
    headerName: 'AccessionNumber',
    align: 'left',
    style: {
      minWidth: 200,
    },
  },
  {
    field: 'StudyInstanceUID',
    headerName: 'UIDs',
    align: 'left',
  },
];
