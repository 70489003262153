/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import {
  AddBlocks,
  BlockSetting,
  StatusbarBlockPipeline,
  ToolBarBlockProcess,
} from './components';
import './components/TabBlockPipeline.css';
import { drawBackgroundWithDots, initCanvasListener } from './components/utils';
import { IsInvalid } from 'helpers';
import { setDiagram } from 'redux-store/actions';

class TabBlockPipeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devicePixelRatio: window.devicePixelRatio || 1,
      blocks: [],
      mouseLeftPressed: false,
      isPanning: false,
      lastPanPoint: null,
      diagram: props.diagram
        ? props.diagram
        : { id: null, name: '', zoomLevel: 1, panOffset: { x: 0, y: 0 } },
      selected_name: null,
      selection: {
        isDrawing: false,
        startX: 0,
        startY: 0,
        fadeOutOpacity: 1.0,
        fadeOutAnimation: 0,
        lastWidth: 0,
        lastHeight: 0,
        lastStartX: 0,
        lastStartY: 0,
      },
      open_add_blocks: false,
      open_block_setting: false,
      setting_changed: false,
      block_moved: false,
    };
    this.holder = {
      setBlockMoved: null,
      centerContent: null,
      zoomTowardPoint: null,
    };
    this.draggingBlock = null;
    this.selectedBlock = null;
    this.selectedLine = null;
    this.canvas = null;
    this.ctx = null;
  }
  resizeCanvas = () => {
    this.draw();
  };
  componentDidMount() {
    this.canvas = document.getElementById('dashboard');
    const searchBox = document.getElementById('searchBox');
    const searchInput = document.getElementById('searchInput');
    const suggestions = document.getElementById('suggestions');
    initCanvasListener(this.canvas, searchBox, searchInput, suggestions, this);
    window.addEventListener('resize', this.resizeCanvas, false);
    this.draw();
  }

  componentDidUpdate() {
    this.draw();
  }

  drawSelectionRect = ctx => {
    const { startX, startY, lastWidth, lastHeight } = this.state.selection;
    const opacity = 1.0;
    ctx.strokeStyle = `rgba(0, 102, 255, ${opacity})`;
    ctx.lineWidth = 2;
    ctx.setLineDash([6, 4]);
    ctx.strokeRect(startX, startY, lastWidth, lastHeight);

    // Add semi-transparent fill
    ctx.fillStyle = `rgba(0, 102, 255, ${opacity * 0.1})`;
    ctx.fillRect(startX, startY, lastWidth, lastHeight);
  };

  draw(block_moved = false) {
    const { setting_changed } = this.state;
    // console.log({ block_moved, setting_changed });
    if (this.holder.setBlockMoved && (block_moved || setting_changed)) {
      if (block_moved) this.setState({ block_moved: true });
      this.holder.setBlockMoved(block_moved || setting_changed);
    }
    const selectedBlock = this.selectedBlock;
    const selectedLine = this.selectedLine;
    const canvas = this.canvas;
    const ctx = this.canvas.getContext('2d');
    drawBackgroundWithDots(canvas, ctx, this);
    const { blocks } = this.state;
    let zoomLevel = 1;
    let panOffset = { x: 0, y: 0 };
    if (this.state.diagram) {
      zoomLevel = this.state.diagram.zoomLevel;
      panOffset = this.state.diagram.panOffset;
    }

    // Apply zoom and pan
    ctx.translate(panOffset.x, panOffset.y);
    ctx.scale(zoomLevel, zoomLevel);

    // Set line width based on zoom level
    ctx.lineWidth = 1 / zoomLevel;

    // Draw blocks
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i] !== selectedBlock)
        blocks[i].draw(ctx, { only_title: true });
    }
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i] !== selectedBlock) blocks[i].drawLines(ctx, blocks);
    }
    if (selectedBlock) {
      selectedBlock.draw(ctx);
      selectedBlock.drawLines(ctx, blocks, true);
    }
    if (selectedLine) {
      selectedLine.draw(ctx);
    }
    if (this.state.selection.isDrawing) {
      this.drawSelectionRect(ctx);
    }
    // Restore the context state
    ctx.restore();

    // Draw mini-map
    // drawMiniMap();

    console.log('Drawing complete');
  }

  handleResetView = () => {
    const { diagram } = this.state;
    this.setState(
      {
        diagram: {
          ...diagram,
          panOffset: { x: 0, y: 0 },
          zoomLevel: 1,
        },
      },
      () => {
        this.draw();
      }
    );
  };

  handleLoadDiagram = (diagram, blocks) => {
    console.log('handleLoadDiagram', { diagram, blocks });
    if (IsInvalid(diagram)) {
      diagram = {
        id: null,
        name: '',
        zoomLevel: 1,
        panOffset: { x: 0, y: 0 },
      };
    }
    if (!blocks.length) {
      // let canvasX = 0;
      // let canvasY = 0;
      // const names = getBlocksNames();
      // for (let i = 0; i < names.length; i++) {
      //   const newBlock = createBlock(canvasX, canvasY, names[i], this);
      //   if (newBlock) {
      //     console.log(names[i]);
      //     canvasX = canvasX + newBlock.width + 20;
      //     if (canvasX > this.canvas.width - 10) {
      //       canvasX = 0;
      //       canvasY = canvasY + 200;
      //     }
      //     blocks.push(newBlock);
      //   }
      // }
    }
    this.props.setDiagram(diagram);
    if (this.holder.setBlockMoved) {
      this.holder.setBlockMoved(false);
    }
    this.draggingBlock = null;
    this.selectedBlock = null;
    this.selectedLine = null;
    this.setState(
      { diagram, blocks, block_moved: false, setting_changed: false },
      () => {
        this.draw();
        this.forceUpdate();
      }
    );
  };

  reRender() {
    this.setState({ a: 1 });
  }

  handleAlignment = (type, value) => {
    // console.log({ type, value });
    const { diagram } = this.state;
    const { panOffset } = diagram;
    if (type === 'zoom') {
      if (this.holder.zoomTowardPoint) {
        if (value === 'zoom_in') this.holder.zoomTowardPoint(panOffset, 1.1);
        else this.holder.zoomTowardPoint(panOffset, 0.9);
      }
    } else {
      if (this.holder.centerContent) {
        this.holder.centerContent();
        this.draw();
      }
    }
  };

  handleDragOverCanvasContainer = e => {
    e.preventDefault();
    this.setState({ mouseX: e.clientX, mouseY: e.clientY });
  };

  handleCanvasDragOver = e => {
    e.preventDefault();
    this.setState({ isOverCanvas: true });
  };

  handleCanvasDragLeave = () => {
    console.log('handleCanvasDragLeave');
    this.setState({ isOverCanvas: false });
  };

  handleCanvasDrop = e => {
    e.preventDefault();
    const { draggedItem } = this.state;
    console.log({ draggedItem });
    if (draggedItem) {
      this.setState({
        isDragging: false,
        draggedItem: null,
        isOverCanvas: false,
      });
    }
  };
  handleSettingChanged = changed => {
    const { block_moved, setting_changed } = this.state;
    console.log('handleSettingChanged', {
      block_moved,
      changed,
      setting_changed,
    });
    if (!block_moved && setting_changed !== changed) {
      if (this.holder.setBlockMoved) {
        console.log('handleSettingChanged - setBlockMoved', {
          changed,
        });
        this.holder.setBlockMoved(changed);
      }
    }
    this.setState({ setting_changed: changed }, () => {
      this.draw();
      this.reRender();
    });
  };
  render() {
    const { height, width, width_sidebar, minSidebar, isDesktop } = this.props;
    const delta = minSidebar ? 67 : isDesktop ? width_sidebar + 2 : 3;
    let style = undefined;
    if (!isDesktop) {
      style = { paddingLeft: 0 };
    }
    const { diagram, mouseX, mouseY } = this.state;
    let selectedElement = null;
    if (this.selectedBlock) {
      selectedElement = this.selectedBlock.title;
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          <ToolBarBlockProcess
            diagram={this.state.diagram}
            handleAddError={this.props.handleAddError}
            handleAlignment={this.handleAlignment}
            handleLoadDiagram={this.handleLoadDiagram}
            handleResetView={this.handleResetView}
            holder={this.holder}
            reRender={this.reRender}
            selectedBlock={this.selectedBlock}
            setState={state => this.setState(state)}
            state={this.state}
          />
        </Grid>
        <Grid container direction="column" item style={style} xs={12}>
          <div
            className="canvas-container w-full max-w-2xl mx-auto flex flex-col"
            onDragOver={this.handleDragOverCanvasContainer}
            style={{ height: height - 167 }}>
            <div className="search-container" id="searchBox">
              <input id="searchInput" placeholder="Search..." type="text" />
            </div>
            <ul id="suggestions" />
            <AddBlocks
              height={height}
              mouseX={mouseX} // Position it at the mouse X
              mouseY={mouseY} // Position it at the mouse Y
              open={this.state.open_add_blocks}
              setState={state => this.setState(state)}
              width={width}
            />
            <BlockSetting
              handleSettingChanged={this.handleSettingChanged}
              height={height}
              mouseX={mouseX} // Position it at the mouse X
              mouseY={mouseY} // Position it at the mouse Y
              open={this.state.open_block_setting}
              selectedBlock={this.selectedBlock}
              setState={state => this.setState(state)}
              width={width}
            />
            <div
              className="relative flex-1 flex flex-col"
              onDragLeave={this.handleCanvasDragLeave}
              onDragOver={this.handleCanvasDragOver}
              onDrop={this.handleCanvasDrop}
              style={{ height: '100%' }}>
              <canvas
                className="block-pipeline-canvas"
                height={`${height - 190}px`}
                id="dashboard"
                width={`${width - delta}px`}
              />
              <StatusbarBlockPipeline
                diagram={diagram}
                selectedBlock={this.selectedBlock}
                selectedElement={selectedElement}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

TabBlockPipeline.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  let { openSidebar, width_sidebar, minSidebar } = state.page_globals;
  const { diagram } = state.page_pipeline_blocks;
  //
  return {
    width_sidebar,
    minSidebar,
    openSidebar,
    isDesktop: state.layout.isDesktop,
    diagram,
  };
};

export default connect(mapStateToProps, { setDiagram })(TabBlockPipeline);
