import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Pagination, Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { StatusFiles, StatusFolders } from './components';
import {
  setExplorerPage,
  setExplorerLimit,
  setRunningProcesses,
} from 'redux-store/actions';
import ButtonShowRunningProcesses from 'components/Buttons/ButtonShowRunningProcesses';
import { SubsDockerProcessFinished } from 'graphql/Subscriptions';

const useStyles = theme => ({
  root: {
    padding: 0,
    margin: 0,
    paddingLeft: theme.spacing(2),
    marginTop: 40,
    marginBottom: 0,
    bottom: 0,
  },
});

class ExplorerStatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }
  handleChange = (event, value) => {
    value--;
    if (value < 0) value = 0;
    // console.log({ value });
    this.props.setExplorerPage(value);
  };
  handleProcessFinished = update_docker_process => {
    const { running_processes } = this.props;
    const index = running_processes
      .map(x => x.id)
      .indexOf(update_docker_process.id);
    if (index === -1) return;
    running_processes[index].status = update_docker_process.status;
    let newArray = JSON.parse(JSON.stringify(running_processes)); // deep copy
    this.props.setRunningProcesses(newArray);
  };
  render() {
    let {
      classes,
      status,
      total_files,
      size,
      count,
      rowsPerPage,
      running_processes,
    } = this.props;
    if (total_files) return null;
    let ComponentPagination = null;
    if (size > rowsPerPage) {
      ComponentPagination = (
        <Pagination
          count={count}
          onChange={this.handleChange}
          page={this.props.page + 1}
        />
      );
    }
    const ids = running_processes.map(x => x.id);
    return (
      <Grid
        alignItems="flex-start"
        className={classes.root}
        container
        direction="row"
        justifyContent="flex-start">
        <ButtonShowRunningProcesses
          handleAddError={this.props.handleAddError}
        />
        <StatusFolders status={status} />
        <StatusFiles status={status} />
        <SubsDockerProcessFinished
          handleProcessFinished={this.handleProcessFinished}
          ids={ids}
        />
        <Box sx={{ flexGrow: 1 }} />
        {ComponentPagination}
        <div style={{ width: 80 }} />
      </Grid>
    );
  }
}

ExplorerStatusBar.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { status, total_files } = state.page_explorer;
  const { running_processes } = state.running_processes;
  let size = 0;
  let page = 0;
  let count = 0;
  let rowsPerPage = 0;
  if (status) {
    size = status.folders + status.files;
    count = Math.ceil(size / 10);
    if (status.page) page = status.page;
    if (status.rowsPerPage) {
      rowsPerPage = status.rowsPerPage;
      count = Math.ceil(size / status.rowsPerPage);
    }
  }
  return {
    status,
    total_files,
    size,
    page,
    count,
    rowsPerPage,
    running_processes: running_processes ? running_processes : [],
  };
};
export default connect(mapStateToProps, {
  setExplorerPage,
  setExplorerLimit,
  setRunningProcesses,
})(withStyles(ExplorerStatusBar, useStyles));
