import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { ChipButtom, getIconStatus } from '../../../styles';

const CellEditStatus = ({ status, onClickSelect = () => '' }) => {
  return (
    <Autocomplete
      disablePortal
      onChange={(event, status) => onClickSelect(status)}
      options={['Active', 'Pending', 'Blocked']}
      renderInput={params => (
        <TextField {...params} label="" sx={{ width: 150 }} />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            component="li"
            key={key}
            {...optionProps}
            sx={{
              '& > img': { mr: 2, flexShrink: 0 },
              paddingLeft: 0,
              paddingRight: 0,
            }}>
            <ChipButtom
              color="secondary"
              icon={getIconStatus(option)}
              label={option}
              size="small"
              sx={{ width: 200 }}
              variant="outlined"
            />
          </Box>
        );
      }}
      sx={{ width: '100%' }}
      value={status}
    />
  );
};
export default CellEditStatus;
