import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';

import {
  TabBirthDate,
  TabDescription,
  TabImage,
  TabQuery,
  TabUIDs,
} from './components';
import { LinearWaiting, TabPanel } from 'components';
import { IsInvalid } from 'helpers';

class TopTabQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { label: 'Patient Name', TabComponent: TabQuery },
        { label: 'Patient ID', TabComponent: TabQuery },
        {
          label: 'Accession Number',
          TabComponent: TabQuery,
        },
        { label: 'BirthDate', TabComponent: TabBirthDate },
        {
          label: 'Description',
          TabComponent: TabDescription,
        },
        { label: 'UIDs', TabComponent: TabUIDs },
        { label: 'Image', TabComponent: TabImage },
      ],
    };
    const { holder } = props;
    if (holder) {
      holder.getQuerySearch = () => this.getQuerySearch();
    }
    this.holder = {
      getValues: [],
    };
    this.holder.getValues.push(null); //'Patient Name'
    this.holder.getValues.push(null); //'Patient ID'
    this.holder.getValues.push(null); // 'Accession Number'
    this.holder.getValues.push(null); //'BirthDate'
    this.holder.getValues.push(null); //'Description'
    this.holder.getValues.push(null); //'UIDs'
    this.holder.getValues.push(null); //'Image'
  }
  getQuerySearch = (value, tab_name) => {
    const { tabValue } = this.props;
    let StudySearch = {};
    if (IsInvalid(tab_name)) {
      if (tabValue >= this.state.tabs.length) return null;
      tab_name = this.state.tabs[tabValue].label;
      let total = this.holder.getValues.length;
      if (!total || tabValue > total || total > this.state.tabs.length)
        return StudySearch;
      else {
        const reply = this.holder.getValues[tabValue]();
        if (reply) value = reply.value;
      }
    }
    if (IsInvalid(value)) return null;
    if (tab_name === 'Patient Name') {
      if (value !== '') StudySearch.PatientName = value;
    } else if (tab_name === 'Patient ID') {
      if (value !== '') StudySearch.PatientID = value;
    } else if (tab_name === 'Accession Number') {
      if (value !== '') StudySearch.AccessionNumber = value;
    } else if (tab_name === 'PatientBirthDate') {
      if (value !== '') StudySearch.PatientBirthDate = value;
    } else if (tab_name === 'Description') {
      const { getDescription } = this.props.holder;
      if (getDescription) {
        const { type, description } = getDescription();
        if (type) {
          StudySearch = {
            ...StudySearch,
            [type]: description,
          };
        }
      } else {
        return StudySearch;
      }
    } else if (tab_name === 'StudyInstanceUID') {
      if (value !== '') StudySearch.StudyInstanceUID = value;
    } else if (tab_name === 'SeriesInstanceUID') {
      if (value !== '') StudySearch.SeriesInstanceUID = value;
    } else if (tab_name === 'Images') {
      const { spacing, size } = value;
      if (size) {
        StudySearch = {
          ...StudySearch,
          ImageSize: size,
        };
      }
      if (spacing) {
        StudySearch = {
          ...StudySearch,
          ImageSpacing: spacing,
        };
      }
    } else if (tab_name === 'CustomFiled') {
      /*empty*/
    } else {
      return null;
    }
    const {
      getStudyTimes,
      getImageType,
      // setPageLimit,
      getModalities,
    } = this.props.holder;
    // const { page, limit } = this.props.holder.getPageLimit();
    // setPageLimit(page, limit);
    if (getStudyTimes) {
      const { date, time } = getStudyTimes();
      if (date) {
        StudySearch.StudyDate = date;
      }
      if (time) {
        StudySearch.StudyTime = time;
      }
    } else {
      /*empty*/
    }
    if (getModalities) {
      const modalities = getModalities();
      if (modalities.length) {
        StudySearch.ModalitiesInStudy = modalities;
      }
    }
    if (getImageType && tab_name !== 'Images') {
      const imagetype = getImageType();

      if (Object.keys(imagetype).length) {
        const { spacing, size } = imagetype;
        if (size) {
          StudySearch = {
            ...StudySearch,
            ImageSize: size,
          };
        }
        if (spacing) {
          StudySearch = {
            ...StudySearch,
            ImageSpacing: spacing,
          };
        }
      }
    }
    return StudySearch;
  };
  executeSearch = (value, tab_name) => {
    const StudySearch = this.getQuerySearch(value, tab_name);
    // console.log({ value, tab_name, StudySearch });
    if (StudySearch) this.props.runSearch(StudySearch);
  };

  render() {
    const { tabValue, loading, classes, handleTabValue } = this.props;
    return (
      <Paper square>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabValue}
          // scrollButtons="auto"
          scrollButtons
          size="size"
          sx={{ minHeight: 10, height: 40 }}
          textColor="primary"
          value={tabValue}
          variant="scrollable">
          {this.state.tabs.map((x, index) => {
            return (
              <Tab
                className={classes.tab}
                key={`tab-query-${index}`}
                label={x.label}
              />
            );
          })}
        </Tabs>
        {loading && <LinearWaiting loading={loading} />}
        <Divider />
        <div className={classes.tab_panel}>
          {this.state.tabs.map((x, index) => {
            const { TabComponent, label } = x;
            return (
              <TabPanel
                index={index}
                key={`tabpanel-query-${index}`}
                value={tabValue}>
                <TabComponent
                  active={tabValue === 6}
                  executeSearch={this.executeSearch}
                  handleAddError={this.props.handleAddError}
                  holder={this.props.holder}
                  index={index}
                  isActive={index === tabValue}
                  query={label}
                  tab_holder={this.holder}
                />
              </TabPanel>
            );
          })}
        </div>
      </Paper>
    );
  }
}

TopTabQuery.propTypes = {
  classes: PropTypes.object,
};

export default TopTabQuery;
