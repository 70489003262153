import { FormatTime } from 'helpers';
import { IsInvalid } from 'helpers';

export const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 75,
    style: {
      width: 75,
      maxWidth: 90,
    },
    align: 'left',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 50,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return 'Type is Missing';
      const value = params.getValue('type');
      if (IsInvalid(value)) return 'Type is Missing';
      const { name } = value;
      if (name) return name;
      return 'Type is Missing';
    },
  },
  {
    field: 'file',
    headerName: 'File Object',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return 'File is Missing';
      const value = params.getValue('file');
      if (IsInvalid(value)) return 'File is Missing';
      const { original_name } = value;
      if (original_name) return original_name;
      return 'File is Missing';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    minWidth: 100,
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const updatedAt = params.getValue('updatedAt');
      if (IsInvalid(updatedAt)) return '';
      return FormatTime(updatedAt, 'MMM Do - YYYY, h:mm a');
    },
  },
];
