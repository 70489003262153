import { IsInvalidParam, getUserFullName } from 'helpers';
import { FormatTime } from 'helpers';
import { IsInvalid } from 'helpers';

export const columns_ano_rules = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  { field: 'tag', headerName: 'Tag', align: 'left' },
  {
    field: 'tag_name',
    headerName: 'Tag Name',
    align: 'left',
  },
  {
    field: 'tag_rule',
    headerName: 'Tag Rule',
    edit: true,
    align: 'left',
  },
  {
    field: 'tag_options',
    headerName: 'Tag Options',
    edit: true,
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const columns_ano_tags = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  { field: 'idRule', headerName: 'ID Rule', align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const user = params.getValue('user');
      if (IsInvalid(user)) return '';
      return getUserFullName(user);
    },
  },
  {
    field: 'project',
    headerName: 'Project',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const project = params.getValue('project');
      if (IsInvalid(project)) return '';
      return project.name;
    },
  },
  {
    field: 'tag_name',
    headerName: 'Tag Name',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const tag_name = params.getValue('tag_name');
      if (tag_name) return tag_name;
      const rule = params.getValue('rule');
      if (IsInvalid(rule)) return '';
      return rule.tag_name;
    },
  },
  {
    field: 'tag_rule',
    headerName: 'Tag Rule',
    //  width: 75,
    align: 'left',
  },
  {
    field: 'tag_value',
    headerName: 'Tag Value',
    //  width: 75,
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const useStyles = () => ({
  root: {},
  button_group: {
    height: 33,
  },
  tabs: {
    margin: 0,
    padding: 0,
    minHeight: 30,
  },
  tab: {
    minHeight: 30,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
});
