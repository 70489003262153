import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Checkbox } from '@mui/material';

import TableGridRowCell from '../TableGridRowCell';
import { withStyles } from 'tss-react/mui';
import { v1 as uuid } from 'uuid';
import { getIdValue } from '../table_utils';
const useStyles = () => ({
  root: {
    borderBottom: '2px solid red',
  },
});
class TableGridRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cells_active: null,
      isItemSelected: false,
    };
    const { holder } = props;
    if (holder) {
      holder.onAllSelectChanged.push(isItemSelected => {
        this.setState({ isItemSelected }, () => {
          this.forceUpdate();
        });
      });
    }
  }
  componentDidUpdate() {
    const { isRowClicked, isItemSelected } = this.props;
    const { cells_active } = this.state;
    if (!isRowClicked && cells_active) {
      this.setState({ cells_active: null, isItemSelected });
    } else if (this.state.isItemSelected !== isItemSelected) {
      this.setState({ isItemSelected });
    }
  }
  shouldComponentUpdate(nextProps) {
    return (
      this.props.saving ||
      nextProps.isRowClicked !== this.props.isRowClicked ||
      nextProps.isRowClicked ||
      !this.deepEqual(nextProps.row)
    );
  }
  deepEqual = object2 => {
    const object1 = this.props.row;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };
  isObject = object => {
    return object != null && typeof object === 'object';
  };
  handleCellDoubleClick = cell => {
    const { row } = this.props;
    this.props.handleRowClicked(row);
    this.setState({ cells_active: cell });
  };
  handleSingleClick = cell => {
    const { row } = this.props;
    let { cells_active } = this.state;
    this.props.onCellSingleClick({ row, cell });
    if (!cells_active || cells_active === cell) return;
    this.setState({ cells_active: null });
  };
  handlelKeyEnter = cell => {
    const { headers } = this.props;
    const index = headers.map(x => x.field).indexOf(cell);
    // let is_not_object = true;
    if (index !== -1 && headers[index].options) {
      // internal_row = headers[index].editerGetter(internal_row, value);
    }
    this.setState({ cells_active: null });
  };
  handleTextChanged = (cell, value) => {
    const { headers } = this.props;
    let { row, row_original } = this.props;
    let internal_row = {
      ...row,
    };
    const index = headers.map(x => x.field).indexOf(cell);
    let is_not_object = true;
    if (index !== -1 && headers[index].editerGetter) {
      internal_row = headers[index].editerGetter(internal_row, value);
      is_not_object = false;
    }
    if (is_not_object) internal_row[cell] = value;
    let edited = 0;
    for (let i = 0; i < headers.length; i++) {
      let E = this.props.getCellValue(headers[i], row);
      if (row_original) {
        E = this.props.getCellValue(headers[i], row_original);
      }
      let I = this.props.getCellValue(headers[i], internal_row);
      if (E !== I) {
        edited++;
      }
    }
    this.props.onRowEdited(edited > 0 ? true : false, internal_row);
  };
  handleIsCellClicked = field => {
    const { row_cell_clicked, row } = this.props;
    if (row_cell_clicked === undefined || !row_cell_clicked) return false;
    const idClicked = getIdValue(row_cell_clicked.row, this.props);
    const idRow = getIdValue(row, this.props);
    if (idClicked !== idRow) return false;
    return row_cell_clicked.cell === field;
  };
  render() {
    const {
      classes,
      row,
      headers,
      checkboxSelection,
      onSelectItemClicked,
      isRowSelected,
      add_row_style,
      row_height,
    } = this.props;

    let TableCellCheckbox = null;
    const { cells_active, isItemSelected } = this.state;
    if (checkboxSelection) {
      const style_cell = {
        width: 20,
        maxWidth: 10,
      };
      if (this.props.grid) {
        style_cell.border = '0.5px solid #e0e0e0';
      }
      TableCellCheckbox = (
        <TableCell padding="checkbox" sx={style_cell}>
          <Checkbox
            checked={isItemSelected}
            color="default"
            inputProps={{ 'aria-labelledby': uuid() }}
            onClick={() => onSelectItemClicked(row)}
          />
        </TableCell>
      );
    }
    let style_row = {
      paddingTop: this.props.dense ? 0 : '3px',
    };
    if (row_height) {
      style_row = {
        ...style_row,
        // minHeight: row_height,
        height: row_height,
      };
    }
    if (add_row_style && add_row_style.style) {
      style_row = {
        ...style_row,
        ...add_row_style.style,
      };
    }
    return (
      <TableRow
        className={classes.root}
        hover
        onClick={() => this.props.handleRowClicked(row)}
        role="checkbox"
        selected={isRowSelected}
        style={style_row}
        tabIndex={-1}>
        {TableCellCheckbox}
        {headers.map((header, index) => {
          const { field } = header;
          const value = this.props.getCellValue(header, row);
          let isActive = cells_active === field ? true : false;
          if (this.handleIsCellClicked(field)) isActive = true;
          return (
            <TableGridRowCell
              cell_selection={this.props.cell_selection}
              colIndex={index}
              getColorCellText={this.props.getColorCellText}
              getStyleBox={this.props.getStyleBox}
              grid={this.props.grid}
              header={header}
              isActive={isActive}
              IsCellSelected={this.props.IsCellSelected}
              key={`table-grid-row-cell-${index}`}
              onButtonClick={this.props.onButtonClick}
              onCellClick={this.handleSingleClick}
              onCellDoubleClick={this.handleCellDoubleClick}
              onCellKeyEnter={this.handlelKeyEnter}
              onCellMouseDown={this.props.onCellMouseDown}
              onCellMouseMove={this.props.onCellMouseMove}
              onCellMouseUp={this.props.onCellMouseUp}
              onCellPaste={this.props.onCellPaste}
              onRowDoubleClick={this.props.onRowDoubleClick}
              onTextChanged={this.handleTextChanged}
              row={row}
              rowIndex={this.props.rowIndex}
              value={value}
            />
          );
        })}
      </TableRow>
    );
  }
}

TableGridRow.propTypes = {
  cell_selection: PropTypes.bool,
  cells: PropTypes.array,
  checkboxSelection: PropTypes.bool,
  classes: PropTypes.object,
  getColorCellText: PropTypes.func,
  grid: PropTypes.bool,
  isItemSelected: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onSelectItemClicked: PropTypes.func,
};
TableGridRow.defaultProps = {
  cell_selection: false,
  grid: false,
  cells: [],
  checkboxSelection: false,
  onSelectItemClicked: () => '',
  onCellDoubleClick: () => '',
  onCellSingleClick: () => '',
  isItemSelected: false,
  isRowSelected: false,
  isRowClicked: false,
  handleRowClicked: () => '',
  onRowEdited: () => '',
  onRowDoubleClick: () => '',
  onButtonClick: () => '',
  onCellPaste: () => '',
  onCellMouseDown: () => '',
  onCellMouseMove: () => '',
  onCellMouseUp: () => '',
  getStyleBox: () => '',
  getColorCellText: () => {
    return null;
  },
};

export default withStyles(TableGridRow, useStyles);
