import React from 'react';
import ImageIcon from '@mui/icons-material/Image';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

const CellUserEmail = ({ user }) => {
  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemAvatar>
        <Avatar>
          <ImageIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            component="a"
            sx={{
              margin: '0px',
              fontFamily:
                '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
              fontWeight: 500,
              fontSize: '0.875rem',
              lineHeight: 1.57,
              textDecoration: 'none',
              color: 'inherit',
              whiteSpace: 'nowrap',
            }}
            variant="subtitle2">
            {user.name}
          </Typography>
        }
        secondary={
          <Typography
            component="p"
            sx={{
              margin: 0,
              fontFamily:
                '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
              fontWeight: 400,
              fontSize: '0.875rem',
              lineHeight: 1.57,
              color: 'var(--mui-palette-text-secondary)',
              '--mui-palette-text-secondary': '#667085',
            }}
            variant="body2">
            {user.email}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default CellUserEmail;
