export { default as ProjectSettingCenterToolBar } from './ProjectSettingCenterToolBar';
export { default as EditProjectCenter } from './EditProjectCenter';
import {
  FormatTime,
  getUserFullName,
  IsInvalid,
  IsInvalidParam,
} from 'helpers';
export const columns = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'center',
    headerName: 'Name',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('center');
      if (value === undefined || !value) return '';
      return value.name;
    },
    editerGetter: (row, value) => {
      if (row['center'] === undefined) return null;
      const center = row['center'];
      if (center === undefined || !center) return null;
      row = {
        ...row,
        center: {
          ...center,
          name: value,
        },
      };
      return row;
    },
  },
  {
    field: 'code',
    headerName: 'Code',
    align: 'left',
    width: 110,
  },
  {
    field: 'short_name',
    headerName: 'Short name',
    align: 'left',
    width: 110,
  },
  {
    field: 'idFolder',
    headerName: 'ID Folder',
    align: 'left',
    width: 110,
  },
  {
    field: 'folder_name',
    headerName: 'Folder Name',
    align: 'left',
    width: 110,
  },
  {
    field: 'Administrator',
    headerName: 'Administrator',
    align: 'left',
    width: 150,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('center');
      if (value === undefined || !value) return '';
      const { admin } = value;
      if (admin === undefined || !admin) return '';
      value = getUserFullName(admin);
      if (value === undefined || !value) return '';
      return value;
    },
  },
  {
    field: 'address',
    headerName: 'Adress',
    align: 'left',
    width: 222,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      let value = params.getValue('center');
      if (IsInvalid(value)) return '';
      const { address } = value;
      if (IsInvalid(address)) return '';
      const { city, country } = address;
      if (city && country) return `${country}, ${city}`;
      if (city) return city;
      if (country) return country;
      return '';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
