import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { Card, Divider, Grid, CardContent } from '@mui/material';
import { DialogDeleteWarning, TableCenters } from 'components';
import { ToolBarSearchCenters, useStyles } from './components';
import { CardProjectHeader } from 'components/Cards/components';
import { GetData, ServerErrorsString } from 'helpers';
import {
  MutationAddProjectCenter,
  MutationDeleteProjectCenter,
  QueryProjectCenter,
} from 'graphql/Projects/utils_project';
import { IsInvalid } from 'helpers';

class CardProjectCenters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      deleting: false,
      loading: false,
      centers: [],
      idProject: -1,
      name: '',
      page: 0,
      limit: 50,
      total: 0,
      new_centers: [],
      open_delete: false,
      code: '',
    };
    this.holder = {
      getSelected: () => this.state.selected,
      getSelectedCenter: null,
    };
  }

  componentDidMount() {
    if (!this.props.isActive) return;
    this.queryCenters();
  }

  componentDidUpdate() {
    if (!this.props.isActive) return;
    const { idProject } = this.state;
    const { project } = this.props;
    if (project.id !== idProject) {
      this.queryCenters(project.id);
    }
  }

  queryCenters = (
    idProject = null,
    newName = null,
    newPage = null,
    newLimit = null
  ) => {
    const { loading } = this.state;
    if (loading) return;
    let { name, page, limit } = this.state;
    if (idProject === undefined || !idProject) {
      idProject = this.state.idProject;
    }
    if (idProject < 0) {
      idProject = this.props.project.id;
    }
    //
    if (idProject <= 0) return;
    if (newName && newName !== '') {
      name = newName;
    }
    if (newPage !== undefined && newPage !== null) {
      page = newPage;
    }
    if (newLimit !== undefined && newLimit !== null) {
      limit = newLimit;
    }
    if (page < 1) page = 0;
    this.setState({ loading: true });
    (async () => {
      QueryProjectCenter(idProject, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, project_centers, total } = data.projectCenters;
          if (ok) {
            let centers = project_centers
              .map(x => {
                const { center, code } = x;
                if (!center || center === undefined) return null;
                return {
                  ...center,
                  code,
                };
              })
              .filter(x => x);
            this.setState({
              loading: false,
              idProject,
              centers,
              selected: [],
              new_centers: [],
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idProject, added: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleSaveClicked = () => {
    const { saving, new_centers, idProject } = this.state;
    if (saving || !new_centers.length) return;
    console.log({ new_centers });
    const ids = new_centers
      .filter(x => x.code === undefined || x.code === '' || !x.code)
      .map(x => x.id)
      .filter(x => x);
    const codes = new_centers
      .filter(x => x.code !== undefined && x.code !== '')
      .map((x, index) => {
        return {
          id: index,
          idProject,
          idCenter: x.id,
          code: x.code,
        };
      });
    this.setState({ saving: true });
    (async () => {
      MutationAddProjectCenter(idProject, ids, codes)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, project_centers } = data.addCentersToProject;
          if (ok) {
            let centers = project_centers
              .map(x => {
                const { center, code } = x;
                if (!center || center === undefined) return null;
                return {
                  ...center,
                  code,
                };
              })
              .filter(x => x);
            this.setState({
              saving: false,
              centers: centers.concat(this.state.centers),
              new_centers: [],
              total: this.state.total + ids.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false, idProject, new_centers: [] });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    let { deleting, selected, idProject, centers, new_centers } = this.state;
    if (deleting || !selected.length) return;
    let ids = selected.map(x => x.id);
    if (new_centers && new_centers.length) {
      const toremove = new_centers.filter(x => ids.indexOf(x.id) !== -1);
      if (toremove.length) {
        selected = selected.filter(
          x => toremove.map(x => x.id).indexOf(x.id) === -1
        );
        new_centers = new_centers.filter(x => ids.indexOf(x.id) === -1);
        ids = selected.map(x => x.id);
      }
    }
    if (ids.length) {
      this.setState({ deleting: true, new_centers });
    } else {
      this.setState({ new_centers, open_delete: false, selected });
      return;
    }
    (async () => {
      MutationDeleteProjectCenter(idProject, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeCenterFromProject;
          if (ok) {
            this.setState({
              deleting: false,
              centers: centers.filter(x => ids.indexOf(x.id) === -1),
              selected: [],
              open_delete: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, idProject, new_centers: [] });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.queryCenters(null, null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.queryCenters(null, null, 0, +event.target.value);
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleAddCenter = center => {
    if (IsInvalid(center, true) || IsInvalid(center.id)) return;
    const { code } = this.state;
    let { new_centers, centers } = this.state;
    centers = centers.concat(new_centers);
    const index = centers.map(x => x.id).indexOf(center.id);
    if (index === -1) {
      if (code && code !== '') {
        new_centers.unshift({ ...center, code });
      } else {
        new_centers.unshift(center);
      }
      this.setState({ new_centers, code: '' });
      this.holder.resetCenter();
    } else {
      this.props.handleAddError(`Center (${center.name}) is already here`);
    }
  };
  handleKeyDownCode = () => {
    const { code } = this.state;
    if (!code || code === undefined || code === '') {
      this.props.handleAddError('Code is missing');
    } else if (this.holder.getSelectedCenter) {
      const center = this.holder.getSelectedCenter();
      if (!center || center === undefined || center.id === undefined) {
        this.props.handleAddError('Center is missing');
      } else {
        this.handleAddCenter(center);
      }
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, maximize, show, project, height, admin } = this.props;
    let { idProject, new_centers, selected } = this.state;
    if (!show && !maximize) return null;
    if (idProject < -1) {
      idProject = project.id;
    }
    const title_dialog = selected.length > 1 ? 'Centers' : 'Center';
    let ComponentSearchCenter = null;
    if (admin) {
      ComponentSearchCenter = (
        <ToolBarSearchCenters
          admin={admin}
          changed={this.state.new_centers.length}
          code={this.state.code}
          handleAddCenter={this.handleAddCenter}
          handleKeyDownCode={this.handleKeyDownCode}
          handleSaveClicked={this.handleSaveClicked}
          holder={this.holder}
          loading={this.state.saving}
          onChangeCenterName={event =>
            this.setState({ code: event.target.value })
          }
        />
      );
    }
    return (
      <Card className={classes.root}>
        <CardProjectHeader
          handleMaximize={() => this.props.handleMaximize('center')}
          subtitle="List of project centers"
          title="Centers"
        />
        <Divider variant="middle" />
        <CardContent className={classes.content}>
          <Grid
            alignItems="center"
            className={classes.toolbar}
            container
            direction="column"
            justifyContent="space-evenly"
            spacing={1}>
            {ComponentSearchCenter}
            <div className={classes.table}>
              <TableCenters
                code
                deleting={this.state.deleting}
                dense={this.props.dense}
                disable_checkbox={!admin}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                handleDeleteSelected={() =>
                  this.setState({ open_delete: true })
                }
                handleReloadTable={() => this.queryCenters(idProject)}
                handleSetSelected={this.handleSetSelected}
                height={height + 90}
                holder={this.holder}
                limit={this.state.limit}
                loading={this.state.loading}
                nowrap={this.props.dense}
                page={this.state.page}
                rows={new_centers.concat(this.state.centers)}
                selected={selected}
                total={this.state.total}
              />
            </div>
          </Grid>
        </CardContent>
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={`You are about to delete the selected ${title_dialog}, this action will remove the Center from the project, you will lose the linking data to these centers`}
          title_text={`Delete ${title_dialog}`}
        />
      </Card>
    );
  }
}

CardProjectCenters.propTypes = {
  classes: PropTypes.object.isRequired,
  dense: PropTypes.bool,
  nowrap: PropTypes.bool,
  sites: PropTypes.array.isRequired,
};
CardProjectCenters.defaultProps = {
  nowrap: false,
  dense: false,
  sites: [],
  maximize: false,
  show: true,
  admin: false,
  access_code: 0,
};

export default withStyles(CardProjectCenters, useStyles);
