import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
export const columns_clinics = [
  {
    field: 'idPatient',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
