import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { TabPanel } from 'components';
import { getParams } from 'helpers';
import { getTabs } from './components/utils';
import { useStylesAdministrator } from './components/AdminDefault/useStyles';
class TabsAdministrator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      tabs: getTabs(props.user),
    };
  }

  componentDidMount() {
    const { level_1, level_2, level_3 } = getParams(this.props);
    let tabValue = this.state.tabs.findIndex(x => x.name === level_1);
    if (tabValue === -1) {
      tabValue = 0;
    }
    const name = this.state.tabs[tabValue].name;
    let address = `/administrator/${name}`;
    if (level_2) address += `/${level_2}`;
    if (level_3) address += `/${level_3}`;
    this.props.history.push(address);
    this.setState({ tabValue });
  }
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue);
    let name = 'users';
    if (tabValue >= 0 && tabValue < this.state.tabs.length) {
      name = this.state.tabs[tabValue].name;
    }
    this.setState({ tabValue });
    this.props.history.push(`/administrator/${name}`);
  };
  render() {
    const { classes, user, height } = this.props;
    const { tabValue } = this.state;
    return (
      <div style={{ margin: 0 }}>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              const { name } = tab;
              return (
                <Tab
                  key={`key-tab-${index}-${name}`}
                  label={name.replace('_', ' ')}
                />
              );
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ width: '100%', height: height - 115, margin: 0 }}>
          <div className={classes.tab_content}>
            {this.state.tabs.map((tab, index) => {
              const { name, TabComponent } = tab;
              return (
                <TabPanel
                  className={classes.tab_panel}
                  index={index}
                  key={`key-tab-panel-${index}-${name}`}
                  value={tabValue}>
                  <TabComponent
                    handleAddError={this.props.handleAddError}
                    handleAddSuccess={this.props.handleAddSuccess}
                    height={height}
                    history={this.props.history}
                    isActive={index === tabValue}
                    match={this.props.match}
                    user={user}
                  />
                </TabPanel>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

TabsAdministrator.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(
  withRouter(TabsAdministrator),
  useStylesAdministrator
);
