import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_admin_users = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const user = params.getValue('user');
      if (IsInvalid(user)) return '';
      return user.FullName;
    },
  },
  { field: 'count', headerName: '#Count', width: 75, align: 'left' },
  {
    field: 'activity',
    headerName: 'Activity',
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
