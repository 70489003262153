import React from 'react';
import { ChipButtom, getIconStatus } from '../../../styles';

const CellStatus = ({ status }) => {
  return (
    <ChipButtom
      color="secondary"
      icon={getIconStatus(status)}
      label={status}
      size="small"
      variant="outlined"
    />
  );
};

export default CellStatus;
