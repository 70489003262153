import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_sequence_error = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const user = params.getValue('user');
      if (IsInvalid(user)) return '';
      return user.FullName;
    },
  },
  {
    field: 'idSeries',
    headerName: 'idSeries',
    width: 75,
    align: 'left',
  },
  { field: 'count', headerName: '#Count', width: 75, align: 'left' },
  {
    field: 'prediction_class_category',
    headerName: 'Category',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const sequence_categ = params.getValue('sequence_categ');
      if (sequence_categ) return sequence_categ.name;
      const value = params.getValue('prediction_class_category');
      if (IsInvalid(value)) return '';
      return `${value} (Missing)`;
    },
  },
  {
    field: 'prediction_class',
    headerName: 'Prediction',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const sequence_predic = params.getValue('sequence_predic');
      if (sequence_predic) return sequence_predic.name;
      const value = params.getValue('prediction_class');
      if (IsInvalid(value)) return '';
      return `${value} (Missing)`;
    },
  },
  {
    field: 'prediction_probability_category',
    headerName: 'Category %',
    align: 'right',
  },
  {
    field: 'prediction_probability',
    headerName: 'Prediction %',
    align: 'right',
  },
  { field: 'confidence', headerName: 'Confidence', align: 'left' },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    align: 'left',
  },
  { field: 'PatientID', headerName: 'PatientID', align: 'left' },
  {
    field: 'AccessionNumber',
    headerName: 'AccessionNumber',
    align: 'left',
  },
  {
    field: 'AcquisitionDate',
    headerName: 'AcquisitionDate',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('AcquisitionDate');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY'); //20220622-090824
    },
  },
  {
    field: 'ContrastBolusAgent',
    headerName: 'ContrastBolusAgent',
    align: 'left',
  },
  { field: 'Modality', headerName: 'Modality', align: 'left' },
  { field: 'SliceThickness', headerName: 'SliceThickness' },
  { field: 'PixelSpacing', headerName: 'PixelSpacing', align: 'left' },
  {
    field: 'MRAcquisitionType',
    headerName: 'MRAcquisitionType',
  },
  { field: 'ImageType', headerName: 'ImageType', align: 'left' },
  { field: 'EchoTime', headerName: 'EchoTime', align: 'right' },
  {
    field: 'EchoTrainLength',
    headerName: 'EchoTrainLength',
    align: 'right',
  },
  { field: 'InversionTime', headerName: 'InversionTime' },
  { field: 'FlipAngle', headerName: 'FlipAngle', align: 'right' },
  {
    field: 'PixelBandwidth',
    headerName: 'PixelBandwidth',
    align: 'right',
  },
  {
    field: 'PhotometricInterpretation',
    headerName: 'PhotometricInterpretation',
    align: 'left',
  },
  { field: 'ProtocolName', headerName: 'ProtocolName' },
  {
    field: 'RepetitionTime',
    headerName: 'RepetitionTime',
    align: 'right',
  },
  { field: 'ScanOptions', headerName: 'ScanOptions', align: 'left' },
  {
    field: 'ScanningSequence',
    headerName: 'ScanningSequence',
    align: 'left',
  },
  { field: 'SequenceName', headerName: 'SequenceName', align: 'left' },
  {
    field: 'SequenceVariant',
    headerName: 'SequenceVariant',
    align: 'left',
  },
  {
    field: 'SeriesInstanceUID',
    headerName: 'SeriesInstanceUID',
    align: 'left',
  },
  {
    field: 'error',
    headerName: 'Error',
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];

export const style_paper = {
  minHeight: 350,
  padding: 0,
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};
