import React from 'react';

const CellText = ({ text, sx = {} }) => {
  return (
    <div
      style={{
        fontFamily:
          '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: 1.57,
        verticalAlign: 'inherit',
        textAlign: 'left',
        padding: '16px',
        color: 'var(--mui-palette-text-primary)',
        // borderBottom:
        //   ' var(--TableCell-borderWidth, 1px) solid var(--mui-palette-TableCell-border)',
        '--mui-palette-text-primary': '#212636',
        '--mui-palette-TableCell-border': '#dcdfe4',
        ...sx,
      }}>
      {text}
    </div>
  );
};
export default CellText;

/* <TableCell
    sx={{
      fontFamily:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      verticalAlign: 'inherit',
      textAlign: 'left',
      padding: '16px',
      color: 'var(--mui-palette-text-primary)',
      borderBottom:
        ' var(--TableCell-borderWidth, 1px) solid var(--mui-palette-TableCell-border)',
      '--mui-palette-text-primary': '#212636',
      '--mui-palette-TableCell-border': '#dcdfe4',
    }}>
    {created}
  </TableCell> 
*/
