import {
  CellProgress,
  CellStatus,
  CellText,
  CellUserEmail,
  CellActions,
} from './index';

export const getTableParams = row => {
  return {
    getValue: name => {
      if (row === undefined) return '';
      if (row[name] === undefined) return '';
      if (row[name]) return row[name];
      return '';
    },
  };
};
export const getComponentCell = (header, row, props) => {
  const { onClickEdit } = props;
  const { type } = header;
  let CellComponent = null;
  let value = null;
  let sx = {};
  if (header.valueGetter) {
    const params = getTableParams(row);
    value = { [header.field]: header.valueGetter(params) };
  } else if (header.field) {
    value = { [header.field]: row[header.field] };
  }
  // console.log({ type, value });
  if (type === 'user_email') {
    CellComponent = CellUserEmail;
  } else if (type === 'progress') {
    CellComponent = CellProgress;
  } else if (type === 'status') {
    CellComponent = CellStatus;
  } else if (type === 'action') {
    CellComponent = CellActions;
    value = { onClickEdit, row };
  } else {
    value = { text: value };
    if (header.valueGetter) {
      const params = getTableParams(row);
      value = { text: header.valueGetter(params) };
    } else if (header.field) {
      value = { text: row[header.field] };
    }
    CellComponent = CellText;
  }
  return { CellComponent, value, sx };
};
