import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { QueryTeamsAtCenter } from 'graphql/Units/utils_center_teams';

import { useMakeStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import SearchAutocomplete from 'components/Search/SearchAutocomplete';

const SearchCenterTeamsInputs = props => {
  const {
    style,
    page,
    limit,
    // eslint-disable-next-line no-unused-vars
    no_icon = false,
    idCenter = -1,
  } = props;
  const disabled = idCenter <= 0 ? true : false;
  const { classes } = useMakeStyles();

  const [name, setTeamName] = React.useState('');
  const [selected, setSelected] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!loading || disabled) {
      return undefined;
    }

    (async () => {
      QueryTeamsAtCenter(idCenter, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const {
            teamsAtCenter: { ok, errors },
          } = data;
          let teams = [];
          if (ok) {
            teams = data.teamsAtCenter.teams;
            if (!teams.length) {
              teams.push({ name: 'Not found' });
            }
          } else {
            throw errors;
          }
          setOptions(teams);
        })
        .catch(error => {
          setOptions([]);
          props.handleAddError(ServerErrorsString(error));
        });
    })();
  }, [loading, name]);
  const handelSiteNameChanged = event => {
    setTeamName(event.target.value);
  };
  const handleAddTeam = team => {
    if (!team || (team.id === undefined && team.name === 'Not found')) {
      //
      setTeamName('');
      setOpen(false);
      setOptions([]);
      setSelected(null);
    } else {
      setSelected(team);
      props.handleAddTeam(team);
    }
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        disabled={disabled}
        handelSearchNameChanged={handelSiteNameChanged}
        handleAddResultObject={handleAddTeam}
        label="Team Center"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder="Search Team at Center..."
        selected={selected}
        setObject={handleAddTeam}
        setObjectName={setTeamName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        disabled={disabled}
        handelSearchNameChanged={handelSiteNameChanged}
        handleAddResultObject={handleAddTeam}
        label="Team Center"
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder="Search Team at Center..."
        setObject={handleAddTeam}
        setObjectName={setTeamName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    </Paper>
  );
};

SearchCenterTeamsInputs.propTypes = {
  className: PropTypes.string,
  idCenter: PropTypes.number.isRequired,
  no_icon: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default SearchCenterTeamsInputs;
