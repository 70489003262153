import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
export const useStyles = () => ({
  root: {},
});
export const columns = [
  { field: 'idMember', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('user');
      if (value === undefined || !value) return '';
      const { FullName } = value;
      return FullName ? FullName : '';
    },
  },
  {
    field: 'email',
    headerName: 'E-Mail',
    width: 150,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('user');
      if (value === undefined || !value) return '';
      const { email } = value;
      return email ? email : '';
    },
  },
  {
    field: 'location',
    headerName: 'Location',
    align: 'left',
    width: 170,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('user');
      if (value === undefined || !value) return '';
      const { address } = value;
      if (address === undefined || !address) return '';
      const { country } = address;
      return country ? country : '';
    },
  },
  {
    field: 'center',
    headerName: 'Center',
    align: 'left',
    width: 170,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('center');
      if (value === undefined || !value) return '';
      const { name } = value;
      return name ? name : '';
    },
  },
  {
    field: 'team',
    headerName: 'Team',
    align: 'left',
    width: 170,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('team');
      if (IsInvalid(value)) return '';
      const { name } = value;
      return name ? name : '';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Registration Date',
    align: 'left',
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do, YYYY (h:mm a)');
    },
  },
];
