import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid } from '@mui/material';
import { TableUserFiles, handleQueryContent } from './components';
import { IconButtonLoad, RowText, SearchTransparent } from 'components';
import { MainTableToolbar } from 'components/Tables/components';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { IsInvalid, SelectedArray } from 'helpers';
import { connect } from 'react-redux';
import {
  clean_manager_error,
  copy,
  cut,
  paste,
} from 'redux-store/actions/file_folder_manager';
import { CreateLandingObject } from 'views/Administrator/components/AdminDefault/TabSystemElements/components/TabLandingObjects/components/TabLandingStopped/components';
import LaunchIcon from '@mui/icons-material/Launch';
class TabUserFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      open_create: false,
      rows_edited: [],
      row_clicked: null,
      loading: false,
      copying: false,
      cutting: false,
      uploading: false,
      deleting: false,
      selected: [],
    };
  }
  isSelected = file => {
    const { selected } = this.state;
    const index = selected.map(x => x.idFileObject).indexOf(file.idFileObject);
    return index !== -1;
  };
  handleSelectedClick = (event, index, file) => {
    event.preventDefault();
    //
    const { selected } = this.state;
    const selectedIndex = selected
      ? selected.map(x => x.idFileObject).indexOf(file.idFileObject)
      : 0;
    const newSelected = SelectedArray(selected, file, selectedIndex);
    this.setState({ selected: newSelected });
    return newSelected.length;
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const { rows } = this.props;
      this.setState({ selected: rows });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleClearSelected = () => {
    this.setState({ selected: [] });
  };
  handleCopy = folder_contents => {
    const total = folder_contents.length;
    if (total) {
      this.props.copy(folder_contents);
      this.props.handleAddSuccess(`Copy ready for explorer (${total})`);
    } else {
      this.props.handleAddError(
        'To Copy you need to select (Folder/Object) first.'
      );
    }
    this.setState({ copying: false });
  };
  handleCut = folder_contents => {
    const total = folder_contents.length;
    if (total) {
      this.props.cut(folder_contents);
      this.props.handleAddSuccess(`Cut ready for explorer (${total})`);
    } else {
      this.props.handleAddError(
        'To Cut you need to select (Folder/Object) first.'
      );
    }
    this.setState({ cutting: false });
  };
  getInputs = (location = false) => {
    const { row_clicked } = this.state;
    if (IsInvalid(row_clicked)) return null;
    const { locations } = row_clicked;
    if (IsInvalid(locations) || !locations.length) return null;
    if (location) {
      return locations[0].Folder;
    }
    const { project } = this.props;
    if (IsInvalid(project)) return null;
    return {
      project,
      idFolderContent: locations[0].idFolderContent,
      folder: locations[0].Folder,
    };
  };
  handleShowExplorer = () => {
    const inputs = this.getInputs(true);
    if (inputs) this.props.history.push(`/explorer/folder/${inputs.idFolder}`);
  };
  handleAdvanceSearch = event => {
    if (!event) return;
    if (event.key !== 'Enter') return;
    const { search } = this.state;
    this.props.handleAdvanceSearch(search);
  };
  render() {
    const {
      type,
      limit,
      loading,
      page,
      rows,
      user,
      total,
      height,
    } = this.props;
    const { deleting, selected, row_clicked, search } = this.state;
    let type_selected = 'User: ';
    let name_selected = 'All';
    let title = 'User Files';
    if (user) {
      name_selected = user.FullName;
    } else if (this.props.project) {
      name_selected = this.props.project.name;
    }
    if (type !== 'users') {
      type_selected = 'Project: ';
      title = 'Project Files';
    }
    // console.log({ row_clicked });
    if (this.state.open_create) {
      const data = this.getInputs();
      return (
        <CreateLandingObject
          handleAddError={this.props.handleAddError}
          handleAddLanding={() => this.setState({ open_create: false })}
          handleClose={() => this.setState({ open_create: false })}
          project={this.props.project}
          {...data}
        />
      );
    }
    const disabled = !selected.length;
    const ComponentAdditional = (
      <Grid
        alignItems="center"
        container
        direction="row"
        item
        justifyContent="center">
        <Grid item>
          <IconButtonLoad
            disabled={!row_clicked || this.state.uploading}
            handleClick={() => this.handleShowExplorer()}
            icon={
              <LaunchIcon style={{ color: row_clicked ? 'green' : 'gray' }} />
            }
            tooltip="Show File at Explorer"
          />
        </Grid>
        <Grid item>
          <IconButtonLoad
            disabled={!row_clicked || this.state.uploading}
            handleClick={() => this.setState({ open_create: true })}
            icon={
              <UploadFileIcon
                style={{ color: row_clicked ? 'blue' : 'gray' }}
              />
            }
            loading={this.state.uploading}
            tooltip="Create Landing Objects"
          />
        </Grid>
        <Grid item>
          <IconButtonLoad
            disabled={disabled || this.state.copying}
            handleClick={() => handleQueryContent(false, this)}
            icon={
              <ContentCutIcon style={{ color: disabled ? 'gray' : 'blue' }} />
            }
            length={this.props.cut_objects.length}
            loading={this.state.cutting}
            tooltip="Cut Files"
          />
        </Grid>
        <Grid>
          <IconButtonLoad
            disabled={disabled || this.state.cutting}
            handleClick={() => handleQueryContent(true, this)}
            icon={
              <CopyAllIcon style={{ color: disabled ? 'gray' : 'green' }} />
            }
            length={this.props.copy_objects.length}
            loading={this.state.copying}
            tooltip="Copy Files"
          />
        </Grid>
      </Grid>
    );
    return (
      <Grid container>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          spacing={5}
          style={{ margin: 10, marginLeft: 15 }}
          xs={12}>
          <RowText
            display="block ruby"
            first={type_selected}
            second={name_selected}
            style={{ marginRight: 10 }}
          />
          <RowText display="block ruby" first="Total files: " second={total} />
          <Grid item style={{ padding: 0 }} xs>
            <SearchTransparent
              onChange={event => {
                this.setState({ search: event.target.value });
              }}
              onKeyPress={this.handleAdvanceSearch}
              value={search}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <MainTableToolbar
            ComponentAdditional={ComponentAdditional}
            handleClearSelected={this.handleClearSelected}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={() => this.props.handleReloadTable()}
            loading={deleting}
            loading_table={loading}
            numSelected={this.state.selected.length}
            title={title}
          />
        </Grid>
        <Divider />
        <TableUserFiles
          deleting={deleting}
          disable_splice
          handleChangePage={this.props.handleChangePage}
          handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
          height={height}
          limit={limit}
          loading={loading}
          onSortHeaderClicked={sort => this.props.onSortHeaderClicked(sort)}
          page={page}
          rows={rows}
          setState={state => this.setState(state)}
          state={this.state}
          total={total}
          user={user}
        />
      </Grid>
    );
  }
}

TabUserFiles.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  //
  const { manager } = state;
  const objects = manager.cut.concat(manager.copy).concat(manager.copy_series);

  return {
    cut_objects: manager.cut,
    copy_objects: manager.copy,
    copy_series: manager.copy_series,
    loading_paste: manager.loading_paste,
    error_manager: manager.error_manager,
    warning_manager: manager.warning_manager,
    size: objects.length,
  };
};
export default connect(mapStateToProps, {
  cut,
  copy,
  paste,
  clean_manager_error,
})(TabUserFiles);
