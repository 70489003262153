import React from 'react';
import { Checkbox } from '@mui/material';
import { FormatTime, getUserFullName } from 'helpers';

export { default } from './TableProjectMemberSetting';

export const columns = [
  {
    id: 'user',
    label: 'Member',
    type: 'user',
    minWidth: 300,
    align: 'left',
    format: value => {
      if (!value) return 'Not defined';
      return getUserFullName(value);
    },
  },
  {
    id: 'access_level',
    label: 'Access Level',
    minWidth: 150,
    format: value => {
      if (!value) return 'Not defined';
      return value.description;
    },
  },
  {
    id: 'email_notification',
    label: 'Email Notification',
    minWidth: 50,
    align: 'center',
    format: value => {
      return <Checkbox checked={value} />;
    },
  },
  {
    id: 'upload_notification',
    label: 'Upload Notification',
    minWidth: 50,
    align: 'center',
    format: value => {
      return <Checkbox checked={value} />;
    },
  },
  {
    id: 'updatedAt',
    label: 'Last Update',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
  {
    id: 'createdAt',
    label: 'Creation Date',
    minWidth: 170,
    align: 'right',
    format: value => FormatTime(value, 'DD/MM/YYYY'),
  },
];
