import { FormatBytes, IsInvalid, IsInvalidParam } from 'helpers';
import { FormatTime } from 'helpers';
import moment from 'moment';

export const columns_signals_edf = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'file_type',
    headerName: 'Type',
    align: 'left',
  },
  {
    field: 'admincode',
    headerName: 'Admincode',
    align: 'left',
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    align: 'left',
    // width: 200,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('start_date');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMMM Do - YYYY');
    },
  },
  {
    field: 'start_time',
    headerName: 'Start Time',
    align: 'left',
    // width: 200,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('start_time');
      if (value === undefined || !value) return '';
      value = moment(value, 'HH:mm:ss').valueOf();
      return FormatTime(value, 'h:mm a');
    },
  },
  {
    field: 'file_duration',
    headerName: 'Duration',
    align: 'left',
  },
  {
    field: 'number_of_channels',
    headerName: '#Channels',
    align: 'left',
  },
  {
    field: 'number_of_annotations',
    headerName: '#Annotations',
    align: 'left',
  },
  {
    field: 'number_of_datarecords',
    headerName: '#Datarecords',
    align: 'left',
  },
  {
    field: 'file_object',
    headerName: 'Filename',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let file_object = params.getValue('file_object');
      if (file_object === undefined || !file_object) return '';
      const { original_name } = file_object;
      return original_name;
    },
  },
  {
    field: 'file_object',
    headerName: 'Size',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let file_object = params.getValue('file_object');
      if (file_object === undefined || !file_object) return '';
      const { Size } = file_object;
      return FormatBytes(Size);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
