import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ButtonIconLoad } from 'components';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { withStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors';
const useStyles = theme => ({
  button: {
    width: '100px',
  },
  button_red: {
    width: '100px',
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
});
class ButtonsAcceptDelete extends React.Component {
  render() {
    const { classes, accepting, deleting } = this.props;
    return (
      <Grid container justifyContent="space-between" sx={{ paddingTop: 5 }}>
        <Grid item>
          <ButtonIconLoad
            className={classes.button}
            disabled={deleting}
            handleClick={this.props.handleAcceptUsers}
            icon={<SaveIcon />}
            loading={accepting}
            text="Accept"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <ButtonIconLoad
            className={classes.button_red}
            disabled={accepting}
            handleClick={this.props.handleDeleteUsers}
            icon={<DeleteIcon />}
            loading={deleting}
            text="Delete"
          />
        </Grid>
      </Grid>
    );
  }
}

ButtonsAcceptDelete.propTypes = {
  accepting: PropTypes.bool,
  deleting: PropTypes.bool,
  handleAcceptUsers: PropTypes.func,
  handleDeleteUsers: PropTypes.func,
};
ButtonsAcceptDelete.defaultProps = {
  accepting: false,
  deleting: false,
  handleAcceptUsers: () => '',
  handleDeleteUsers: () => '',
};
export default withStyles(ButtonsAcceptDelete, useStyles);
