import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
class AaocaBottom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { active } = this.props;
    return (
      <Paper
        sx={{
          p: 5,
          width: '100%',
          border: `1px solid ${active ? '#f5f5f5' : ''}`,
          borderRadius: '16px',
        }}>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Grid item sx={{ width: 110 }}>
            <CampaignIcon sx={{ color: 'red', width: 100, height: 100 }} />
          </Grid>
          <Grid item xs>
            <Typography>
              *Anatomical risk definition. Low-risk: pre-pulmonic course,
              retro-aortic course, high take-off. High-risk: inter-arterial
              course, sub-pulmonic course.
            </Typography>
            <Typography sx={{ color: 'red', fontWeight: 600, fontSize: 20 }}>
              This software is intended for research purposes only and has not
              been approved for clinical use!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

AaocaBottom.propTypes = {
  classes: PropTypes.object,
};

export default AaocaBottom;
