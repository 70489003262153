import { IsInvalidParam } from 'helpers';
import { IsInvalid, FormatTime, getUserFullName } from 'helpers';
export const columns_sessions = [
  {
    field: 'id',
    headerName: 'ID',
    align: 'left',
  },
  {
    field: 'user',
    headerName: 'User',
    align: 'left',
    width: 222,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const admin = params.getValue('user');
      if (IsInvalid(admin)) return '';
      return getUserFullName(admin);
    },
  },
  {
    field: 'timestamp',
    headerName: 'Time Stamp',
    align: 'left',
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('timestamp');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'city',
    headerName: 'City',
    align: 'left',
  },
  {
    field: 'country',
    headerName: 'Country',
    align: 'left',
  },
  {
    field: 'count_logins',
    headerName: 'Logins',
    align: 'left',
  },
  {
    field: 'count_refresh',
    headerName: 'Refresh',
    align: 'left',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
