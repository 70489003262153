import { IsInvalidParam } from 'helpers';
import { IsInvalid } from 'helpers';
import { FormatTime } from 'helpers';

export const columns_series = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const type = params.getValue('type');
      if (IsInvalid(type)) return 'Type is Missing';
      const { name } = type;
      if (name) return name;
      return 'Type is Missing';
    },
  },
  {
    field: 'name',
    headerName: 'Value',
    align: 'left',
    minWidth: 50,
  },
  {
    field: 'Sequence',
    headerName: 'Sequence',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const series = params.getValue('series');
      if (IsInvalid(series)) return '';
      const { sequence } = series;
      if (sequence) return sequence.name;
      return '';
    },
  },
  {
    field: 'AcquisitionType',
    headerName: 'AcquisitionType',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const series = params.getValue('series');
      if (IsInvalid(series)) return '';
      const { ImageMr } = series;
      if (ImageMr) return ImageMr.MRAcquisitionType;
      return '';
    },
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const series = params.getValue('series');
      if (IsInvalid(series)) return '';
      if (series) return series.SeriesDescription;
      return '';
    },
  },
  {
    field: 'ProtocolName',
    headerName: 'ProtocolName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const series = params.getValue('series');
      if (IsInvalid(series)) return '';
      const { ImageMr } = series;
      if (ImageMr) return ImageMr.ProtocolName;
      return '';
    },
  },
  {
    field: 'SequenceName',
    headerName: 'SequenceName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const series = params.getValue('series');
      if (IsInvalid(series)) return '';
      const { ImageMr } = series;
      if (ImageMr) return ImageMr.SequenceName;
      return '';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    minWidth: 100,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const updatedAt = params.getValue('updatedAt');
      if (updatedAt) return FormatTime(updatedAt, 'MMM Do - YYYY, h:mm a');
      return '';
    },
  },
];

export const columns_files = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const type = params.getValue('type');
      if (IsInvalid(type)) return 'Type is Missing';
      const { name } = type;
      if (name) return name;
      return 'Type is Missing';
    },
  },
  {
    field: 'name',
    headerName: 'Value',
    align: 'left',
    minWidth: 50,
  },
  {
    field: 'FileName',
    headerName: 'FileName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const file = params.getValue('file');
      if (file) return file.original_name;
      return '';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    minWidth: 100,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const updatedAt = params.getValue('updatedAt');
      if (updatedAt) return FormatTime(updatedAt, 'MMM Do - YYYY, h:mm a');
      return '';
    },
  },
];
