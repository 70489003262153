const countDecimalPlaces = number => {
  const str = number.toString();
  if (str.includes('.')) {
    return str.split('.')[1].length; // Count the digits after the decimal point
  }
  return 0; // No decimal point means no floating digits
};
export const chart = {
  chart: {
    type: 'radialBar',
    height: 300,
    offsetY: 20,
    sparkline: {
      enabled: true,
    },
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      hollow: {
        size: '90%',
      },
      track: {
        background: '#e7e7e7',
        strokeWidth: '90%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: '#999',
          opacity: 1,
          blur: 2,
        },
      },
      dataLabels: {
        show: true,
        name: {
          show: false,
          fontSize: '16px',
          fontWeight: 400,
          color: '#6b7280',
          offsetY: 60,
        },
        value: {
          show: true,
          fontSize: '32px',
          fontWeight: 600,
          color: '#111827',
          offsetY: -10,
          formatter: function(val) {
            const l = countDecimalPlaces(val);
            if (l > 3) return val.toFixed(3) + '%';
            else return val + '%';
          },
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: ['#6366f1'],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'round',
    dashArray: 0,
  },
};
