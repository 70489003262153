const statusColors = {
  finished: 'success', //green
  running: 'info', //orange
  error: 'danger', //red
  failed: 'danger', //red
  waiting: 'warning', //warning
  neutral: 'neutral',
  purple: 'purple',
};

import { IsInvalid, IsInvalidParam } from 'helpers';
import {
  getUserFullName,
  FormatSeriesTime,
  getPatientName,
  FormatSeriesDate,
  FormatBytes,
  FormatTime,
} from 'helpers';
export const columns_files = [
  {
    field: 'idFileObject',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'Owner',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('Owner');
      if (IsInvalid(value)) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'project',
    headerName: 'Project',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('project');
      if (IsInvalid(value)) return '';
      return value.name;
    },
  },
  {
    field: 'original_name',
    headerName: 'Filename',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'Size',
    headerName: 'Size',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('Size');
      if (IsInvalid(value)) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'derivated',
    headerName: 'Derivated',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const derivated = params.getValue('derivated');
      if (derivated === undefined || !derivated) return '';
      return derivated > 0 ? 'YES' : '';
    },
  },
  {
    field: 'locations',
    headerName: 'Folder',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('locations');
      if (IsInvalid(value) || !value.length) return '';
      const { Folder } = value[0];
      if (IsInvalid(Folder)) return '';
      const { folders_paths } = Folder;
      if (IsInvalid(folders_paths) || !folders_paths.length) return '';
      return folders_paths.map(x => x.name).join('/');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
export const columns_series = [
  { field: 'idSeries', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'Patient',
    headerName: 'Patient Name',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('Patient');
      if (IsInvalid(value)) return '';
      return getPatientName(value, false);
    },
  },
  {
    field: 'PatientID',
    headerName: 'PatientID',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('Patient');
      if (IsInvalid(value)) return '';
      const { PatientID } = value;
      if (PatientID === undefined || !PatientID) return '';
      return PatientID;
    },
  },
  { field: 'idStudy', headerName: 'ID Study', width: 75, align: 'left' },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    width: 75,
    align: 'left',
  },
  {
    field: 'sequence',
    headerName: 'Sequence',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let sequence = params.getValue('sequence');
      if (sequence === undefined || !sequence) return '';
      return sequence.name;
    },
  },
  {
    field: 'Modality',
    headerName: 'Modality',
    align: 'left',
  },
  {
    field: 'nifti',
    headerName: 'NIfTI',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('nifti');
      if (IsInvalid(value)) return '';
      return value > 0 ? 'YES' : '';
    },
  },
  {
    field: 'NumberOfSeriesRelatedInstances',
    headerName: '#Files',
    align: 'left',
  },
  {
    field: 'SeriesDate',
    headerName: 'Dates',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('SeriesDate');
      if (IsInvalid(value)) return '';
      return FormatSeriesDate(value, 'yyyy/MM/DD');
    },
  },
  {
    field: 'SeriesTime',
    headerName: 'Times',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('SeriesTime');
      if (IsInvalid(value)) return '';
      return FormatSeriesTime(value, 'h:mm a');
    },
  },
  {
    field: 'locations',
    headerName: 'Folder',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('locations');
      if (IsInvalid(value) || !value.length) return '';
      const { Folder } = value[0];
      if (IsInvalid(Folder)) return '';
      const { folders_paths } = Folder;
      if (IsInvalid(folders_paths) || !folders_paths.length) return '';
      return folders_paths.map(x => x.name).join('/');
    },
  },
  {
    field: 'SeriesInstanceUID',
    headerName: 'UIDs',
    align: 'left',
  },
];
export const columns_jobs = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'idGroupJob',
    headerName: 'Job Group',
    width: 75,
    align: 'left',
  },
  {
    field: 'docker_image',
    headerName: 'JOB Name',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('docker_image');
      if (IsInvalid(value)) return '';
      return value.operation;
    },
  },
  {
    field: 'user',
    headerName: 'User',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('user');
      if (IsInvalid(value)) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 75,
    align: 'left',
    type: 'status',
    color: value => {
      if (IsInvalid(value) || value === '') return 'neutral';
      const i = Object.keys(statusColors).indexOf(value);
      if (i === -1) return 'neutral';
      return statusColors[value];
    },
  },
  {
    field: 'idSeries',
    headerName: 'ID Series',
    width: 75,
    align: 'left',
  },
  {
    field: 'series',
    headerName: 'SeriesDescription',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let series = params.getValue('series');
      if (IsInvalid(series)) return '';
      return series.SeriesDescription;
    },
  },
  {
    field: 'idFileObject',
    headerName: 'ID File',
    width: 75,
    align: 'left',
  },
  {
    field: 'file_object',
    headerName: 'File Object',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let file_object = params.getValue('file_object');
      if (IsInvalid(file_object)) return '';
      return file_object.original_name;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
