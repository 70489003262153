/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DropFiles } from 'components';
import { withStyles } from 'tss-react/mui';
const useStyles = () => ({
  drop: {
    fontSize: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
});
import UploadRight from './UploadRight';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';
import { MutationUploadFile } from 'common';
const Span = styled('span')(() => ({
  color: 'red',
  fontWeight: 600,
}));
class AaocaUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [
        // { name: 'series.zip' }
      ],
      total_files: 0,
      total_uploaded: 0,
      uploaded: 0,
      progress: 0,
    };
    this.holder = {
      deleteFile: null,
      clearAll: null,
      getSettings: null,
    };
    const { holder } = props;
    if (holder) {
      holder.setTotal = total_uploaded => this.setState({ total_uploaded });
      holder.startUpload = () => this.handleUploadFile();
    }
  }
  handleChange = files => {
    const total_files = files.length;
    this.setState({ files, total_files });
    this.props.setState({ files });
    if (!total_files) {
      this.props.setState({ value: 0 });
      if (this.props.holder.reset) {
        this.props.holder.reset();
      }
      if (this.holder.clearAll) {
        this.holder.clearAll();
      }
    }
  };
  handleDelete = file => {
    if (IsInvalid(file)) return;
    let { files } = this.state;
    files = files.filter(f => f !== file);
    if (this.holder.deleteFile) {
      this.holder.deleteFile(file);
    }
    this.handleChange(files);
    this.props.handleResetToStartAgain();
  };
  handleProgress = event => {
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = loaded;
    }
    const progress = Math.floor((loaded * 100) / total);
    console.log({ loaded, total });
    this.setState({ progress, uploaded: loaded });
  };
  handleUploadFile = () => {
    const { access } = this.props;
    if (IsInvalid(access)) {
      this.props.handleAddError('Invalid activation');
      return;
    }
    const { loading, files } = this.state;
    if (loading) return;
    let settings = {
      public_app: true,
      idUserTemp: access.id,
    };
    (async () => {
      MutationUploadFile(files[0], this.handleProgress, null, settings)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.uploadFile;
          console.log({ ok, errors });
          if (ok) {
            const { contents, warnings } = data.uploadFile;
            console.log({ contents, warnings });
            if (contents.length) {
              this.setState({ loading: false });
              this.props.handleFinshed(contents[0]);
            } else {
              throw Error('Error uploading the file');
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, active, full_run, enabled } = this.props;
    const { files } = this.state;
    return (
      <Paper
        disabled={active || full_run}
        sx={{
          p: 5,
          width: '100%',
          border: `${active ? '2px' : '1px'} solid ${
            active ? 'green' : full_run ? '#f5f5f5' : ''
          }`,
          borderRadius: '16px',
        }}>
        <Grid
          container
          direction="column"
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Grid item pb={5} xs={12}>
            <Typography variant="h6">
              Upload Your Coronary Computed Tomography Angiography (CCTA) Images
              in DICOM or NIfTI Format.
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            item
            sx={{
              pt: 0,
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            xs={12}>
            {enabled ? (
              files.length ? (
                <Grid
                  item
                  sx={{ width: '100%', p: 1, height: '100%', pt: 0 }}
                  xs={12}>
                  <UploadRight
                    file={files.length ? files[0] : null}
                    handleFinshed={this.props.handleFinshed}
                    onDelete={this.handleDelete}
                    progress={this.state.progress}
                    setParentState={state => this.setState(state)}
                    setState={state => this.props.setState(state)}
                    uploaded={this.state.uploaded}
                  />
                </Grid>
              ) : (
                <Grid item sx={{ width: '100%', height: '100%' }} xs={12}>
                  <DropFiles
                    acceptedFiles={[
                      '.nii',
                      '.nii.gz',
                      'application/zip',
                      '.zip',
                    ]}
                    box_style={{ minHeight: 180 }}
                    dropzoneParagraphClass={classes.drop}
                    filesLimit={100}
                    holder={this.holder}
                    maxFileSize={5000000000}
                    onChange={this.handleChange}
                    position_snack={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    showPreviewsInDropzone={false}
                  />
                </Grid>
              )
            ) : (
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  color: 'blue',
                  fontSize: '2rem',
                  lineHeight: '2.1rem',
                  p: 5,
                }}>
                To use AAOCA you need to activate the system with the{' '}
                <span style={{ color: 'green' }}>CODE</span> and your{' '}
                <span style={{ color: 'green' }}>email</span>.
              </Typography>
            )}
          </Grid>
          <Grid
            container
            item
            sx={{
              paddingTop: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            xs={12}>
            <Typography
              sx={{ width: '100%', textAlign: 'center', color: 'blue' }}>
              Only <Span>DICOM</Span> in a <Span>ZIP</Span> file or{' '}
              <Span>NIfTI</Span> with format{' '}
              <Span style={{ fontSize: '1.2rem' }}>.nii</Span> or{' '}
              <Span style={{ fontSize: '1.2rem' }}>.nii.gz</Span> are accepted.
            </Typography>
            <Typography>
              Your images will be automatically anonymized and securely uploaded
              to the server.
            </Typography>
            <Typography>
              If cropped images are uploaded, the segmentation and cropping
              modules will be skipped!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

AaocaUpload.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(AaocaUpload, useStyles);
