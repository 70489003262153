const StringDesc = (text_a, text_b, orderBy) => {
  if (orderBy === 'createdAt' || orderBy === 'updatedAt') {
    if (parseInt(text_b, 10) < parseInt(text_a, 10)) {
      return -1;
    }
    if (parseInt(text_b, 10) > parseInt(text_a, 10)) {
      return 1;
    }
  } else {
    if (text_b.localeCompare(text_a) < 0) {
      return -1;
    }
    if (text_b.localeCompare(text_a) > 0) {
      return 1;
    }
  }
};
const IsDateTime = column => {
  if (column === undefined || !column) return false;
  const { type } = column;
  if (type === undefined || !type) return false;
  if (type === 'time' || type === 'date') return true;
  return false;
};
function desc(a, b, orderBy, columns = null) {
  let A = a[orderBy];
  let B = b[orderBy];
  if (!A && typeof B === 'string') {
    A = '';
  }
  if (!B && typeof A === 'string') {
    B = '';
  }

  if (columns) {
    const index = columns
      .map(x => x.field)
      .filter(x => x)
      .indexOf(orderBy);
    if (index !== -1 && index >= 0 && index < columns.length) {
      if (columns[index].valueGetter && !IsDateTime(columns[index])) {
        const params_a = {
          ...a,
          getValue: name => {
            if (a === undefined) return '';
            if (a[name] === undefined) return '';
            if (a[name]) return a[name];
            return '';
          },
          getHeader: name => {
            if (columns[index] === undefined) return '';
            if (columns[index][name] === undefined) return '';
            if (columns[index][name]) return columns[index][name];
            return '';
          },
        };
        const params_b = {
          ...b,
          getValue: name => {
            if (b[name] === undefined) return '';
            if (b[name]) return b[name];
            return '';
          },
          getHeader: name => {
            if (columns[index] === undefined) return '';
            if (columns[index][name] === undefined) return '';
            if (columns[index][name]) return columns[index][name];
            return '';
          },
        };
        A = columns[index].valueGetter(params_a);
        if (typeof A === 'string') A = A.trim();
        B = columns[index].valueGetter(params_b);
        if (typeof B === 'string') B = B.trim();
        // console.log({ a, T: typeof A, A, b, T: typeof B, B });
        // console.log({ T: typeof B, B });
        if (typeof A === 'number' && typeof B === 'number') {
          if (!A || A === undefined) A = 0;
          if (!B || B === undefined) B = 0;
          if (B < A) {
            return -1;
          }
          if (B > A) {
            return 1;
          }
          return 0;
        }
      } else if (IsDateTime(columns[index])) {
        A = parseInt(A, 10);
        B = parseInt(B, 10);
      }
    }
    // console.log({ index, orderBy, A, B });
  } else {
    // console.log({ orderBy, T: typeof A, A, T: typeof B, B });
  }
  // if (A === undefined) console.log({ a });
  if (typeof A === 'string' && typeof B === 'string') {
    return StringDesc(A, B, orderBy);
  } else if (
    A &&
    B &&
    columns &&
    A.constructor === Object &&
    B.constructor === Object
  ) {
    const index = columns.map(x => x.id).indexOf(orderBy);
    if (index !== -1 && columns[index].format) {
      const object_a = columns[index].format(a[orderBy]);
      const object_b = columns[index].format(b[orderBy]);
      if (typeof object_a === 'string' && typeof object_a === 'string') {
        return StringDesc(object_a, object_b, orderBy);
      }
    }
  } else {
    if (!A || A === undefined) A = 0;
    if (!B || B === undefined) B = 0;
    if (B < A) {
      return -1;
    }
    if (B > A) {
      return 1;
    }
  }
  return 0;
}

export function getSorting(order, orderBy, columns = null) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy, columns)
    : (a, b) => -desc(a, b, orderBy, columns);
}

export function stableSort(array, cmp, disable = false) {
  if (disable) return array;
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
