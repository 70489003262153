import moment from 'moment';
import { IsInvalid } from 'helpers';
import { cases, resection, research_study, outcome } from './utils_columns';
import { header_patients } from '../../PatientClinics/components';
const base = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'patient',
    headerName: 'PatientID',
    edit: true,
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('patient');
      if (IsInvalid(value)) return '';
      return value.PatientID;
    },
    editerGetter: (row, value) => {
      row = {
        ...row,
        patient: {
          PatientID: value,
        },
      };
      return row;
    },
  },
];
export const getHeader = (index = 0) => {
  let headers = [];
  if (index === 1) headers = base.concat(cases);
  else if (index === 2) headers = base.concat(research_study);
  else if (index === 3) headers = base.concat(resection);
  else if (index === 4) headers = base.concat(outcome);
  else headers = header_patients;
  return headers.map((x, index) => {
    if (index === 0) return x;
    return {
      ...x,
    };
  });
};
export const getEmptyRow = index => {
  let headers = [...base];
  if (index === 1) headers = headers.concat(cases);
  else if (index === 2) headers = headers.concat(research_study);
  else if (index === 3) headers = headers.concat(resection);
  else if (index === 4) headers = headers.concat(outcome);
  else headers = header_patients;
  let row = {};
  for (let i = 0; i < headers.length; i++) {
    const key = headers[i].field;
    let value = '';
    if (
      key === 'createdAt' ||
      key === 'updatedAt' ||
      key === 'date' ||
      key === 'spec_ictal' ||
      key === 'music_start_date' ||
      key === 'music_end_date' ||
      key === 'date_pre_implantation_mr' ||
      key === 'date_post_implantation_ct' ||
      key === 'first_seizure_date'
    ) {
      value = `${moment().valueOf()}`;
    }
    if (headers[i].type === 'boolean') {
      value = false;
    }
    row = {
      ...row,
      [key]: value,
      edit: true,
    };
    if (headers[i].editerGetter) {
      row = headers[i].editerGetter(row, '');
    }
  }
  return row;
};
