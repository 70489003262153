import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
export const useStyles = () => ({
  root: {},
});
export const columns_project_studies = [
  {
    field: 'id',
    headerName: 'ID',
    width: 75,
    style: {
      width: 75,
      maxWidth: 90,
    },
    align: 'left',
  },
  {
    field: 'study',
    headerName: 'PatientName',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('study');
      if (value === undefined || !value) {
        return '';
      } else {
        const { Patient } = value;
        if (Patient === undefined || !Patient) return '';
        return Patient.PatientName;
      }
    },
  },
  {
    field: 'study',
    headerName: 'PatientID',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('study');
      if (value === undefined || !value) {
        return '';
      } else {
        const { Patient } = value;
        if (Patient === undefined || !Patient) return '';
        return Patient.PatientID;
      }
    },
  },
  {
    field: 'study',
    headerName: 'Study',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('study');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.StudyDescription;
      }
    },
  },
  {
    field: 'study_code',
    headerName: 'Level 2',
    align: 'left',
    edit: true,
    style: {
      // width: 190,
      minWidth: 50,
    },
  },
  {
    field: 'studydate',
    headerName: 'Study Date',
    align: 'left',
    edit: true,
    style: {
      width: 120,
      minWidth: 120,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('study');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.StudyDate;
      }
    },
  },
  {
    field: 'StudyInstanceUID',
    headerName: 'StudyInstanceUID',
    align: 'left',
    edit: true,
    style: {
      width: 120,
      minWidth: 120,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('study');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.StudyInstanceUID;
      }
    },
  },
  {
    field: 'center',
    headerName: 'Center',
    align: 'left',
    edit: true,
    style: {
      width: 80,
      minWidth: 90,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('center');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.name;
      }
    },
  },
  {
    field: 'total_series',
    headerName: '#Series',
    align: 'left',
    style: {
      width: 60,
      // padding: 0,
      minWidth: 60,
      maxWidth: 110,
    },
  },
  {
    field: 'mappings',
    headerName: 'Visit',
    align: 'left',
    style: {
      width: 60,
      minWidth: 60,
      // maxWidth: 100,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      let value = params.getValue('mappings');
      if (value === undefined || !value) {
        return '';
      } else if (Array.isArray(value)) {
        const types = value.filter(x => {
          const { type } = x;
          if (type === undefined || !type) return false;
          let i = type.name.toLowerCase().indexOf('visit');
          if (i === -1) {
            i = type.name.toLowerCase().indexOf('timepoint');
            if (i === -1) return false;
          }
          return true;
        });
        if (types.length) return types[0].name;
        value = params.getValue('study');
        if (value === undefined || !value) return '';
        return value.StudyDate;
      } else return '';
    },
  },
  {
    field: 'mappings_type',
    headerName: '#Maps',
    align: 'left',
    style: {
      width: 50,
      minWidth: 70,
      maxWidth: 100,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('mappings');
      if (value === undefined || !value) {
        return 0;
      } else if (Array.isArray(value)) {
        return value.length;
      } else return 0;
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do, YYYY (h:mm a)');
    },
  },
];
