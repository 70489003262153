import React from 'react';
import PropTypes from 'prop-types';
import { Button, TableCell, Typography } from '@mui/material';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import { StatusBullet } from 'components';
import { IsInvalid, IsValid } from 'helpers';

import moment from 'moment';
import TableGridDatePicker from './TableGridDatePicker';
import TableGridRowCellCheckbox from './TableGridRowCellCheckbox';
import TableGridSelect from './TableGridSelect';
import { useStyles, InputBaseCustom } from './StyleCell';

class TableGridRowCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }
  componentDidUpdate() {
    const { isActive } = this.props;
    const { edit } = this.state;
    if (!isActive && edit) {
      this.setState({ edit: false });
    }
  }
  handleDoubleClick = () => {
    const { header, value, row } = this.props;
    if (header === undefined) return;
    const { field, edit } = header;
    // console.log('handleDoubleClick', { field, edit });
    if (edit === undefined || !edit) {
      this.props.onRowDoubleClick(row);
      return;
    }
    this.setState({ edit: true });
    this.props.onCellDoubleClick(field, value);
  };
  handleTextChange = event => {
    const { header, isActive } = this.props;
    if (header === undefined || isActive === undefined || !isActive) return;
    const { field, edit } = header;
    if (edit === undefined || !edit) return;
    this.props.onTextChanged(field, event.target.value);
  };
  handleSingleClick = () => {
    const { edit } = this.state;
    if (edit) return;
    const { header } = this.props;
    if (header === undefined) return;
    const { field } = header;
    this.props.onCellClick(field);
  };
  handlePaste = event => {
    const pastedData = event.clipboardData.getData('text');
    const { header } = this.props;
    if (header === undefined) return;
    const { field } = header;
    const { row } = this.props;
    this.props.onCellPaste({ cell: field, row }, pastedData);
  };
  handleDateChange = value => {
    this.setState({ edit: true });
    const { header } = this.props;
    if (header === undefined) return;
    const { field } = header;
    this.props.onTextChanged(field, value.valueOf().toString());
  };
  handleCheckboxChange = value => {
    this.setState({ edit: true });
    const { header } = this.props;
    if (header === undefined) return;
    const { field } = header;
    this.props.onTextChanged(field, value);
  };

  render() {
    const {
      header,
      classes,
      value,
      grid,
      cell_selection,
      isActive,
      rowIndex,
      colIndex,
      row,
    } = this.props;
    let style = null;
    let scope = null;
    if (header.style) style = header.style;
    if (header.scope) scope = header.scope;
    let align = 'center';
    if (header.numeric) align = 'right';
    if (header.align) align = header.align;
    let ComponentCell = value;
    if (this.state.edit) {
      let add_border = {};
      if (grid) {
        add_border = { borderLeft: '1px solid green' };
      }
      ComponentCell = (
        <InputBaseCustom
          onChange={this.handleTextChange}
          onKeyDown={event => {
            const { key } = event;
            if (key === 'Enter') {
              const { header } = this.props;
              let field = null;
              if (header) field = header.field;
              this.props.onCellKeyEnter(field);
            }
          }}
          style={{ width: '100%', margin: 0, padding: 0, ...add_border }}
          value={value}
        />
      );
    }

    if (header.type === 'status' && header.color) {
      const params = {
        ...row,
        getValue: name => {
          if (row[name] === undefined) return '';
          return row[name];
        },
        getHeader: name => {
          if (header[name] === undefined) return '';
          return header[name];
        },
      };
      ComponentCell = (
        <div className={classes.statusContainer}>
          <StatusBullet
            className={classes.status}
            color={header.color(value, params)}
            size="sm"
          />
          {value}
        </div>
      );
    } else if (header.type === 'button') {
      let { name, style } = header;
      if (IsInvalid(name) || name === '') {
        name = 'Click';
      }
      ComponentCell = (
        <Button
          onClick={() => this.props.onButtonClick(this.props.row)}
          style={style}
          variant="outlined">
          {name}
        </Button>
      );
    } else if (
      header.type === 'date' ||
      header.type === 'datetime' ||
      header.type === 'time'
    ) {
      let timestamp = null;
      if (header.edit && isActive) {
        if (
          IsValid(row[header.field]) &&
          (header.type === 'date' || header.type === 'datetime')
        ) {
          timestamp = moment(Number(row[header.field]));
        }
        if (IsValid(row[header.field]) && header.type === 'time') {
          timestamp = moment(Number(row[header.field]));
        }
        ComponentCell = (
          <TableGridDatePicker
            onDateChange={this.handleDateChange}
            style={style}
            type={header.type}
            value={timestamp}
          />
        );
      } else {
        if (IsValid(row[header.field])) {
          if (header.type === 'date')
            timestamp = moment(Number(row[header.field])).format(
              'MMM Do - YYYY'
            );
          if (header.type === 'datetime')
            timestamp = moment(Number(row[header.field])).format(
              'MMM Do - YYYY, h:mm a'
            );
          if (header.type === 'time')
            timestamp = moment(Number(row[header.field])).format('h:mm a');
        }
        ComponentCell = <Typography variant="body2">{timestamp}</Typography>;
      }
    } else if (header.type === 'boolean') {
      const value = row[header.field];
      if (header.edit && isActive) {
        ComponentCell = (
          <TableGridRowCellCheckbox
            onCheckboxChange={this.handleCheckboxChange}
            value={value}
          />
        );
      } else {
        ComponentCell = (
          <Typography sx={{ textAlign: 'center' }} variant="body2">
            {value ? 'Yes' : 'No'}
          </Typography>
        );
      }
    } else if (header.type === 'categories') {
      const value = row[header.field];
      if (header.edit && isActive) {
        const categories = header.options;
        ComponentCell = (
          <TableGridSelect
            categories={categories}
            onCheckboxChange={this.handleCheckboxChange}
            selectedValue={value}
          />
        );
      } else {
        ComponentCell = (
          <Typography sx={{ textAlign: 'center' }} variant="body2">
            {value}
          </Typography>
        );
      }
    } else if (header.getComponent) {
      ComponentCell = header.getComponent(header, row);
    }
    const style_cell_color = this.props.getColorCellText(row, value);
    if (style_cell_color) {
      style = {
        ...style,
        ...style_cell_color,
      };
    }
    let isCellSelected = !this.state.edit && cell_selection && isActive;
    let cell_select_box = null;
    if (this.props.IsCellSelected(rowIndex, colIndex)) {
      cell_select_box = this.props.getStyleBox(rowIndex, colIndex);
      if (cell_select_box) {
        style = {
          ...style,
          ...cell_select_box,
        };
      }
    }
    return (
      <TableCell
        align={align}
        className={clsx({
          [classes.base]: grid,
          [classes.boder]: this.state.edit,
          [classes.boder_selected]: isCellSelected,
          [classes.boder_left]: grid && !isCellSelected,
          [classes.disable_text]: cell_select_box,
        })}
        onClick={this.handleSingleClick}
        onDoubleClick={this.handleDoubleClick}
        onMouseDown={event =>
          this.props.onCellMouseDown(event, rowIndex, colIndex)
        }
        onMouseMove={event =>
          this.props.onCellMouseMove(event, rowIndex, colIndex)
        }
        onMouseUp={event => this.props.onCellMouseUp(event)}
        onPaste={this.handlePaste}
        scope={scope}
        style={style}>
        {ComponentCell}
      </TableCell>
    );
  }
}

TableGridRowCell.propTypes = {
  classes: PropTypes.object,
  grid: PropTypes.bool,
  header: PropTypes.object,
  isActive: PropTypes.bool,
  // value: PropTypes.string,
};
TableGridRowCell.defaultProps = {
  cell_selection: false,
  grid: false,
  header: '',
  value: '',
  isActive: false,
  onRowDoubleClick: () => '',
  onCellDoubleClick: () => '',
  onTextChanged: () => '',
  onCellClick: () => '',
  onCellKeyEnter: () => '',
  onCellPaste: () => '',
  onButtonClick: () => '',
  onCellMouseDown: () => '',
  onCellMouseMove: () => '',
  onCellMouseUp: () => '',
  getStyleBox: () => '',
  getColorCellText: () => {
    return null;
  },
};

export default withStyles(TableGridRowCell, useStyles);
