import React from 'react';
import PropTypes from 'prop-types';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
  AnatomicalRegion,
  ButtonCircularIconLoad,
  CardEditHeader,
  DivGrow,
  GreenCheckbox,
  ImageFocus,
  InvestigativedSide,
  Pathology,
  Sequence,
} from 'components';
import { CardActions, Divider, FormControlLabel, Grid } from '@mui/material';
import { TextStyle } from './components';
import { IsInvalid } from 'helpers';
import { MutationUpdateStudiesDetails } from 'graphql/Study';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
class EditProjectStudies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remove_details: false,
      saving: false,
      details: {
        focus: undefined, // { idFocus: -1, ...value },
        region: undefined, //{ idRegion: -1, ...value },
        pathology: undefined, // { idPathology: -1, ...value },
        sequence: undefined, //{ idSequence: -1, ...value },
        side: undefined, //{ idSide: -1, ...value },
      },
    };
    this.holder = {
      getPathology: null,
      getSide: null,
      getRegion: null,
      getFocus: null,
      getSequence: null,
      resetPathology: null,
      resetSide: null,
      resetRegion: null,
      resetFocus: null,
      resetSequence: null,
    };
  }
  handleSelectedChanged = (type, value) => {
    const { details } = this.state;
    // console.log({ type, value });
    // if (Object.keys(this.state).indexOf(type) === -1) {
    //   this.setState({ changed: true, details: { ...details, [type]: value } });
    // } else if (this.state[type].name !== value.name) {
    this.setState({ details: { ...details, [type]: value } });
    // }
  };
  getValue = (type, variable) => {
    let value = null;
    if (this.state.remove_details) value = -1;
    if (IsInvalid(variable)) return value;
    if (IsInvalid(variable[type])) return value;
    if (variable[type] > 0) return variable[type];
    return value;
  };
  getVariables = () => {
    const variables = {};
    const {
      details: { focus, region, pathology, sequence, side },
    } = this.state;
    variables.idFocus = this.getValue('idFocus', focus);
    variables.idRegion = this.getValue('idRegion', region);
    variables.idPathology = this.getValue('idPathology', pathology);
    variables.idSequence = this.getValue('idSequence', sequence);
    variables.idSide = this.getValue('idSide', side);
    if (Object.keys(variables).length) return variables;
    return null;
  };
  handleSave = () => {
    const { selected } = this.props;
    const { saving } = this.state;
    if (saving) return;
    const variables = this.getVariables();
    if (IsInvalid(variables)) {
      this.props.handleAddError('You need to select an option first');
      return;
    }
    const ids = selected.map(x => x.idStudy);
    this.setState({ saving: true });
    MutationUpdateStudiesDetails(ids, variables)
      .then(res => {
        const data = GetData(res);
        const { ok, errors } = data.updateStudySeriesDetails;
        if (ok) {
          this.setState({ saving: false });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.setState({ saving: false });
        this.props.handleAddError(ServerErrorsString(error));
      });
  };
  IsChanged = () => {
    const { details } = this.state;
    if (details.focus) return true;
    if (details.region) return true;
    if (details.pathology) return true;
    if (details.sequence) return true;
    if (details.side) return true;
    if (this.state.remove_details) return true;
    return false;
  };
  handleChangeRemove = event => {
    const remove_details = event.currentTarget.checked;
    this.setState({ remove_details });
  };
  render() {
    const { details } = this.state;
    const { selected } = this.props;
    console.log({ selected });
    const total_series = selected
      .map(x => x.total_series)
      .reduce((acc, num) => acc + num);
    return (
      <React.Fragment>
        <CardEditHeader
          description="To modify the current studies, select the information"
          handleClose={this.props.handleCloseEdit}
          icon={<AccountTreeIcon style={{ color: 'green' }} />}
          title="Current studies of the project"
        />
        <Divider />
        <Grid container>
          <TextStyle
            sub_title={`${selected.length}`}
            title="Selected studies:"
          />
          <TextStyle sub_title={`${total_series}`} title="Total series:" />
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <AnatomicalRegion
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              region={details.region}
            />
          </Grid>
          <Grid item>
            <ImageFocus
              focus={details.focus}
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
            />
          </Grid>
          <Grid item>
            <InvestigativedSide
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              side={details.side}
            />
          </Grid>
          <Grid item>
            <Pathology
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              pathology={details.pathology}
            />
          </Grid>
          <Grid item>
            <Sequence
              handleSelectedChanged={this.handleSelectedChanged}
              holder={this.holder}
              sequence={details.sequence}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={this.state.remove_details}
                onChange={this.handleChangeRemove}
              />
            }
            label="Remove details of series"
            style={{ margin: 0, padding: 0 }}
          />
        </Grid>
        <Divider />
        <CardActions disableSpacing>
          {/* <ButtonCircularIconLoad
            className={classes.button_grey}
            handleButtonClick={handleReload}
            icon={<CachedIcon />}
            small
            tooltip="Reload Details"
          /> */}
          <DivGrow />
          <ButtonCircularIconLoad
            disabled={!this.IsChanged()}
            handleButtonClick={this.handleSave}
            // left={classes.left}
            loading={this.state.saving}
            small
          />
        </CardActions>
      </React.Fragment>
    );
  }
}

EditProjectStudies.propTypes = {
  classes: PropTypes.object,
};

export default EditProjectStudies;
