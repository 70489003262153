import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { IconButton, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { MainTableToolbar } from 'components/Tables/components';
import { IsInvalid, rowsPerPageBig } from 'helpers';
import { columns_series_user } from './styles';
import { useStyles } from 'views/Administrator/components/AdminDefault/useStyles';
import { TableGrid } from 'components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
class TableUserSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }
  handleClickOpen = locations => {
    if (IsInvalid(locations) || !locations.length) return;
    const { Folder } = locations[0];
    if (IsInvalid(Folder)) return;
    const { idFolder } = Folder;
    this.props.history.push(`/explorer/folder/${idFolder}`);
  };
  render() {
    const { classes, rows, loading, deleting, height, total } = this.props;
    const { selected, rows_edited, row_clicked } = this.props.state;
    // let disabled = false;
    // if (loading || total < this.props.limit) {
    //   disabled = true;
    // }
    let ComponentAdditional = null;
    if (row_clicked) {
      const { locations } = row_clicked;
      ComponentAdditional = (
        <React.Fragment>
          <IconButton
            aria-label="edit-series"
            disabled={loading}
            onClick={() => this.handleClickOpen(locations)}
            size="small">
            <OpenInNewIcon />
          </IconButton>

          <IconButton
            aria-label="show-explorer"
            disabled={loading}
            onClick={() => this.handleClickOpen(locations)}
            size="small">
            <OpenInNewIcon />
          </IconButton>
        </React.Fragment>
      );
    }
    return (
      <Paper className={classes.root}>
        <MainTableToolbar
          ComponentAdditional={ComponentAdditional}
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.props.handleDeleteSelected}
          handleReloadTable={this.props.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={this.props.title}
        />
        <TableGrid
          checkboxSelection
          dense
          disableSelectionOnClick
          external_edited={rows_edited}
          external_selected={selected}
          grid
          headers={columns_series_user}
          height={height - 10}
          idRow="idSeries"
          loading={loading}
          onPageChange={this.props.handleChangePage}
          onRowClicked={row_clicked => this.props.setState({ row_clicked })}
          onRowEditedChanged={rows_edited => {
            this.props.setState({ rows_edited });
          }}
          onRowsPerPageChange={this.props.handleChangeRowsPerPage}
          onSelectedChanged={selected => this.props.setState({ selected })}
          page={this.props.page}
          paginationMode="server"
          rowCount={total}
          rows={rows}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </Paper>
    );
  }
}

TableUserSeries.propTypes = {
  classes: PropTypes.object,
  deleting: PropTypes.bool,
  disable_splice: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleDeleteSelected: PropTypes.func,
  handleReloadTable: PropTypes.func,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
  rows: PropTypes.array,
};
TableUserSeries.defaultProps = {
  disable_splice: false,
  rows: [],
  loading: false,
  deleting: false,
  page: 0,
  limit: 100,
  title: 'User Series',
  handleChangePage: () => '',
  handleChangeRowsPerPage: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
};
export default withStyles(withRouter(TableUserSeries), useStyles);
