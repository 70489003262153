import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import {
  Button,
  ButtonGroup,
  Grid,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  SearchInput,
  ButtonReloadDelete,
  DialogDeleteWarning,
  IconButtonLoad,
} from 'components';
import AddIcon from '@mui/icons-material/Add';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import Badge from '@mui/material/Badge';
import SaveIcon from '@mui/icons-material/Save';
import { styleToolbar } from 'common';
import { MutationDeleteUsers } from 'graphql/Users';
import { getParams, IsInvalid, ServerErrorsString } from 'helpers';
import { GetData } from 'helpers';
const BadgeUsers = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    // left: 0,
    right: 5,
    top: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: 'lightGreen',
  },
}));

class ToolBarUserActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
      open_delete: false,
      alignment: 'all',
    };
    const { holder } = props;
    if (holder) {
      holder.setDeleting = deleting => this.setState({ deleting });
    }
  }
  componentDidMount() {
    let { level_2 } = getParams(this.props);
    if (IsInvalid(level_2)) level_2 = 'all';
    this.setState({ alignment: level_2 });
  }
  handleAlignment = (event, alignment) => {
    this.setState({ alignment });
  };
  handleDeleteSelected = () => {
    const { selected, users } = this.props;
    const { deleting } = this.state;
    if (deleting || !selected.length) return;
    this.setState({ deleting: true, open_delete: false });
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteUsers(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteUsers;
          if (ok) {
            this.setState({ deleting: false });
            this.props.handleState({
              selected: [],
              users: users.filter(x => ids.indexOf(x.id) === -1),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const {
      classes,
      disabled,
      view,
      requests,
      onlines,
      numSaving,
    } = this.props;
    let { numSelected } = this.props;
    let ComponentSearchUsers = null;
    if (view === 'all') {
      ComponentSearchUsers = (
        <Grid item md={5} xs={12}>
          <SearchInput
            onChange={this.props.handleChangeName}
            placeholder="Search user"
            value={this.props.name}
          />
        </Grid>
      );
    }
    let title_add = 'Add new user';
    if (view === 'temp_users') {
      numSelected = 0;
      title_add = 'Add temp user';
    }
    return (
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ minHeight: 45 }}>
          <Grid
            container
            direction="row"
            item
            justifyContent="space-between"
            sx={{ minWidth: 330 }}
            xs={8}>
            <Grid item md={7} xs={12}>
              <ToggleButtonGroup
                aria-label="text alignment"
                disabled={disabled}
                exclusive
                onChange={this.props.handleViews}
                size="small"
                value={this.props.view}>
                <ToggleButton
                  aria-label="left aligned"
                  sx={{ paddingTop: numSelected ? 0 : '7px' }}
                  value="all">
                  <BadgeUsers badgeContent={numSelected}>
                    <PeopleOutlineIcon
                      style={{ color: view === 'all' ? 'green' : null }}
                    />
                  </BadgeUsers>
                </ToggleButton>
                <ToggleButton
                  aria-label="left aligned"
                  sx={{ paddingTop: onlines ? 0 : '7px' }}
                  value="online">
                  <BadgeUsers badgeContent={onlines}>
                    <PeopleAltIcon
                      style={{ color: view === 'online' ? 'green' : null }}
                    />
                  </BadgeUsers>
                </ToggleButton>
                <ToggleButton
                  aria-label="centered"
                  sx={{ paddingTop: requests ? 0 : '7px' }}
                  value="requests">
                  <BadgeUsers badgeContent={requests}>
                    <GroupAddIcon
                      style={{
                        color: view === 'requests' ? 'green' : null,
                      }}
                    />
                  </BadgeUsers>
                </ToggleButton>
                <ToggleButton
                  aria-label="temp-users"
                  sx={{ paddingTop: requests ? 0 : '7px' }}
                  value="temp_users">
                  <ContactMailIcon
                    style={{
                      color: view === 'temp_users' ? 'green' : null,
                    }}
                  />
                </ToggleButton>
              </ToggleButtonGroup>
              <ButtonGroup
                // className={classes.root}
                exclusive="false"
                size="small"
                value="center">
                <Button
                  className={classes.button}
                  color="primary"
                  onClick={this.props.handleOpenCreateUser}
                  size="small"
                  startIcon={<AddIcon />}
                  style={{
                    marginLeft: 5,
                    backgroundColor: 'blue',
                    color: 'white',
                  }}
                  variant="contained">
                  {title_add}
                </Button>
              </ButtonGroup>
            </Grid>
            {ComponentSearchUsers}
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            sx={{ minWidth: numSaving ? 160 : 100 }}
            xs={2}>
            {numSaving ? (
              <IconButtonLoad
                bottom_top
                handleClick={this.props.handleSave}
                icon={
                  <SaveIcon
                    sx={{ color: this.props.saving ? 'gray' : 'blue' }}
                  />
                }
                left_right
                length={numSaving}
                loading={this.props.saving}
                tooltip="Save"
              />
            ) : null}
            <ButtonReloadDelete
              deleting={this.state.deleting}
              handleClearSelected={this.props.handleClearSelected}
              handleDeleteSelected={() => {
                if (view === 'temp_users') this.props.holder.deleteTableTemp();
                else this.setState({ open_delete: true });
              }}
              handleReloadTable={this.props.handleReloadTable}
              loading={this.props.loading}
              loading_table={this.props.loading_table}
              numSelected={numSelected}
              tooltip="Reload USer"
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="The delete user was triggered"
          title_text="Delete Users"
        />
      </Paper>
    );
  }
}

ToolBarUserActions.propTypes = {
  classes: PropTypes.object,
};
ToolBarUserActions.defautlProps = {
  name: '',
  handleChangeName: () => '',
  handleOpenCreateUser: () => '',
  handleClearSelected: () => '',
  handleDeleteSelected: () => '',
  handleReloadTable: () => '',
  loading: false,
  loading_table: false,
  numSelected: 0,
};
export default withStyles(ToolBarUserActions, styleToolbar);
