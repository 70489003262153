import { CellActions } from '../TableCellNormal';
import {
  CellEditDate,
  CellEditProgress,
  CellEditStatus,
  CellEditText,
  CellEditUserEmail,
} from './index';
// import { CellActions } from '../components';

export const getComponentValue = (
  header,
  row,
  onClickClose,
  onClickSave,
  THIS
) => {
  const { type, field } = header;
  let value = null;
  let ComponentCellEdit = null;
  let sx = {};
  if (header.valueSetter) {
    // const params = getTableParams(row);
    // value = { [header.field]: header.valueGetter(params) };
  } else if (field) {
    value = { [field]: row[field] };
  }
  if (header.getEditComponent) {
    ComponentCellEdit = header.getEditComponent();
    value = {
      ...value,
      onSelect: docker => {
        THIS.setState({
          row: {
            ...row,
            docker,
          },
        });
      },
    };
  } else if (type === 'user_email') {
    ComponentCellEdit = CellEditUserEmail;
    value = {
      ...value,
      onChangeText: event => {
        THIS.setState({
          row: {
            ...row,
            user: {
              ...row.user,
              name: event.target.value,
            },
          },
        });
      },
    };
  } else if (type === 'progress') {
    ComponentCellEdit = CellEditProgress;
    value.onChangeSlider = progress =>
      THIS.setState({ row: { ...row, progress } });
  } else if (type === 'status') {
    ComponentCellEdit = CellEditStatus;
    value = {
      ...value,
      onClickSelect: status => THIS.setState({ row: { ...row, status } }),
    };
  } else if (type === 'action') {
    ComponentCellEdit = CellActions;
    value = {
      close: true,
      save: true,
      edit: false,
      onClickClose: () => onClickClose(THIS.props.row),
      onClickSave,
      row,
    };
  } else if (type === 'date') {
    ComponentCellEdit = CellEditDate;
    value = {
      date: row[field],
      onChangeDate: created => {
        THIS.setState({
          row: {
            ...row,
            created: created.format('MMM D, YYYY h:mm A'),
          },
        });
      },
    };
  } else {
    value = {
      text: row[field],
      onChangeText: text => {
        THIS.setState({
          row: {
            ...row,
            [`${field}`]: text,
          },
        });
      },
    };
    ComponentCellEdit = CellEditText;
  }
  return {
    value,
    ComponentCellEdit,
    sx,
  };
};
