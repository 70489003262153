import { IsInvalid } from 'helpers';

export const columns_unsorted = [
  {
    field: 'idSeries',
    headerName: 'ID',
    width: 75,
    align: 'left',

    valueGetter: params => {
      if (IsInvalid(params)) return '-1';
      if (params.getValue === undefined) return '-1';
      const idSeries = params.getValue('idSeries');
      if (idSeries) return idSeries;
      const series = params.getValue('series');
      if (series) return series.idSeries;
      return '-1';
    },
  },
  {
    field: 'confidence',
    headerName: 'Confidence',
    width: 200,
    align: 'left',
  },
  {
    field: 'prediction_class',
    headerName: 'Prediction',
    align: 'left',
    // width: 200,
    //  valueGetter: params => {
    //    if (IsInvalid(params)) return '';
    //    if (params.getValue === undefined) return '';
    //    let value = params.getValue( 'folders');
    //    if (value === undefined || !value) return '';
    //    return value.length;
    //  },
  },
  {
    field: 'prediction_probability',
    headerName: 'Probability',
    align: 'left',
    // width: 200,
    //  valueGetter: params => {
    //    if (IsInvalid(params)) return '';
    //    if (params.getValue === undefined) return '';
    //    let value = params.getValue( 'folder_contents');
    //    if (value === undefined || !value) return '';
    //    const Rights = value.map(x => x.Rights).filter(x => x);
    //    if (Rights === undefined || !Rights) return '';
    //    let count = 0;
    //    Rights.forEach(x => {
    //      count += x.length;
    //    });
    //    return count;
    //  },
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    align: 'left',
    // width: 110,
  },
  {
    field: 'ProtocolName',
    headerName: 'ProtocolName',
    align: 'left',
  },
  {
    field: 'SequenceName',
    headerName: 'SequenceName',
    align: 'left',
    width: 150,
  },
  {
    field: 'Main Class',
    headerName: 'prediction_class_category',
    align: 'left',
    // width: 200,
    //  valueGetter: params => {
    //    if (IsInvalid(params)) return '';
    //    if (params.getValue === undefined) return '';
    //    let value = params.getValue( 'folder_contents');
    //    if (value === undefined || !value) return '';
    //    return value.length;
    //  },
  },
  {
    field: 'prediction_probability_category',
    headerName: 'Main Acuracy',
    align: 'left',
  },
  {
    field: 'confidence_threshold',
    headerName: 'Threshold',
    width: 200,
    align: 'left',
  },
];
