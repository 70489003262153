import { FormatTime, IsInvalidParam } from 'helpers';
import { FormatSeriesDate, IsInvalid } from 'helpers';

export const columns_unmapseries = [
  { field: 'idSeries', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'ImageMr',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let ImageMr = params.getValue('ImageMr');
      if (ImageMr === undefined || !ImageMr) return '';
      const { MRAcquisitionType } = ImageMr;
      if (MRAcquisitionType === undefined || !MRAcquisitionType) return '';
      return MRAcquisitionType;
    },
  },
  {
    field: 'Modality',
    headerName: 'Modality',
    align: 'left',
    width: 70,
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    align: 'left',
    width: 110,
  },
  {
    field: 'ImageMr',
    headerName: 'ProtocolName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let ImageMr = params.getValue('ImageMr');
      if (ImageMr === undefined || !ImageMr) return '';
      const { ProtocolName } = ImageMr;
      if (ProtocolName === undefined || !ProtocolName) return '';
      return ProtocolName;
    },
  },
  {
    field: 'ImageMr',
    headerName: 'SequenceName',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let ImageMr = params.getValue('ImageMr');
      if (ImageMr === undefined || !ImageMr) return '';
      const { SequenceName } = ImageMr;
      if (SequenceName === undefined || !SequenceName) return '';
      return SequenceName;
    },
  },
  {
    field: 'ContrastBolusAgent',
    headerName: 'ContrastBolusAgent',
    align: 'left',
    width: 110,
  },
  {
    field: 'center',
    headerName: 'Center',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let center = params.getValue('center');
      if (center === undefined || !center) return '';
      return center.name;
    },
  },

  {
    field: 'NumberOfSeriesRelatedInstances',
    headerName: '#Files',
    align: 'left',
  },
  {
    field: 'SeriesDate',
    headerName: 'Dates',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('SeriesDate');
      if (value === undefined || !value) return '';
      return FormatSeriesDate(value, 'yyyy/MM/DD');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
