import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
export const useStyles = () => ({
  root: {},
});
export const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 75,
    style: {
      width: 75,
      maxWidth: 90,
    },
    align: 'left',
  },
  {
    field: 'file_object',
    headerName: 'File Object',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('file_object');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.original_name;
      }
    },
  },
  {
    field: 'study',
    headerName: 'Study',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('study');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.StudyDescription;
      }
    },
  },
  {
    field: 'series',
    headerName: 'Series',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('series');
      if (value === undefined || !value) {
        return '';
      } else {
        return value.SeriesDescription;
      }
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    align: 'left',
    edit: true,
    style: {
      width: 190,
      minWidth: 190,
      maxWidth: 190,
    },
    width: 200,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do, YYYY (h:mm a)');
    },
  },
];
