import { getUserFullName, IsInvalid } from 'helpers';
import { columns_patient_signals } from 'views/DataSignals/components/TabPatientSignals/utils';
export const columns_signals = [
  { ...columns_patient_signals[0] },
  {
    field: 'owner',
    headerName: 'Owner',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('owner');
      if (value === undefined || !value) return '';
      return getUserFullName(value);
    },
  },
  ...columns_patient_signals.slice(1, columns_patient_signals.length),
];
