import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { TableSiteUsersToolbar } from './components';
import { headerUserTemp, useStyles } from './components/utils';
import { IsInvalid, rowsPerPageBig } from 'helpers';
import { TableGrid } from 'components';
import TableTempAccess from './TableTempAccess';

const DivWrapper = ({ paper, classes, children }) => {
  if (paper) {
    return <Paper className={classes.root}>{children}</Paper>;
  } else {
    return <div>{children}</div>;
  }
};

class TableSiteUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 25,
    };
    const { holder } = props;
    if (holder) {
      holder.clearTable = () => {
        this.setSelected([]);
      };
      holder.getSelectedTable = () => {
        let selected = [];
        const { users } = this.props;
        for (let i = 0; i < users.length; i++) {
          if (this.isSelected(users[i])) {
            selected.push(users[i]);
          }
        }
        return selected;
      };
    }
    this.holder = {
      handleReloadTable: null,
    };
  }

  setOrder = newOrder => {
    this.setState({ order: newOrder });
  };
  setOrderBy = newOrderBy => {
    this.setState({ orderBy: newOrderBy });
  };
  setSelected = newSelected => {
    this.setState({ selected: newSelected });
    this.props.handleSelected(newSelected);
  };
  setPage = newPage => {
    this.setState({ page: newPage });
  };
  setDense = newDense => {
    this.setState({ dense: newDense });
  };
  setRowsPerPage = newRowsPerPage => {
    this.setState({ rowsPerPage: newRowsPerPage });
  };

  handleRequestSort = (newOrderBy, newOrder) => {
    this.setOrder(newOrder);
    this.setOrderBy(newOrderBy);
    // console.log({ newOrderBy, newOrder });
    if (newOrderBy === 'account') return;
    if (newOrderBy === 'address') return;
    if (newOrderBy === 'FullName') newOrderBy = 'firstName';
    this.props.onOrderByChanged(newOrderBy, newOrder);
  };

  handleChangeDense = event => {
    this.setDense(event.target.checked);
  };
  isSelected = user => {
    const { selected } = this.state;
    const index = selected
      .map(x => `${x.id}_${x.firstName}`)
      .indexOf(`${user.id}_${user.firstName}`);

    return index !== -1 ? true : false;
  };
  handleClearSelected = () => {
    this.setSelected([]);
  };
  handleReloadTable = () => {
    this.props.handleReloadTable();
  };
  getColorCellText = row => {
    if (IsInvalid(row)) return null;
    const { isActive } = row;
    if (isActive) return null;
    return {
      fontWeight: 800,
      color: 'red',
    };
  };
  render() {
    const {
      classes,
      height,
      users,
      loading,
      deleting,
      hide_toolbar,
    } = this.props;
    let { dense, selected } = this.state;
    let emptyRows = 5 - users.length;
    if (emptyRows < 1) {
      emptyRows = 0;
    }
    if (this.props.dense) {
      dense = true;
    }

    if (this.props.view === 'temp_users') {
      return (
        <TableTempAccess
          handleAddError={this.props.handleAddError}
          handleSelected={this.props.handleSelected}
          height={height}
          holder={this.props.holder}
          setState={this.props.setState}
        />
      );
    }
    let ComponentToolBar = null;
    if (!hide_toolbar) {
      ComponentToolBar = (
        <TableSiteUsersToolbar
          handleClearSelected={this.handleClearSelected}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReloadTable={this.handleReloadTable}
          loading={deleting}
          loading_table={loading}
          numSelected={selected.length}
          title={this.props.title}
        />
      );
    }
    return (
      <DivWrapper classes={classes} paper={this.props.paper}>
        {ComponentToolBar}
        <Divider />
        <TableGrid
          checkboxSelection
          dense={dense}
          disableSelectionOnClick
          external_selected={selected}
          getColorCellText={this.getColorCellText}
          headers={headerUserTemp}
          height={height}
          loading={loading}
          onButtonClick={this.props.handleOpenEditUser}
          onPageChange={page => this.props.onPageChange(page)}
          onRowsPerPageChange={limit => this.props.onRowsPerPageChange(limit)}
          onSelectedChanged={selected => this.setSelected(selected)}
          onSortHeaderClicked={this.handleRequestSort}
          page={this.props.page}
          paginationMode="server"
          rowCount={this.props.total}
          rows={users}
          rowsPerPage={this.props.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </DivWrapper>
    );
  }
}

TableSiteUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  disable_splice: PropTypes.bool,
  paper: PropTypes.bool,
};
TableSiteUsers.defaultProps = {
  users: [],
  paper: true,
  disable_splice: false,
  onOrderByChanged: () => '',
  title: 'Site Users',
  dense: false,
  hide_toolbar: false,
};
export default withStyles(TableSiteUsers, useStyles);
