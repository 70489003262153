// import { IsInvalid } from 'helpers';

import { IsInvalid } from 'helpers';

export const handleResultData = (
  type,
  data,
  object,
  page,
  limit,
  new_tab,
  THIS
) => {
  let { tabValue } = THIS.state;
  if (new_tab !== null) tabValue = new_tab;
  console.log('handleResultData', {
    new_tab,
    type,
    tabValue,
    object,
  });
  if (tabValue === 0) {
    let data_series = null;
    if (type === 'users') data_series = data.Series;
    else data_series = data.projectSeries;
    if (IsInvalid(data_series)) throw 'Unknown error recovering series';
    const { ok, Series, Total, errors } = data_series;
    if (ok) {
      THIS.setState({
        loading: false,
        series: Series,
        total_series: Total,
        init: true,
        user: type === 'users' ? object : null,
        project: type === 'users' ? null : object,
        page,
        limit,
        tabValue,
      });
    } else {
      throw errors;
    }
  } else if (tabValue === 1) {
    let data_files = null;
    if (type === 'users') data_files = data.filesAtUser;
    else data_files = data.projectFiles;
    if (IsInvalid(data_files)) throw 'Unknown error recovering files';
    const { ok, files, total, errors } = data_files;
    if (ok) {
      THIS.setState({
        loading: false,
        files,
        total_files: total,
        init: true,
        user: type === 'users' ? object : null,
        project: type === 'users' ? null : object,
        page,
        limit,
        tabValue,
      });
    } else {
      throw errors;
    }
  } else {
    throw 'Unknown error recovering data';
  }
};
export const GetIDtoSearch = (user, project, page, limit) => {
  console.log('GetIDtoSearch', { project, user, page, limit });
  let id_to_search = -1;
  let object = null;
  if (user) {
    id_to_search = user.id;
    object = user;
  } else if (project) {
    id_to_search = project.id;
    object = project;
  }
  return { id_to_search, object };
};
