import React from 'react';
import PropTypes from 'prop-types';
import {
  ToolBarProjectObjectMapping,
  initColumns,
  addColumnsType,
  addColumnsTypeDescription,
  addColumnsTypeDate,
} from './components';
import { GetData, ServerErrorsString, rowsPerPageBig } from 'helpers';
import { ProjectVariableTypes, TableGrid } from 'components';
import { QueryAllProjectObjectMappingFlat } from 'graphql/Projects/utils_project_objects';
class TabProjectSettingMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [...initColumns],
      open_mapping: false,
      open_editor: false,
      idProject: -1,
      selected: [],
      project_object_maps: [],
      page: 0,
      limit: 100,
      total: 0,
      empty: false,
      type: 0,
      filter: null,
      headers_to_hide: [],
      types: [],
      length: 0,
    };
  }
  runQueryProjectObjectMapping = (new_page = null, new_limit = null) => {
    const { idProject } = this.props;
    let { page, limit, loading, types } = this.state;
    if (types.length < 1) {
      this.props.handleAddError('You need to select types first');
      return;
    }
    console.log('runQueryProjectObjectMapping', { idProject });
    if (loading || idProject < 1) return;
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    const ids = types.map(x => x.idType);
    this.setState({ loading: true });
    (async () => {
      QueryAllProjectObjectMappingFlat(idProject, ids, page, limit)
        .then(res => {
          const data = GetData(res);
          const {
            ok,
            errors,
            project_object_maps,
            total,
          } = data.allProjectObjectMappingsFlat;
          if (ok) {
            this.setState({
              loading: false,
              project_object_maps,
              total,
              columns: [...initColumns],
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            page,
            idProject,
            limit,
            total: 0,
            empty: true,
            columns: [...initColumns],
          });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  addColumns = () => {
    const { project_object_maps, columns } = this.state;
    if (!project_object_maps.length) {
      return;
    }
    let changed = false;
    // let names = [];
    for (let i = 0; i < project_object_maps.length; i++) {
      for (let j = 0; j < project_object_maps[i].secondary_maps.length; j++) {
        const map = project_object_maps[i].secondary_maps[j];
        // const main_typename = project_object_maps[i].type_name;
        let field = `${j};;=field=;;${map.type_name}`;
        let index = columns.map(x => x.field).indexOf(field);
        if (index === -1) {
          changed = true;
          columns.push(addColumnsType(j, map));
        }
        field = `${j};;=field_INFO=;;${map.type_name}`;
        index = columns.map(x => x.field).indexOf(field);
        if (index === -1) {
          changed = true;
          columns.push(addColumnsTypeDescription(j, map));
        }
        field = `${j};;=field_DATE=;;${map.type_name}`;
        index = columns.map(x => x.field).indexOf(field);
        if (index === -1) {
          changed = true;
          columns.push(addColumnsTypeDate(j, map));
        }
      }
    }
    if (changed) {
      this.setState({ columns });
    }
  };
  handleSelectionChanged = types => {
    this.setState({ types, length: types.length, columns: [...initColumns] });
  };
  render() {
    if (!this.props.isActive) return null;
    const { selected, project_object_maps, columns } = this.state;
    let { height, isAdmin, access_code } = this.props;
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    if (project_object_maps.length) {
      columns[1].headerName = project_object_maps[0].type_name;
      this.addColumns();
    }
    return (
      <div style={style}>
        <ToolBarProjectObjectMapping
          access_code={access_code}
          handleClearSelected={() => this.setState({ selected: [] })}
          handleOpenEdit={() => this.setState({ open_editor: true })}
          handleOpenVarMappping={() => this.setState({ open_mapping: true })}
          handleReloadTable={() => this.runQueryProjectObjectMapping()}
          handleSelectionType={this.handleSelectionType}
          idProject={this.props.idProject}
          isAdmin={isAdmin}
          loading={this.state.loading}
          rowCount={this.state.project_object_maps.length}
          selected={selected}
          type={this.state.type}
          types={this.state.types}
        />
        <ProjectVariableTypes
          idProject={this.props.idProject}
          onSelectionChanged={this.handleSelectionChanged}
        />
        <TableGrid
          checkboxSelection
          disableSelectionOnClick
          external_selected={this.state.selected}
          filter={this.state.filter}
          headers={this.state.columns}
          headers_to_hide={this.state.headers_to_hide}
          height={height}
          loading={this.state.loading}
          onFilterChanded={filter => this.setState({ filter })}
          // onHeadersToHideChanged={this.handleHeadersToHide}
          onPageChange={newPage =>
            this.runQueryProjectObjectMapping(null, newPage, this.state.limit)
          }
          onRowsPerPageChange={limit =>
            this.runQueryProjectObjectMapping(null, 0, limit)
          }
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.project_object_maps}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TabProjectSettingMapping.propTypes = {
  classes: PropTypes.object,
};
TabProjectSettingMapping.defaultProps = {
  handleAddError: () => '',
  isAdmin: false,
  access_code: 0,
};
export default TabProjectSettingMapping;
