import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Tab, Tabs } from '@mui/material';
import {
  TabContainers,
  TabImages,
  TabSystemDockers,
  TabMapping,
  TabConfiguration,
  TabAccountDockers,
} from './components';
import { withStyles } from 'tss-react/mui';
import { TabPanel } from 'components';
import { getParams } from 'helpers';

const useStyles = theme => ({
  toolbar: {
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});
const transformTextToLink = input => {
  return input
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '_');
};
class TabDockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      docker_images: [],
      selected_containers: [],
      selected_images: [],
      tabValue: 0,
    };
    this.tabs = [
      { TabComponent: TabContainers, label: 'Containers' },
      { TabComponent: TabImages, label: 'Images' },
      { TabComponent: TabSystemDockers, label: 'System Dockers' },
      { TabComponent: TabMapping, label: 'Mapping' },
      { TabComponent: TabConfiguration, label: 'Configuration Tables' },
      { TabComponent: TabAccountDockers, label: 'Account Dockers' },
    ];
    this.holder = {
      addNewContainer: null,
      removedContainer: null,
    };
  }
  componentDidMount() {
    const { level_1, level_2 } = getParams(this.props);
    console.log({ level_1, level_2 });
    const index = this.tabs
      .map(x => transformTextToLink(x.label))
      .indexOf(level_2);
    let tabValue = 0;
    let link = 'containers';
    if (index !== -1) {
      tabValue = index;
      link = transformTextToLink(this.tabs[index].label);
    }
    this.setState({ tabValue });
    this.props.history.push(`/administrator/dockers/${link}`);
  }
  handleClickContainer = () => {};
  handleSetSelectedContainers = (selected_containers, deleted = false) => {
    if (deleted && this.holder.removedContainer) {
      this.holder.removedContainer(this.state.selected_containers);
    }
    this.setState({ selected_containers });
  };
  handleSetSelectedImages = selected_images => {
    this.setState({ selected_images });
  };

  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    this.setState({ tabValue });
    if (tabValue >= 0 && tabValue < this.tabs.length) {
      const link = transformTextToLink(this.tabs[tabValue].label);
      this.props.history.push(`/administrator/dockers/${link}`);
    }
  };
  handleAddNewContainer = container => {
    if (this.holder.addNewContainer) {
      this.holder.addNewContainer(container);
    }
  };
  handleDockerImages = docker_images => {
    this.setState({ docker_images });
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, user, height } = this.props;
    let { tabValue } = this.state;
    if (tabValue === undefined) tabValue = 0;
    else tabValue = parseInt(tabValue, 10);
    return (
      <Grid container>
        <Grid item style={{ width: '100%' }} xs={12}>
          {/* <Paper square> */}
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.tabs.map((tab, index) => {
              return <Tab key={`tab-header-${index}`} label={tab.label} />;
            })}
          </Tabs>
          <Divider />
          <div style={{ width: '100%', height: height - 250 }}>
            <div className={classes.tab_content}>
              {this.tabs.map((tab, index) => {
                const { TabComponent } = tab;
                return (
                  <TabPanel
                    className={classes.tab_panel}
                    index={index}
                    key={`tab-dockers-${index}`}
                    value={tabValue}>
                    <TabComponent
                      docker_images={this.state.docker_images}
                      handleAddError={this.props.handleAddError}
                      handleAddNewContainer={this.handleAddNewContainer}
                      handleAddSuccess={this.props.handleAddSuccess}
                      handleDockerImages={this.handleDockerImages}
                      handleSetSelectedContainers={
                        this.handleSetSelectedContainers
                      }
                      handleSetSelectedImages={this.handleSetSelectedImages}
                      handleTabValue={this.handleTabValue}
                      height={height}
                      holder={this.holder}
                      isActive={this.props.isActive}
                      user={user}
                    />
                  </TabPanel>
                );
              })}
            </div>
          </div>
          {/* </Paper> */}
        </Grid>
      </Grid>
    );
  }
}

TabDockers.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabDockers, useStyles);
