import React from 'react';
import PropTypes from 'prop-types';
import { IconButtonLoad, TableGrid } from 'components';
import { QueryUserProjects } from 'graphql/Projects';
import {
  ServerErrorsString,
  GetData,
  IsInvalid,
  FormatTime,
  getUserFullName,
  rowsPerPageBig,
  IsInvalidParam,
} from 'helpers';
import { Grid } from '@mui/material';
import { Replay as ReloadIcon } from '@mui/icons-material';
const columns_project = [
  {
    field: 'id',
    headerName: 'ID',
    align: 'left',
  },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
  },
  {
    field: 'admin',
    headerName: 'Admin',
    align: 'left',
    width: 222,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const admin = params.getValue('admin');
      if (IsInvalid(admin)) return '';
      return getUserFullName(admin);
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
class UserViewProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      projects: [],
      idUser: -1,
    };
  }
  componentDidMount() {
    this.loadProjects();
  }
  componentDidUpdate() {
    this.loadProjects();
  }
  loadProjects = (force, newPage, newLimit) => {
    const { edit_user } = this.props;
    if (IsInvalid(edit_user)) return;
    const { loading, idUser } = this.state;
    let { page, limit } = this.state;
    if (loading || (idUser === edit_user.id && IsInvalid(force))) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryUserProjects(edit_user.id, null, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, projects, errors, total } = data.userProjects;
          if (ok) {
            this.setState({
              page,
              limit,
              loading: false,
              projects,
              idUser: edit_user.id,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { projects, loading } = this.state;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          p={1}
          spacing={1}>
          <Grid item>User Projects</Grid>
          <Grid item>
            <IconButtonLoad
              // disabled={!changed}
              handleClick={() => this.loadProjects(true)}
              icon={<ReloadIcon />}
              loading={loading}
              small
              tooltip="Reload Table"
            />
          </Grid>
        </Grid>
        <TableGrid
          checkboxSelection
          dense
          headers={columns_project}
          loading={loading}
          onPageChange={page => this.loadProjects(true, page, this.state.limit)}
          onRowsPerPageChange={limit => this.loadProjects(true, 0, limit)}
          page={this.state.page}
          paginationMode="server"
          rowCount={this.state.total}
          rows={projects}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </React.Fragment>
    );
  }
}

UserViewProjects.propTypes = {
  classes: PropTypes.object,
};

export default UserViewProjects;
