import React from 'react';
import { Slider } from '@mui/material';
import { StackProgress, TypoProgress } from '../../../styles';
const CellEditProgress = ({ progress, onChangeSlider = () => '' }) => {
  return (
    <StackProgress>
      <Slider
        aria-label="time-indicator"
        max={100}
        min={0}
        onChange={(_, progress) => onChangeSlider(progress)}
        size="small"
        step={1}
        sx={{ width: 180 }}
        value={progress}
      />
      <TypoProgress component="p" variant="body2">
        {`${progress}%`}
      </TypoProgress>
    </StackProgress>
  );
};
export default CellEditProgress;
