/* eslint-disable indent */
import React from 'react';
import { grey, lightBlue } from '@mui/material/colors';
import { lighten } from '@mui/material/styles';
import { AutoComboBox, BadgePulsar } from 'components';
import {
  FormatBytes,
  FormatTime,
  GetData,
  getFileSeries,
  getInitials,
  IsInvalid,
  IsInvalidParam,
} from 'helpers';
import { Avatar, Grid } from '@mui/material';
import { QuerySystemDockerImages } from 'graphql/Dockers/utils_images';

export const useStyles = theme => ({
  root: {
    width: 'calc(100% - 16px)',
    height: 'auto',
    margin: theme.spacing(1),
    padding: 0,
  },
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
  },
  table_head: {
    backgroundColor: grey[300],
  },
  table_body: {
    width: '100%',
    // maxHeight: 440,
    overflow: 'auto',
  },
  tableWrapper: {
    width: '100%',
    overflow: 'auto',
    // // position: 'absolute',
    // top: 0,
    // left: 0,
    // maxHeight: 'calc(100% - 65px)',
    // /* uncomment this static height to see it work */
    // /* height: 100%; */
    // width: '100%',
    // overflow: 'hidden',
    // display: 'flex',
    // flexDirection: 'column',
    // // maxHeight: 'calc(100%-500px)',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table_pagination: { height: '38px' },
  cell_user: {
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: 'red',
  },
  online: {
    color: 'green',
    fontWeight: 1000,
  },
  cell_avatar: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  cell_avatar_login: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  table_row: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[200],
      '& > .MuiTableCell-root': {
        color: 'blue',
      },
    },
  },
  date_width: {
    minWidth: 200,
  },
});

export const getRow = value => {
  let row = {
    id: -1,
    Thumbnail: null,
    kind: '',
    name: '',
    size: '0 Bytes',
    data_modified: '',
    permission: [],
  };
  const { id, Folder, FolderContent, Object: MultiObject, updatedAt } = value;
  row.id = id;
  row.data_modified = updatedAt;
  if (typeof Folder !== 'undefined' && Folder) {
    row.name = Folder.name;
    row.kind = '--';
    row.permission = Folder.Rights;
    row.size = FormatBytes(Folder.Size);
  } else if (typeof FolderContent !== 'undefined' && FolderContent) {
    row = getFileSeries(FolderContent, row);
    row.permission = FolderContent.Rights;
  } else if (typeof MultiObject !== 'undefined' && MultiObject) {
    row = getFileSeries(MultiObject, row);
    row.permission = [MultiObject.Rights];
  }
  return row;
};
export const useToolbarStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(lightBlue[500], 0.85),
  },
  title: {
    flex: '1 1 100%',
    color: '#263238',
    fontSize: '16px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    textTransform: 'none',
  },
});
export const headerUserTemp = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'FullName',
    headerName: 'FullName',
    align: 'left',
    width: '100%',
    getComponent: (header, row) => {
      const username = row['username'];
      const FullName = row['FullName'];
      let ComponentActive = null;
      if (row.isOnline) {
        ComponentActive = (
          <BadgePulsar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            overlap="circular"
            style={{ marginRight: 16 }}
            variant="dot">
            <Avatar src={row.avatar}>{getInitials(username)}</Avatar>
          </BadgePulsar>
        );
      } else {
        ComponentActive = (
          <Avatar src={row.avatar}>{getInitials(username)}</Avatar>
        );
      }
      return (
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          mr={10}
          spacing={1}
          sx={{ width: '100%' }}>
          <Grid item sx={{ minWidth: 48 }} xs={3}>
            {ComponentActive}
          </Grid>
          <Grid item xs={6}>
            {FullName}
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'account',
    numeric: false,
    disablePadding: true,
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('account');
      if (IsInvalid(value)) return '';
      return value.description;
    },
  },
  {
    field: 'email',
    numeric: false,
    disablePadding: false,
    headerName: 'E-Mail',
    align: 'left',
  },
  {
    field: 'username',
    numeric: true,
    disablePadding: false,
    headerName: 'Username',
  },
  {
    field: 'address',
    numeric: true,
    disablePadding: false,
    headerName: 'Country',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('address');
      if (IsInvalid(value)) return '';
      return value.country;
    },
  },
  {
    field: 'last_login',
    headerName: 'Last Login',
    minWidth: 100,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return 'No login activity';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return 'No login activity';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Register',
    minWidth: 100,
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'show',
    numeric: false,
    disablePadding: false,
    headerName: 'Show',
    type: 'button',
    name: 'Edit',
  },
];
// const CompoentDocker = ({ row, onSelect }) => {
//   let docker = undefined;
//   if (row) docker = row['docker'];
//   if (IsInvalid(docker)) docker = { operation: 'None', id: 0 };
//   // eslint-disable-next-line no-unused-vars
//   const [dockers, setDockers] = React.useState([docker]);
//   // React.useEffect(() => {
//   //   (async () => {
//   //     QuerySystemDockerImages()
//   //       .then(res => {
//   //         const data = GetData(res);
//   //         const { ok, errors, images } = data.systemDockerImages;
//   //         if (ok) {
//   //           images.unshift({ operation: 'None', id: 0 });
//   //           setDockers(images);
//   //         } else {
//   //           throw errors;
//   //         }
//   //       })
//   //       .catch(error => {
//   //         console.log({ error });
//   //       });
//   //   })();
//   // }, []);

// };
class CompoentDocker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docker: props.docker ? props.docker : { operation: 'None', id: 0 },
      dockers: [{ operation: 'None', id: 0 }],
    };
  }
  componentDidMount() {
    (async () => {
      QuerySystemDockerImages()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images } = data.systemDockerImages;
          if (ok) {
            images.unshift({ operation: 'None', id: 0 });
            this.setState({ dockers: images });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
        });
    })();
  }
  render() {
    const { onSelect } = this.props;
    const { dockers, docker } = this.state;
    return (
      <AutoComboBox
        defaultValue={docker}
        disabled={this.state.loading}
        getOptionLabel={option => {
          if (option === undefined || option.operation === undefined) return '';
          return option.operation;
        }}
        label="Docker Image"
        onObjectChanged={docker_image => onSelect(docker_image)}
        options={dockers}
        small
      />
    );
  }
}
export const header_temp = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 10,
    maxWidth: 10,
    edit: false,
  },
  {
    field: 'docker',
    headerName: 'Docker',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const docker = params.getValue('docker');
      if (IsInvalid(docker)) return '';
      return docker.operation;
    },
    getEditComponent: () => {
      return CompoentDocker;
    },
  },

  {
    field: 'email',
    headerName: 'E-mail',
  },
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'runs',
    headerName: 'Runs',
    width: 100,
    minWidth: 100,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'right',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: '',
    headerName: 'Action',
    type: 'action',
    align: 'left',
  },
];
