import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import {
  cell,
  cell_action,
  cell_check,
  cell_check_icon,
  CheckBoxSyan,
} from './components/styles';

class TableBlockHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleCheckbox = () => {
    let { selected } = this.props.state;
    if (selected.length) {
      selected = [];
    } else {
      selected = [...this.props.state.data];
    }
    this.props.setState({ selected });
  };
  render() {
    const { isCheckEnabled } = this.props;
    const { data, selected, headers } = this.props.state;
    let CellCheck = null;
    if (isCheckEnabled) {
      CellCheck = (
        <TableCell sx={{ ...cell_check, backgroundColor: '#f9fafb' }}>
          <CheckBoxSyan
            checked={data.length && selected.length === data.length}
            indeterminate={selected.length > 0 && selected.length < data.length}
            onClick={() => this.handleCheckbox()}
            sx={cell_check_icon}
          />
        </TableCell>
      );
    }
    return (
      <TableHead>
        <TableRow>
          {CellCheck}
          {headers.map((header, index) => {
            const { headerName, minWidth, width, type, maxWidth } = header;
            let cell_sx = { ...cell };
            if (minWidth) cell_sx.minWidth = minWidth;
            if (width) cell_sx.width = width;
            if (maxWidth) cell_sx.maxWidth = maxWidth;
            if (type === 'action') cell_sx = cell_action;
            return (
              <TableCell key={`tb-header-${index}`} sx={cell_sx}>
                {headerName}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}

TableBlockHeader.propTypes = {
  classes: PropTypes.object,
};
TableBlockHeader.defaultProps = {
  isCheckEnabled: true,
  headers: [],
};
export default TableBlockHeader;
