import React from 'react';
import { TextField } from '@mui/material';
const CellEditText = ({ text, onChangeText = () => '' }) => {
  return (
    <TextField
      onChange={event => onChangeText(event.target.value)}
      sx={{
        minWidth: 150,
      }}
      value={text}
    />
  );
};
export default CellEditText;
