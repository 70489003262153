import { copyToClipboard } from 'helpers';
import { findMinMaxIndices, getIdValue } from '../table_utils';

export const getHeaders = (props, state) => {
  const { headers } = props;
  const { headers_to_hide } = state;
  return headers.filter(x => {
    const index = headers_to_hide.map(x => x.field).indexOf(x.field);
    return index === -1;
  });
};
const isDateTime = (type, field) => {
  return (
    type === 'datetime' ||
    type === 'time' ||
    type === 'date' ||
    field === 'createdAt' ||
    field === 'updatedAt'
  );
};
export const getCellValue = (header, row, sort = false) => {
  if (header === undefined || !header) return '';
  const { field, valueGetter, type } = header;
  if (valueGetter) {
    if (sort) {
      if (isDateTime(type, field)) {
        try {
          return parseInt(row[field]);
        } catch (error) {
          return row[field];
        }
      }
    }
    const params = {
      ...row,
      getValue: name => {
        if (row[name] === undefined) return '';
        return row[name];
      },
      getHeader: name => {
        if (header[name] === undefined) return '';
        return header[name];
      },
    };

    return valueGetter(params);
  } else {
    if (sort) {
      if (isDateTime(type, field)) {
        try {
          return parseInt(row[field]);
        } catch (error) {
          return row[field];
        }
      }
    }
  }
  return row[field];
};
export const getFinalRows = (rows, state, props) => {
  if (rows === undefined || !rows) {
    rows = [];
  }
  let { rows_edited, filter } = state;
  let final_rows = rows.map(row => {
    const index = rows_edited
      .map(x => getIdValue(x, props))
      .indexOf(getIdValue(row, props));
    if (index === -1) {
      return { ...row };
    } else {
      return { ...rows_edited[index] };
    }
  });
  if (filter) {
    let filter_rows = [];
    const { type, key, header } = filter;
    for (let i = 0; i < final_rows.length; i++) {
      const value = getCellValue(header, final_rows[i]);
      // console.log({ type, key, header, value });
      let added = false;
      if (value !== null && value !== undefined && key && key !== '') {
        let text = String(value);
        if (type === 'Equal') {
          if (text === key) filter_rows.push(final_rows[i]);
        } else if (type === 'Does Not Equal') {
          if (text !== key) filter_rows.push(final_rows[i]);
        } else if (type === 'Begins with') {
          if (text.startsWith(key)) filter_rows.push(final_rows[i]);
        } else if (type === 'Contains') {
          // console.log({ value });
          if (text.includes(key)) filter_rows.push(final_rows[i]);
        } else if (type === 'End with') {
          if (text.endsWith(key)) filter_rows.push(final_rows[i]);
        } else {
          filter_rows.push(final_rows[i]);
          added = true;
        }
      } else {
        filter_rows.push(final_rows[i]);
        added = true;
      }
      if (!added) {
        if (key === '' || !key) {
          filter_rows.push(final_rows[i]);
        }
      }
    }
    return filter_rows;
  }
  return final_rows;
};

export const CopyToClipboard = (rows, selected, headers) => {
  console.log('Ctrl+C detected', { rows, selected, headers });
  const reply = findMinMaxIndices(selected);
  if (!reply) return;
  const { minRow, maxRow, minCol, maxCol } = reply;
  // let matrix = [];
  let texts = [];
  for (let row = minRow; row <= maxRow; row++) {
    if (row >= rows.length) continue;
    const row_cells = rows[row];
    // const keys = Object.keys(row_cells);
    let cells = [];
    for (let col = minCol; col <= maxCol; col++) {
      if (col >= headers.length) continue;
      const cell = getCellValue(headers[col], row_cells);
      cells.push(cell);
    }
    // matrix.push(cells);
    texts.push(cells.join('\t'));
  }
  console.log({ t: texts.join('\n') });
  if (texts.join('\n').trim()) copyToClipboard(texts.join('\n'));
};
export const descGrid = (a, b, orderBy, headers) => {
  if (orderBy === '') return 0;
  let value_a = a[orderBy];
  let value_b = b[orderBy];
  if (orderBy) {
    const index = headers.map(x => x.field).indexOf(orderBy);
    if (index !== -1) {
      const header = headers[index];
      if (header) {
        value_a = getCellValue(header, a, true);
        value_b = getCellValue(header, b, true);
      }
    }
  }
  if (value_b < value_a) {
    return -1;
  }
  if (value_b > value_a) {
    return 1;
  }
  return 0;
};

export const getSortFunc = (order, orderBy, headers) => {
  return order === 'desc'
    ? (a, b) => descGrid(a, b, orderBy, headers)
    : (a, b) => -descGrid(a, b, orderBy, headers);
};

export const getSortRows = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};
