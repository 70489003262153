const useStyles = theme => ({
  root: {
    paddingRight: theme.spacing(2),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    height: '42px',
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  table: {
    width: '100%',
    margin: theme.spacing(2),
  },
  toolbar: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
});

export default useStyles;
