import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_PROJECT_ACCESS_LEVELS = gql`
  query($name: String, $page: Int, $limit: Int) {
    projectAccessLevels(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      access_levels {
        id
        access_code
        description
      }
    }
  }
`;

export const QueryProjectAccessLevels = (
  name = null,
  page = null,
  limit = null
) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_ACCESS_LEVELS),
    variables: {
      name,
      page,
      limit,
    },
  });
};
