import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_ORPHANS = gql`
  query($idUser: Int, $page: Int, $limit: Int) {
    orphans(idUser: $idUser, page: $page, limit: $limit) {
      idFileObject
      idUser

      Owner {
        id
        FullName
      }

      original_name
      mimetype
      Size
      Thumbnail
      filepath

      updatedAt
      createdAt
    }
  }
`;

export const QueryOrphans = (idUser, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ORPHANS),
    variables: { idUser, page, limit },
  });
};
const MUTATION_DELETE_ORPHANS = gql`
  mutation($ids: [Int!]!) {
    DeleteOrphans(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
export const MutationDeleteOrphans = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_ORPHANS),
    variables: { ids },
  });
};
const QUERY_USER_FILES = gql`
  query($idUser: LongLong, $page: Int, $limit: Int, $filters: FilterFile) {
    filesAtUser(
      idUser: $idUser
      page: $page
      limit: $limit
      filters: $filters
    ) {
      ok
      total
      errors {
        path
        message
      }
      files {
        idFileObject
        idUser

        Owner {
          id
          FullName
        }

        original_name
        mimetype
        Size
        filepath

        locations {
          idFolderContent
          Folder {
            idFolder
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryUserFiles = (filters, idUser, page, limit) => {
  console.log('QueryUserFiles', { filters, idUser, page, limit });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_FILES),
    variables: { filters, idUser, page, limit },
  });
};
const QUERY_FILES = gql`
  query($page: Int, $limit: Int, $idUser: LongLong, $filters: FilterFile) {
    filesAtUser(
      idUser: $idUser
      page: $page
      limit: $limit
      filters: $filters
    ) {
      ok
      total
      errors {
        path
        message
      }
      files {
        idFileObject

        Owner {
          id
          FullName
        }
        project {
          id
          name
        }

        derivated

        original_name
        mimetype
        Size
        Thumbnail
        filepath

        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryFiles = (search, page, limit, settings) => {
  let idUser = null;
  let filters = null;
  if (settings) {
    if (settings.idUser) idUser = settings.idUser;
    if (settings.FileDate)
      filters = { ...filters, date_range: settings.FileDate };
    if (settings.idProject)
      filters = { ...filters, idProject: settings.idProject };
  }
  if (search) {
    if (filters) filters.search = search;
    else filters = { search };
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILES),
    variables: { page, limit, idUser, filters },
  });
};

const QUERY_FILES_USERS = gql`
  query($name: String, $page: Int, $limit: Int) {
    fileUsers(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      total
      users {
        id
        FullName
      }
    }
  }
`;
export const QueryFilesUsers = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILES_USERS),
    variables: { name, page, limit },
  });
};
const QUERY_FILES_PROJECTS = gql`
  query($name: String, $page: Int, $limit: Int) {
    fileProjects(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      total
      projects {
        id
        name
      }
    }
  }
`;
export const QueryFilesProjects = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_FILES_PROJECTS),
    variables: { name, page, limit },
  });
};
