import { gql, useSubscription } from '@apollo/client';
import { IsInvalid } from 'helpers';

const MUTATION_SUBSCRIPTION_ONLINE = gql`
  subscription($idUserTemp: LongLong!, $idDocker: LongLong!) {
    resultDockerTempAccess(idUserTemp: $idUserTemp, idDocker: $idDocker) {
      ok
      results
      errors {
        path
        message
      }
    }
  }
`;

const SubscribeDockerApp = props => {
  const { idUserTemp, idDocker, handleDockerResult } = props;
  // console.log({ idUserTemp, idDocker });
  if (IsInvalid(idUserTemp) || IsInvalid(idDocker)) return null;
  const { data, loading } = useSubscription(MUTATION_SUBSCRIPTION_ONLINE, {
    variables: { idUserTemp, idDocker },
  });
  // console.log({ data, loading });
  if (loading || IsInvalid(data)) return null;
  const { resultDockerTempAccess } = data;
  if (resultDockerTempAccess && handleDockerResult) {
    handleDockerResult(resultDockerTempAccess);
  }
  console.log('SubscribeDockerApp', {
    status: resultDockerTempAccess
      ? 'Subscription received'
      : 'Error Subscription, data is empty',
  });
  return null;
};
export default SubscribeDockerApp;
