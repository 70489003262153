import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { jwtDecode } from 'jwt-decode';

import isString from 'lodash/isString';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { FindKey, IsInvalid } from './utils';
import { FormatTime } from 'helpers';

const updateCollaborationsResponseMuation = gql`
  mutation($accepted: Boolean!, $id: LongLong!) {
    updateCollaborationsResponse(accepted: $accepted, id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
const MUTATION_REMOVE_COLLABORATORS = gql`
  mutation($emails: [String!]) {
    removeCollaborators(emails: $emails) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

const addCollaboratorMutation = gql`
  mutation($email: String!) {
    addCollaborator(email: $email) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
const addCollaboratorsMutation = gql`
  mutation($emails: [String!]) {
    addCollaborators(emails: $emails) {
      ok
      errors {
        path
        message
      }
    }
  }
`;
const QUERY_ALL_COLLABORATORS = gql`
  query($email: String) {
    allCollaborators(email: $email) {
      id
      admin
      user {
        firstName
        lastName
        FullName
        status
        avatar
        address {
          city
          country
        }
      }
      email
      invited
      accepted
      createdAt
    }
  }
`;

const getUserAtEmailQuery = gql`
  query($email: String!) {
    getUserAtEmail(email: $email) {
      id
      FullName
      firstName
      lastName
      status
      email
      avatar
      createdAt
      phone
      username
      address {
        city
        country
      }
    }
  }
`;
const QUERY_ALL_USERS = gql`
  query(
    $email: String
    $filter: Boolean
    $page: Int
    $limit: Int
    $name: String
  ) {
    allUsers(
      email: $email
      filter: $filter
      page: $page
      limit: $limit
      name: $name
    ) {
      ok
      errors {
        path
        message
      }
      total
      users {
        id
        FullName
        firstName
        lastName
        status
        email
        avatar
        createdAt
        phone
        username
        request
        address {
          city
          country
        }
      }
    }
  }
`;

export const getUserAtEmail = email => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(getUserAtEmailQuery),
    variables: {
      email: email,
    },
  });
};
export const QueryAllUsers = (email, name, page, limit, filter = null) => {
  const variables = {};
  if (email !== undefined && email) variables.email = email;
  if (name !== undefined && name) variables.name = name;
  if (page !== undefined) variables.page = page;
  if (limit !== undefined) variables.limit = limit;
  if (filter !== undefined && filter !== null) variables.filter = filter;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_USERS),
    variables,
  });
};
const user_object = `
        id
        title
        FullName
        firstName
        lastName
        status
        email
        avatar
        isActive
        last_login
        isOnline
        account {
          code
          description
        }
        category {
          id
          code
          name
        }
        createdAt
        phone
        username
        request
        address {
          city
          country
          line_1
        }
        `;

const QUERY_ALL_USERS_FULL = gql`
  query(
    $email: String
    $filter: Boolean
    $page: Int
    $limit: Int
    $name: String
    $orderBy: [String!]
  ) {
    allUsers(
      email: $email
      filter: $filter
      page: $page
      limit: $limit
      name: $name
      orderBy: $orderBy
    ) {
      ok
      errors {
        path
        message
      }
      total
      users {
        ${user_object}
      }
      onlines {
        ${user_object}
      }
      requests {
        id
        FullName
        username
        email
        status
        address {
          city
          country
        }
        account {
          code
          description
        }
        category {
          id
          code
          name
        }
        createdAt
      }
    }
  }
`;
export const QueryFullAllUsers = (
  email,
  name,
  page,
  limit,
  filter = null,
  orderBy = null
) => {
  const variables = {};
  if (email !== undefined && email) variables.email = email;
  if (name !== undefined && name) variables.name = name;
  if (limit !== undefined) {
    variables.page = page;
    variables.limit = limit;
  }
  if (filter !== undefined && filter !== null) variables.filter = filter;
  if (orderBy !== undefined && orderBy !== null) variables.orderBy = orderBy;

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_USERS_FULL),
    variables,
  });
};
export const updateCollaborationsResponse = (accepted, id) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(updateCollaborationsResponseMuation),
    variables: {
      accepted,
      id,
    },
  });
};

export const removeCollaborators = emails => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_COLLABORATORS),
    variables: {
      emails: emails,
    },
  });
};

export const allCollaborators = email => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_COLLABORATORS),
    variables: {
      email: email,
    },
  });
};

export const addCollaborator = email => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(addCollaboratorMutation),
    variables: {
      email: email,
    },
  });
};
export const addCollaborators = emails => {
  let list_emails = [];
  for (let key in emails) {
    const { email } = emails[key];
    list_emails.push(email);
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(addCollaboratorsMutation),
    variables: {
      emails: list_emails,
    },
  });
};
export const getInitials = (name = '') => {
  if (!name) return 'NN';
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
};
export const getNumber = value => {
  //
  if (value === '') {
    return 0;
  }
  if (isNaN(value)) {
    if (Array.isArray(value)) {
      return value.length;
    } else if (isString(value)) {
      return value.toInt();
    } else {
      return 0;
    }
  } else {
    if (Array.isArray(value)) {
      return value.length;
    } else if (isString(value)) {
      return value.toInt();
    }
    return value;
  }
};

export const getAddress = (user = {}) => {
  const { address } = user;
  if (address) {
    //
    if (
      address.street &&
      address.city &&
      address.country &&
      address.street.length &&
      address.city.length &&
      address.country.length
    ) {
      return address.street + ', ' + address.city + ', ' + address.country;
    }
    if (
      address.city &&
      address.country &&
      address.city.length &&
      address.country.length
    ) {
      return address.city + ', ' + address.country;
    }
    if (address.country && address.country.length) {
      return address.country;
    }
    if (address.street && address.street.length) {
      return address.street;
    }
  }
  return '';
};
export const getCityCountry = (city, country) => {
  if (city && country && city.length && country.length) {
    return city + ', ' + country;
  } else if (city) {
    return city;
  } else if (country) {
    return country;
  } else {
    return '';
  }
};
const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
    minWidth: 170,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
  },
  {
    id: 'address',
    label: 'Location',
    minWidth: 170,
    align: 'left',
    format: value => (value ? getCityCountry(value.city, value.country) : ''),
  },
  {
    id: 'phone',
    label: 'Phone',
    minWidth: 170,
    align: 'right',
    format: value => (value ? value.toLocaleString() : ''),
  },
  {
    id: 'createdAt',
    label: 'Registration Date',
    minWidth: 200,
    align: 'right',
    format: value => FormatTime(value, 'MMM Do - YYYY, h:mm a'),
  },
];
export const getTableColumns = (hide = []) => {
  if (!hide || !hide.length) {
    return columns;
  }
  const array = [...columns];
  //
  for (let index = 0; index < hide.length; index++) {
    const element = hide[index];
    const id = array
      .map(item => {
        return item.id;
      })
      .indexOf(element);
    array.splice(id, 1);
  }
  return array;
};

export const getDate = value => {
  return FormatTime(value, 'DD/MM/YYYY');
};
export const IsUserObject = value => {
  if (!value || typeof value === 'undefined' || value === '') {
    return false;
  }
  //
  if (FindKey(value, 'firstName') && FindKey(value, 'lastName')) {
    return true;
  } else if (typeof value.FullName !== 'undefined') {
    return true;
  } else {
    const { User } = value;
    if (typeof User !== 'undefined' && User) {
      if (FindKey(User, 'firstName') || FindKey(User, 'lastName')) {
        return true;
      } else if (FindKey(User, 'FullName')) {
        return true;
      }
    }
    return false;
  }
};
export const getUserFullName = value => {
  if (IsInvalid(value) || value === '') {
    return '';
  }
  const { FullName } = value;
  const { User } = value;
  if (User) {
    const { firstName, lastName, FullName } = User;
    if (typeof firstName !== 'undefined' && typeof lastName !== 'undefined') {
      return `${firstName} ${lastName}`;
    } else if (
      typeof firstName !== 'undefined' &&
      typeof lastName === 'undefined'
    ) {
      return firstName;
    } else if (
      typeof firstName === 'undefined' &&
      typeof lastName !== 'undefined'
    ) {
      return lastName;
    } else if (typeof FullName !== 'undefined') {
      return FullName;
    }
  } else if (FullName && FullName !== '') {
    return FullName;
  } else if (FindKey(value, 'firstName') && FindKey(value, 'lastName')) {
    return value['firstName'] + ' ' + value['lastName'];
  }
  return '';
};
export const GetSizeFromArray = value => {
  if (!value) {
    return '0';
  }
  const total = getNumber(value);
  if (total) {
    return total.toString();
  }
  return '0';
};

export const GetCurrentUser = () => {
  try {
    const token = localStorage.getItem('token');
    const myDecodedToken = jwtDecode(token);
    if (IsInvalid(myDecodedToken)) throw Error('Invalid Token');
    const { user: user_token } = myDecodedToken;
    const user = {
      id: user_token.id,
      FullName: user_token.FullName,
      firstName: user_token.firstName,
      lastName: user_token.lastName,
      username: user_token.username,
      status: user_token.status,
      bio: user_token.bio ? user_token.bio : 'Bio: Not defined',
      avatar: user_token.avatar
        ? user_token.avatar
        : '/images/avatars/avatar_0.png',
      email: user_token.email,
      phone: user_token.phone ? user_token.phone : '',
      city: user_token.city ? user_token.city : '',
      state: user_token.state ? user_token.state : '',
      country: user_token.country ? user_token.country : '',
      createdAt: user_token.createdAt ? user_token.createdAt : '',
    };
    return user;
  } catch (error) {
    return null;
  }
};
