import React from 'react';
import PropTypes from 'prop-types';
import { GetData, IsInvalid, IsValid, ServerErrorsString } from 'helpers';
import { MutationWgetFolder } from 'graphql/Folders/utils_folders';

class ExplorerMenuWgetFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.holder) {
      props.holder.openWgetFolder = () => this.handleWgetFolder();
    }
  }
  handleWgetFolder = () => {
    const { itemOver } = this.props;
    console.log('itemOver', { itemOver });
    if (IsValid(itemOver)) {
      // create file
      const { idFolder } = itemOver;
      if (IsInvalid(idFolder)) {
        this.props.handleAddError(
          'To download the WGET command file you need to select a valid Folder.'
        );
      }
      (async () => {
        MutationWgetFolder(idFolder)
          .then(res => {
            const data = GetData(res);
            console.log({ data });
            const { ok, errors, wgetFiles } = data.wgetFolder;
            if (ok) {
              console.log('wgetFiles', { wgetFiles });
              const fileContent = wgetFiles
                .map(item => `Filename: ${item.Filename}, Link: ${item.link}`)
                .join('\n');

              const blob = new Blob([fileContent], { type: 'text/plain' });

              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = 'ids_folders_files.txt'; // File name to save as
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              throw errors;
            }
          })
          .catch(error => {
            console.log('Error ExplorerMenuRunProcess', { error });
            this.setState({ running: false });
            this.props.handleAddError(ServerErrorsString(error));
          });
      })();
    } else {
      this.props.handleAddError(
        'To download the WGET command file you need to select (Folder) first.'
      );
    }
  };
  render() {
    return null;
  }
}

ExplorerMenuWgetFolder.propTypes = {
  itemOver: PropTypes.object,
};

export default ExplorerMenuWgetFolder;
