import { FormatBytes, IsInvalidParam } from 'helpers';
import { FormatTime } from 'helpers';
import { IsInvalid, getUserFullName } from 'helpers';

export const columns_sequence = [
  { field: 'idSeries', headerName: 'ID', align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const Owner = params.getValue('Owner');
      if (IsInvalid(Owner)) return '';
      return getUserFullName(Owner);
    },
  },
  {
    field: 'NumberOfSeriesRelatedInstances',
    headerName: '#Files',
    align: 'right',
  },
  {
    field: 'Modality',
    headerName: 'Modality',
    align: 'center',
  },
  {
    field: 'MRAcquisitionType',
    headerName: 'Type',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const ImageMr = params.getValue('ImageMr');
      if (IsInvalid(ImageMr)) return '';
      return ImageMr.MRAcquisitionType;
    },
  },
  {
    field: 'Size',
    headerName: 'Size',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const value = params.getValue('Size');
      if (IsInvalid(value)) return '';
      return FormatBytes(value);
    },
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    align: 'left',
  },
  {
    field: 'ProtocolName',
    headerName: 'ProtocolName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const ImageMr = params.getValue('ImageMr');
      if (IsInvalid(ImageMr)) return '';
      return ImageMr.ProtocolName;
    },
  },
  {
    field: 'SequenceName',
    headerName: 'SequenceName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const ImageMr = params.getValue('ImageMr');
      if (IsInvalid(ImageMr)) return '';
      return ImageMr.SequenceName;
    },
  },
  {
    field: 'ContrastBolusAgent',
    headerName: 'ContrastBolusAgent',
    align: 'center',
    width: 110,
  },
  {
    field: 'ImageSize',
    headerName: 'Image Size',
    align: 'center',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const ImageSize = params.getValue('ImageSize');
      if (IsInvalid(ImageSize)) return '';
      return ImageSize.Size;
    },
  },
  {
    field: 'ImageResolution',
    headerName: 'Spacing',
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const ImageResolution = params.getValue('ImageResolution');
      if (IsInvalid(ImageResolution)) return '';
      return ImageResolution.Spacing;
    },
  },
  {
    field: 'locations',
    headerName: 'Location',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (IsInvalid(params.getValue)) return '';
      const locations = params.getValue('locations');
      if (IsInvalid(locations) || !locations.length) return '';
      const { Folder } = locations[0];
      if (IsInvalid(Folder)) return '';
      return Folder.folders_paths.map(x => x.name).join('/');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
