import { IsInvalid, IsInvalidParam } from 'helpers';
import { FormatTime } from 'helpers';

export const columns = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  { field: 'type', headerName: 'Type', width: 75, align: 'left' },
  { field: 'key', headerName: 'Key', width: 75, align: 'left' },
  { field: 'value', headerName: 'Value', width: 75, align: 'left' },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  //  {
  //    field: 'user',
  //    headerName: 'Owner',
  //    style: {
  //      width: 50,
  //      minWidth: 70,
  //    },
  //    align: 'left',
  //    valueGetter: params => {
  //      if (IsInvalid(params)) return '';
  //      if (params.getValue === undefined) return '';
  //      const value = params.getValue( 'user');
  //      if (value === undefined || !value) return '';
  //      return getUserFullName(value);
  //    },
  //  },
];
