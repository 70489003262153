import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  MutationUserTempAccess,
  QueryUserTempAccess,
} from 'graphql/HomeProjects';
import { GetData, ServerErrorsString } from 'helpers';
import { ButtonLoading } from 'components';
class AaocaAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      code: '',
    };
  }
  componentDidUpdate() {
    const { code, email } = this.state;
    const { enabled } = this.props;
    if ((email === '' || code === '') && enabled) {
      this.props.setState({ enabled: false });
    }
  }
  handleActivate = () => {
    const { loading, email, code } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryUserTempAccess(email, 'AAOCA', code)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, access } = data.userTempAccess;
          if (ok) {
            this.props.setState({ enabled: true, access });
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRequest = () => {
    const { loading, email } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      MutationUserTempAccess(email, 'AAOCA')
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.createUserTempAccess;
          if (ok) {
            this.props.handleAddSuccess(
              `The code was send it to your email: ${email}`
            );
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleChange = event => {
    const key = event.target.id;
    const value = event.target.value;
    this.setState({ [key]: value });
  };
  render() {
    const { code, email } = this.state;
    const { active, full_run, enabled } = this.props;
    const style = {
      container: true,
      direction: 'row',
      sx: {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    };
    return (
      <Paper
        disabled={active || full_run}
        sx={{
          p: 5,
          paddingTop: 2,
          paddingBottom: 2,
          width: '100%',
          border: `${active ? '2px' : '1px'} solid ${
            active ? 'green' : full_run ? '#f5f5f5' : ''
          }`,
          borderRadius: '16px',
        }}>
        <Grid {...style}>
          <Grid item {...style} md={10} xs={12}>
            <Grid item md={7} pr={2} xs={12}>
              <TextField
                disabled={enabled}
                fullWidth
                id="email"
                InputLabelProps={{
                  shrink: true,
                }}
                label="E-mail"
                onChange={this.handleChange}
                placeholder="Enter your email"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  },
                }}
                type="email"
                value={email}
                variant="standard"
              />
            </Grid>
            <Grid item md={5} xs={12}>
              <TextField
                disabled={enabled}
                fullWidth
                id="code"
                InputLabelProps={{
                  shrink: true,
                }}
                label="CODE"
                onChange={this.handleChange}
                placeholder="Enter or request code"
                value={code}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            md={2}
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            xs={12}>
            {enabled ? (
              <IconButton
                onClick={() => this.props.setState({ enabled: false })}>
                <DeleteIcon sx={{ color: 'red' }} />
              </IconButton>
            ) : email !== '' && code !== '' ? (
              <Grid item pl={2}>
                <ButtonLoading
                  color="success"
                  handleClick={() => this.handleActivate()}
                  loading={this.state.loading}
                  name="Activate"
                  size="small"
                  transform={false}
                  variant="contained"
                />
              </Grid>
            ) : (
              <Grid item>
                <ButtonLoading
                  color="error"
                  handleClick={() => this.handleRequest()}
                  loading={this.state.loading}
                  name="Request"
                  size="small"
                  transform={false}
                  variant="contained"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

AaocaAccess.propTypes = {
  classes: PropTypes.object,
};

export default AaocaAccess;
