import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableUserSeries } from './components';
import { RowText, SearchTransparent } from 'components';

class TabUserSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      deleting: false,
      selected: [],
      rows_edited: [],
      row_clicked: null,
    };
  }
  handleAdvanceSearch = event => {
    if (!event) return;
    if (event.key !== 'Enter') return;
    const { search } = this.state;
    this.props.handleAdvanceSearch(search);
  };
  render() {
    const { limit, loading, page, rows, user, total, height } = this.props;
    const { deleting, search } = this.state;
    let type_selected = 'User: ';
    let name_selected = 'All';
    let title = 'User Series';
    if (user) {
      name_selected = user.FullName;
    } else if (this.props.project) {
      name_selected = this.props.project.name;
    }
    if (this.props.type !== 'users') {
      type_selected = 'Project: ';
      title = 'Project Series';
    }
    return (
      <Grid container>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          spacing={5}
          style={{ margin: 10, marginLeft: 15 }}
          xs={12}>
          <RowText
            display="block ruby"
            first={type_selected}
            second={name_selected}
            style={{ marginRight: 10 }}
          />
          <RowText display="block ruby" first="Total series: " second={total} />
          <Grid item style={{ padding: 0 }} xs>
            <SearchTransparent
              onChange={event => {
                this.setState({ search: event.target.value });
              }}
              onKeyPress={this.handleAdvanceSearch}
              value={search}
            />
          </Grid>
        </Grid>
        <TableUserSeries
          deleting={deleting}
          disable_splice
          handleChangePage={this.props.handleChangePage}
          handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
          handleReloadTable={() => this.props.handleReloadTable()}
          height={height}
          limit={limit}
          loading={loading}
          page={page}
          rows={rows}
          setState={state => this.setState(state)}
          state={this.state}
          title={title}
          total={total}
          user={user}
        />
      </Grid>
    );
  }
}

TabUserSeries.propTypes = {
  classes: PropTypes.object,
};

export default TabUserSeries;
