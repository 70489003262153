import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { picker_style } from '../../../styles';
const CellEditDate = ({ date, onChangeDate = () => '' }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={['DatePicker']}> */}
      <DatePicker
        // label="Controlled picker"
        format="DD/MM/YYYY"
        onChange={onChangeDate}
        sx={picker_style}
        value={dayjs(date, 'MMM D, YYYY h:mm A')}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
};
export default CellEditDate;
