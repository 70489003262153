import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { GetData, rowsPerPageBig, ServerErrorsString } from 'helpers';
import {
  GridDockerToolBar,
  TableSystemDockers,
  EditSystemDockerImage,
  CreateSystemDockerImage,
} from './components';
import { QuerySystemDockerImages } from 'graphql/Dockers/utils_images';
import { CreateDockerImage } from '../TabImages/components';
import { MutationDeleteDockerImage } from 'graphql/Dockers/utils_docker_system';
class TabSystemDockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 50,
      loading: false,
      deleting: false,
      images: [],
      selected: [],
      total: 0,
      open_edit: false,
      open_create: false,
      open_upload: false,
      open_delete: false,
    };
  }
  componentDidMount() {
    this.handleUpdateImages();
  }
  handleUpdateImages = (newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    console.log('TabSystemDockers');
    const { loading } = this.state;
    if (loading) return;
    let { page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QuerySystemDockerImages(null, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images, total } = data.systemDockerImages;
          if (ok) {
            this.setState({ loading: false, images, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelectedDockerImages = () => {
    const { deleting, selected } = this.state;
    const ids = selected.map(x => x.id);
    if (deleting) return;
    this.setState({ deleting: true });
    (async () => {
      MutationDeleteDockerImage(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteSystemDockerImage;
          if (ok) {
            this.setState({ deleting: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeletedSelected = () => {
    this.setState({ selected: [], open_delete: false });
    this.handleUpdateImages();
  };
  handleNewDockerImage = image => {
    const { images } = this.state;
    const index = images.map(x => x.idImage).indexOf(image.idImage);
    if (index === -1) {
      images.unshift(image);
    } else {
      this.props.handleAddError(
        `Docker image is already here (${image.idImage})`
      );
    }
    this.setState({ open_upload: false, images });
  };
  handleUpdateDockerSetting = (id, settings) => {
    const { images, selected } = this.state;
    let index = images.map(x => x.id).indexOf(id);
    // console.log({ id, settings, index, operation: settings.operation_name });
    if (index === -1) return;
    let operation_name = images[index].operation;
    if (settings.operation_name) {
      operation_name = settings.operation_name;
      delete settings.operation_name;
    }
    images[index].operation = operation_name;
    images[index].settings = settings;
    index = selected.map(x => x.id).indexOf(id);
    if (index !== -1) {
      selected[index].operation = operation_name;
      selected[index].settings = settings;
    }
    this.setState({ images, selected });
  };
  render() {
    const {
      deleting,
      loading,
      images,
      open_create,
      open_upload,
      open_edit,
    } = this.state;
    if (open_create) {
      return (
        <CreateSystemDockerImage
          docker_images={this.props.docker_images}
          handleAddError={this.props.handleAddError}
          handleOpenCreateDocker={() => this.setState({ open_create: false })}
          handleUpdateDockerSetting={this.handleUpdateDockerSetting}
        />
      );
    }
    if (open_edit) {
      return (
        <EditSystemDockerImage
          handleAddError={this.props.handleAddError}
          handleOpenCreateDocker={() => this.setState({ open_edit: false })}
          handleUpdateDockerSetting={this.handleUpdateDockerSetting}
          selected={this.state.selected}
        />
      );
    }
    if (open_upload) {
      return (
        <CreateDockerImage
          handleAddError={this.props.handleAddError}
          handleNewDockerImage={this.handleNewDockerImage}
          handleOpenCreateDocker={() => this.setState({ open_upload: false })}
        />
      );
    }
    return (
      <Grid
        container
        spacing={1}
        style={{ padding: 20, width: '100%', paddingBottom: 0 }}>
        <Grid item xs={12}>
          <GridDockerToolBar
            deleting={deleting}
            handleDeleteSelected={this.handleDeleteSelectedDockerImages}
            handleOpenCreate={() => this.setState({ open_create: true })}
            handleOpenCreateUpload={() => this.setState({ open_upload: true })}
            handleOpenDelete={() => this.setState({ open_delete: true })}
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleReload={() => this.handleUpdateImages()}
            loading={loading}
            selected={this.state.selected}
          />
        </Grid>
        <Grid
          item
          style={{
            minHeight: 400,
            backgroundColor: 'white',
            padding: 0,
            marginTop: 6,
            marginLeft: 6,
          }}
          xs={12}>
          <TableSystemDockers
            checkboxSelection
            external_selected={this.state.selected}
            height={this.props.height - 310}
            onPageChange={page =>
              this.handleUpdateImages(page, this.state.limit)
            }
            onRowsPerPageChange={limit => this.handleUpdateImages(0, limit)}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rows={images}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabSystemDockers.propTypes = {
  classes: PropTypes.object,
};

export default TabSystemDockers;
