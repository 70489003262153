import React from 'react';
import { LinearProgress } from '@mui/material';
import { StackProgress, TypoProgress } from '../../../styles';

const CellProgress = ({ progress }) => {
  return (
    <StackProgress>
      <LinearProgress
        sx={{
          position: 'relative',
          display: 'block',
          height: '4px',
          zIndex: 0,
          backgroundColor: 'var(--mui-palette-LinearProgress-primaryBg)',
          borderRadius: '8px',
          overflow: 'hidden',
          flex: '1 1 auto',
          '--mui-palette-LinearProgress-primaryBg': 'rgb(195, 192, 255)',
        }}
        value={progress}
        variant="determinate"
      />
      <TypoProgress component="p" variant="body2">
        {`${progress}%`}
      </TypoProgress>
    </StackProgress>
  );
};
export default CellProgress;
