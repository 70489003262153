import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { getParams, IsInvalid } from 'helpers';

import {
  TabProjectActivity,
  TabProjectFiles,
  TabProjectOverview,
  TabProjectTeams,
  TabProjectSetting,
  useStyles,
} from './components';
import { TabPanel } from 'components';
import { IsProjectAdmin, IsProjectFullAccess } from 'common';

class OverviewMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      tabs: [
        { name: 'overview', index: 0, TabComponent: TabProjectOverview },
        { name: 'files', index: 1, TabComponent: TabProjectFiles },
        { name: 'activity', index: 2, TabComponent: TabProjectActivity },
        { name: 'teams', index: 3, TabComponent: TabProjectTeams },
      ],
    };
    this.holder = {
      handleMemberDeleted: null,
    };
  }
  uploadSettings = () => {
    const access_code = this.getAccessLevel();
    const admin = this.IsAdmin();
    const { tabs } = this.state;
    if (
      IsProjectFullAccess(admin, access_code) &&
      tabs.map(x => x.index).indexOf(4) === -1
    ) {
      tabs.push({
        name: 'settings',
        index: 4,
        TabComponent: TabProjectSetting,
      });
      this.setState({ tabs });
    }
  };
  componentDidMount() {
    this.uploadSettings();
    this.updateTabName();
  }
  componentDidUpdate() {
    this.uploadSettings();
    this.updateTabName();
  }
  updateTabName = () => {
    const tab_name = this.getTabName();
    let tabValue = -1;
    const index = this.state.tabs.map(x => x.name).indexOf(tab_name);
    if (index !== -1) {
      tabValue = this.state.tabs[index].index;
    }
    // console.log({ tabValue });
    if (tabValue === -1) {
      this.props.history.push('/project_management/browser');
    } else if (tabValue !== this.state.tabValue) {
      if (tabValue < 0) tabValue = 0;
      this.setState({ tabValue });
    }
  };

  getTabName = () => {
    const params = getParams(this.props);
    if (IsInvalid(params)) return null;
    const { level_1 } = params;
    return level_1;
  };
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history, project } = this.props;
    newValue = parseInt(newValue, 10);
    if (typeof project === 'undefined' || project.id < 1) {
      history.push('/project_management/browser');
      return;
    }
    const { id: idProject } = project;
    this.setState({ tabValue: newValue });
    const index = this.state.tabs.map(x => x.index).indexOf(newValue);
    if (index !== -1) {
      const { name } = this.state.tabs[index];
      history.push(`/project_management/${idProject}/${name}`);
    } else {
      history.push(`/project_management/${idProject}/overview`);
    }
  };
  handleAddError = error => {
    this.props.handleAddError(error);
    if (error.indexOf('You are not in this project') !== -1) {
      this.props.history.push('/project_management/browser');
    }
  };
  getAccessLevel = () => {
    const { project } = this.props;
    if (IsInvalid(project)) return 0;
    const { membership } = project;
    if (IsInvalid(membership)) {
      return 0;
    }
    const { member_setting } = membership;
    if (IsInvalid(membership)) {
      return 0;
    }
    const { access_level } = member_setting;
    if (IsInvalid(access_level)) {
      return 0;
    }
    return access_level.access_code;
  };
  IsAdmin = () => {
    const { project, user } = this.props;
    if (IsInvalid(project)) return false;
    const { admin } = project;
    if (IsInvalid(user) || IsInvalid(admin)) return false;
    if (user.id === admin.id) return true;
    if (user.type === 1) return true;
    const access_code = this.getAccessLevel();
    return IsProjectAdmin(null, access_code, user);
  };

  render() {
    const { classes, project, user, height } = this.props;
    const { teams } = project;
    let total_teams = 0;
    if (typeof teams !== 'undefined') {
      total_teams = teams.length;
    }
    const isAdmin = this.IsAdmin();
    const { tabValue, tabs } = this.state;
    const height_container = height - 250;
    this.uploadSettings();
    return (
      <React.Fragment>
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {tabs.map(x => {
              const { index, name } = x;
              let label = name.charAt(0).toUpperCase() + name.slice(1);
              if (name === 'files') label = 'Folders/' + label;
              return <Tab key={`tab-project-${index}`} label={label} />;
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ height: height_container }}>
          {tabs.map(x => {
            const { index, TabComponent } = x;
            return (
              <TabPanel
                className={classes.files}
                index={index}
                key={`tab-tabpanel-project-${index}`}
                value={tabValue}>
                <TabComponent
                  access_code={this.getAccessLevel()}
                  handleAddError={this.handleAddError}
                  height={height_container - 15}
                  holder={this.holder}
                  isActive={index === tabValue}
                  isAdmin={isAdmin}
                  project={project}
                  projectChanged={this.props.projectChanged}
                  total_teams={total_teams}
                  user={user}
                />
              </TabPanel>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

OverviewMain.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default withStyles(withRouter(OverviewMain), useStyles);
