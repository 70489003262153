import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns_people = [
  {
    field: 'idPatient',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'GivenName',
    headerName: 'First name',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'FamilyName',
    headerName: 'Last name',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'PatientID',
    headerName: 'BirthDate',
    style: {
      width: 50,
      minWidth: 70,
    },
    align: 'left',
  },
  {
    field: 'NumberOfPatientRelatedStudies',
    headerName: '#Links',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
