// import { IsInvalid } from 'helpers';

const headers = [
  {
    field: 'user',
    headerName: 'Name',
    width: '10%',
    align: 'left',
    type: 'user_email',
  },
  {
    field: 'progress',
    headerName: 'Quota',
    type: 'progress',
    align: 'left',
    // valueGetter: params => {
    //   if (IsInvalid(params)) return '';
    //   const value = params.getValue( 'user');
    //   if (value === undefined || !value) return '';
    //   const { FullName } = value;
    //   return FullName ? FullName : '';
    // },
  },
  {
    field: 'phone',
    headerName: 'Phone number',
    type: 'type',
    minWidth: 150,
    align: 'left',
  },
  {
    field: 'created',
    headerName: 'Create at',
    minWidth: 180,
    align: 'left',
    type: 'date',
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'status',
    width: 100,
    minWidth: 100,
    align: 'left',
  },
  {
    field: '',
    headerName: 'Action',
    type: 'action',
    align: 'left',
  },
];
export default headers;
