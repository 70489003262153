import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';
import { Card, CardContent, Divider, Grid, TextField } from '@mui/material';
import { DivGrow, SnackMessage } from 'components';
import {
  ProjectAvatar,
  ProjectDescription,
  ProjectDetailsActions,
  ProjectFolder,
  ProjectName,
  ProjectType,
  ProjectCategory,
} from './components';
import { UpdateProjectDetails } from 'graphql/Projects';
import { FormatTime, GetData, IsInvalid, ServerErrorsString } from 'helpers';
import { IsEditAllow } from 'helpers/Designers';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    height: '100%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  item: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
});

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      warning: [],
      changed: 0,
      error: '',
    };
    this.holder = {
      getType: null,
      getName: null,
      getDescription: null,
      getAvatar: null,
      getFolderName: null,
      getCategory: null,
    };
  }

  handleSave = () => {
    const { loading } = this.state;
    if (loading) return;
    const { project } = this.props;
    this.setState({ loading: true, error: '', warning: [] });
    let idCategory = null;
    if (this.holder.getCategory) {
      idCategory = this.holder.getCategory();
    }
    const variables = { id: project.id };
    variables.input = {
      type: this.holder.getType(),
      name: this.holder.getName(),
      description: this.holder.getDescription(),
      avatar: this.holder.getAvatar(),
      folder_name: this.holder.getFolderName(),
      idCategory,
    };
    (async () => {
      UpdateProjectDetails(variables)
        .then(res => {
          const data = GetData(res);
          const { updateProjectDetails } = data;
          if (typeof updateProjectDetails !== 'undefined') {
            const { ok, errors } = updateProjectDetails;
            if (ok) {
              this.setState({ loading: false, changed: 0 });
              this.props.projectChanged({
                ...project,
                ...variables.input,
                ...updateProjectDetails.project,
                ProjectFolder: {
                  ...project.ProjectFolder,
                  name: variables.input.folder_name,
                },
              });
            } else {
              throw errors;
            }
          }
        })
        .catch(error => {
          this.setState({
            loading: false,
            error: ServerErrorsString(error),
          });
        });
    })();
  };
  handleSnackClose = () => {
    this.setState({ error: '', warning: [] });
  };
  handleChange = (name, status) => {
    // console.log({ name, status });
    let { changed } = this.state;
    if (changed[name] === status) return;
    this.setState({ changed: { ...changed, name: status } });
  };
  isChanged = () => {
    const { changed } = this.state;
    let count = 0;
    for (let key in changed) {
      if (changed[key]) {
        count++;
      }
    }
    return count ? true : false;
  };

  render() {
    const {
      user,
      classes,
      project,
      show,
      maximize,
      admin,
      access_code,
    } = this.props;
    const { loading, warning, error } = this.state;
    if (IsInvalid(project)) {
      return <div />;
    }
    let isAdmin = admin;
    if (access_code === 3) isAdmin = false;
    if (!show && !maximize) return null;
    const time_updated = FormatTime(
      project.updatedAt,
      'MMM Do - YYYY (h:mm a)'
    );
    const time_created = FormatTime(
      project.createdAt,
      'MMM Do - YYYY (h:mm a)'
    );
    let ComponentProjectCategory = null;
    if (IsEditAllow(user) || (user && user.id === 1)) {
      // console.log({ project });
      ComponentProjectCategory = (
        <Grid item md={6} xs={12}>
          <ProjectCategory
            admin={isAdmin}
            handleChange={this.handleChange}
            holder={this.holder}
            project={project}
          />
        </Grid>
      );
    }
    return (
      <Card className={classes.root}>
        <ProjectAvatar
          admin={isAdmin}
          handleChange={this.handleChange}
          holder={this.holder}
          project={project}
        />
        <Divider variant="middle" />
        <CardContent className={classes.content}>
          <Grid
            alignItems="flex-start"
            container
            direction="column"
            justifyContent="space-between">
            <Grid item md={6} xs={12}>
              <ProjectType
                admin={isAdmin}
                handleChange={this.handleChange}
                holder={this.holder}
                project={project}
              />
            </Grid>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              spacing={1}>
              <Grid item md={6} xs={12}>
                <ProjectName
                  admin={isAdmin}
                  handleChange={this.handleChange}
                  holder={this.holder}
                  project={project}
                />
              </Grid>
              {ComponentProjectCategory}
            </Grid>
            <ProjectDescription
              admin={isAdmin}
              handleChange={this.handleChange}
              holder={this.holder}
              project={project}
            />
            <ProjectFolder
              admin={isAdmin}
              handleChange={this.handleChange}
              holder={this.holder}
              project={project}
            />
            <Grid container>
              <Grid className={classes.item} item>
                <TextField
                  disabled
                  id="created"
                  label="Created"
                  size="small"
                  value={time_created}
                  variant="outlined"
                />
              </Grid>
              <DivGrow />
              <Grid className={classes.item} item>
                <TextField
                  disabled
                  id="updated"
                  label="Updated"
                  size="small"
                  value={time_updated}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider variant="middle" />
        <ProjectDetailsActions
          admin={isAdmin}
          changed={this.isChanged()}
          handleSaveDetails={this.handleSave}
          loading={loading}
        />
        <SnackMessage
          handleClose={this.handleSnackClose}
          message_text={error}
          open={error !== '' ? true : false}
          type="error"
        />
        <SnackMessage
          handleClose={this.handleSnackClose}
          message_text={warning.length ? warning[0] : 'Unknown warning'}
          open={warning && warning.length ? true : false}
          type="warning"
        />
      </Card>
    );
  }
}

ProjectDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
ProjectDetails.defaultProps = {
  maximize: false,
  show: true,
  admin: false,
};

export default withStyles(ProjectDetails, useStyles);
