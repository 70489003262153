import { FormatTime, IsInvalid } from 'helpers';
export const initColumns = [
  { field: 'id', headerName: 'ID Map', width: '10%', align: 'left' },
  {
    field: 'type_value',
    headerName: 'Type Value',
    align: 'left',
  },
];
export const addColumnsType = (j, map) => {
  return {
    field: `${j};;=field=;;${map.type_name}`,
    headerName: map.type_name,
    align: 'left',
    valueGetter: params => {
      const reply = GetParmInformation(params, ';;=field=;;');
      if (!reply) return '';
      const { secondary_maps, split_parts } = reply;
      const index_sec_map = parseInt(split_parts[0], 10);
      if (index_sec_map >= 0 && index_sec_map < secondary_maps.length) {
        const type_name = secondary_maps[index_sec_map].type_name;
        if (type_name !== split_parts[1]) return '';
        return secondary_maps[index_sec_map].type_value;
      }
      return '';
    },
  };
};
export const addColumnsTypeDescription = (j, map) => {
  return {
    field: `${j};;=field_INFO=;;${map.type_name}`,
    headerName: `${map.type_name} Info`,
    align: 'left',
    valueGetter: params => {
      const reply = GetParmInformation(params, ';;=field_INFO=;;');
      if (!reply) return '';
      const { secondary_maps, split_parts } = reply;
      const index_sec_map = parseInt(split_parts[0], 10);
      if (index_sec_map >= 0 && index_sec_map < secondary_maps.length) {
        const type_name = secondary_maps[index_sec_map].type_name;
        if (type_name !== split_parts[1]) {
          return '';
        }
        const { object_type } = secondary_maps[index_sec_map];
        if (object_type === undefined || !object_type) return '';
        const { series, file_object } = object_type;
        if (series) return series.SeriesDescription;
        if (file_object) return file_object.original_name;
      }
      return '';
    },
  };
};
export const addColumnsTypeDate = (j, map) => {
  return {
    field: `${j};;=field_DATE=;;${map.type_name}`,
    headerName: `${map.type_name} Date`,
    align: 'left',
    valueGetter: params => {
      const reply = GetParmInformation(params, ';;=field_DATE=;;');
      if (!reply) return '';
      const { secondary_maps, split_parts } = reply;
      const index_sec_map = parseInt(split_parts[0], 10);
      if (index_sec_map >= 0 && index_sec_map < secondary_maps.length) {
        const type_name = secondary_maps[index_sec_map].type_name;
        if (type_name !== split_parts[1]) {
          return '';
        }
        const { object_type } = secondary_maps[index_sec_map];
        if (object_type === undefined || !object_type) return '';
        const { series, file_object } = object_type;
        if (series) return series.SeriesDate;
        if (file_object)
          return FormatTime(file_object.createdAt, 'MMM Do, YYYY - h:mm a');
      }
      return '';
    },
  };
};
const GetParmInformation = (params, split) => {
  if (IsInvalid(params)) {
    console.log('no params');
    return null;
  }
  if (params.getValue === undefined) {
    console.log('no getValue');
    return null;
  }
  const secondary_maps = params.getValue('secondary_maps');
  if (secondary_maps === undefined || !secondary_maps) {
    console.log('no secondary_maps');
    return null;
  }
  if (params.getHeader === undefined) {
    console.log('no header');
    return null;
  }
  const header_field = params.getHeader('field');
  if (header_field === undefined || !header_field) {
    console.log('no header_field');
    return null;
  }
  const split_parts = header_field.split(split);
  if (split_parts.length !== 2) {
    return null;
  }
  return {
    split_parts,
    secondary_maps,
  };
};
