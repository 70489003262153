import { IsInvalid } from 'helpers';

export const headers = [
  { field: 'idType', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('type');
      if (IsInvalid(value)) return '';
      const { name } = value;
      return name ? name : '';
    },
  },
  { field: 'value', headerName: 'value', align: 'left', edit: true },
];
