import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TableGrid } from 'components';
import { rowsPerPageBig, GetData, ServerErrorsString } from 'helpers';
import {
  useStyles,
  columns_project_studies,
  ToolBarProjectStudies,
  MappingProjectStudyTypes,
  EditProjectStudies,
} from './components';
import { withStyles } from 'tss-react/mui';
import { QueryProjectStudies } from 'graphql/Projects';
import { IsAdministrator } from 'helpers';

class TabProjectSettingStudies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_edit: false,
      open_mapping: false,
      idCenter: null,
      loading: false,
      selected: [],
      project_studies: [],
      page: 0,
      limit: 50,
      name: null,
      total: 0,
      init: false,
      row_clicked: null,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    this.queryProjectStudies();
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    if (!this.state.init) this.queryProjectStudies();
  }
  queryProjectStudies = (newIdCenter, newPage, newLimit) => {
    const { idProject } = this.props;
    let { loading, page, limit, idCenter } = this.state;
    if (loading) return;
    if (newLimit) limit = newLimit;
    if (newPage !== undefined) page = newPage;
    if (newIdCenter) {
      if (newIdCenter < 1) idCenter = null;
      else idCenter = newIdCenter;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProjectStudies(idProject, idCenter, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, project_studies, errors, total } = data.projectStudies;
          if (ok) {
            this.setState({
              loading: false,
              project_studies,
              total,
              idCenter,
              page,
              limit,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddCenter = center => {
    let idCenter = -1;
    if (center) idCenter = center.id;
    this.queryProjectStudies(idCenter);
  };
  render() {
    if (!this.props.isActive) return null;
    const { height, access_code, user } = this.props;
    if (this.state.open_mapping) {
      return (
        <MappingProjectStudyTypes
          handleAddError={this.props.handleAddError}
          handleCloseEdit={() => this.setState({ open_mapping: false })}
          height={height}
          selected={this.state.selected}
        />
      );
    }
    if (this.state.open_edit) {
      return (
        <EditProjectStudies
          handleAddError={this.props.handleAddError}
          handleCloseEdit={() => this.setState({ open_edit: false })}
          height={height}
          selected={this.state.selected}
        />
      );
    }
    return (
      <Grid
        alignItems="stretch"
        container
        direction="column"
        item
        justifyContent="flex-start"
        spacing={1}
        xs={12}>
        <Grid item xs={12}>
          <ToolBarProjectStudies
            access_code={access_code}
            handleAddCenter={this.handleAddCenter}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleOpenVarMappping={() => this.setState({ open_mapping: true })}
            handleReloadTable={() => this.queryProjectStudies()}
            idProject={this.props.idProject}
            isAdmin={IsAdministrator(user)}
            loading={this.state.loading}
            row_clicked={this.state.row_clicked}
            selected={this.state.selected}
            user={this.props.user}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }} xs={12}>
          <TableGrid
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={columns_project_studies}
            height={height - 60}
            loading={this.state.loading}
            onPageChange={page =>
              this.queryProjectStudies(null, page, this.state.limit)
            }
            onRowClicked={row_clicked => this.setState({ row_clicked })}
            onRowsPerPageChange={limit =>
              this.queryProjectStudies(null, 0, limit)
            }
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={this.state.project_studies}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Grid>
      </Grid>
    );
  }
}

TabProjectSettingStudies.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabProjectSettingStudies, useStyles);
