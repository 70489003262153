import React from 'react';
import PropTypes from 'prop-types';
import { GetData, PaperComponent, ServerErrorsString } from 'helpers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AutoComboBox, ButtonLoad, DialogTitle } from 'components';
import { QuerySystemDockerImages } from 'graphql/Dockers/utils_images';
import { MutationUserTempAccess } from 'graphql/HomeProjects';
import moment from 'moment';

class DialogAddTempUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docker_image: null,
      email: '',
      loading: false,
      dockers: [{ operation: 'None', id: 0 }],
      total: 0,
    };
  }
  componentDidMount() {
    this.handleUpdateImages();
  }
  handleUpdateImages = () => {
    console.log('DialogSelectDocker');
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QuerySystemDockerImages()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, images, total } = data.systemDockerImages;
          if (ok) {
            images.unshift({ operation: 'None', id: 0 });
            this.setState({ loading: false, dockers: images, total });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleCreateTempUser = () => {
    const { loading, email, docker_image } = this.state;
    if (loading) return;
    const { operation } = docker_image;
    const setting = { idDocker: docker_image.id, send_email: false };
    this.setState({ loading: true });
    (async () => {
      MutationUserTempAccess(email, operation, setting)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, access } = data.createUserTempAccess;
          if (ok) {
            this.setState({ loading: false });
            const { id, idDocker, name_app, ...res } = access;
            this.props.handleCreateTempUser({
              id,
              docker: { id: idDocker, operation: name_app },
              ...res,
              updatedAt: moment().valueOf(),
              createdAt: moment().valueOf(),
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, dockers, email } = this.state;
    const { open, handleClose } = this.props;
    if (!open) return null;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Create new temporal access user
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new temp access user, please fill all the necessary email.
          </DialogContentText>
          <Grid container direction="column" mt={2} spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                InputLabelProps={{
                  shrink: true,
                }}
                label="E-mail"
                onChange={e => this.setState({ email: e.target.value })}
                placeholder="Enter your email"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  },
                }}
                type="email"
                value={email}
                variant="standard"
              />
            </Grid>
            <Grid item mt={2} xs={12}>
              <AutoComboBox
                defaultValue={dockers[0]}
                disabled={this.state.loading}
                getOptionLabel={option => {
                  if (option === undefined || option.operation === undefined)
                    return '';
                  return option.operation;
                }}
                label="Docker Image"
                onObjectChanged={docker_image =>
                  this.setState({ docker_image })
                }
                options={dockers}
                small
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            //  disable_button={selected.length ? false : true}
            handleClick={this.handleCreateTempUser}
            loading={loading}
            name="Add User"
            variant="text"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

DialogAddTempUser.propTypes = {
  classes: PropTypes.object,
};

export default DialogAddTempUser;
