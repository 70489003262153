import React from 'react';
import PropTypes from 'prop-types';
import {
  CreateNewUser,
  TableSiteUsers,
  UserView,
  ToolBarUserActions,
} from './components';
import { QueryFullAllUsers } from 'helpers/user_utils';
import { GetData, IsInvalid, ServerErrorsString, getParams } from 'helpers';
import { Grid, Paper } from '@mui/material';
import { SubscribeOnline } from 'graphql/Subscriptions';

class TabUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      requests: null,
      onlines: null,
      create_user: false,
      loading: false,
      deleting: false,
      users: [],
      edit_user: null,
      page: 0,
      limit: 50,
      total: 0,
      name: '',
      length: 0,
      view: 'all',
      numSaving: 0,
    };
    this.holder = {
      clearTable: null,
      getSelectedTable: null,
      reloadTableTemp: null,
      cleanTableTemp: null,
      setDeleting: null,
      addTableTemp: null,
      saveTableTemp: null,
    };
  }

  componentDidMount() {
    let { level_2 } = getParams(this.props);
    if (IsInvalid(level_2)) level_2 = 'all';
    this.setState({ view: level_2 }, () => {
      this.updateUsers();
    });
  }
  componentDidUpdate() {
    const { length, name } = this.state;
    const new_length = name ? name.length : 0;
    if (length !== new_length) {
      this.updateUsers();
    }
  }
  handleOrderByChanged = (orderBy, order) => {
    this.updateUsers(null, null, null, [`${orderBy}:${order}`]);
  };
  updateUsers = (
    newName = null,
    newPage = null,
    newLimit = null,
    orderBy = null
  ) => {
    if (newName && newName.constructor !== String) {
      newName = null;
    }
    const { loading } = this.state;
    let { page, limit, name } = this.state;

    if (loading) {
      if (newName !== null && name !== newName) {
        this.setState({ name: newName });
      }
      return;
    }
    if (newName !== null) {
      name = newName;
    }
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true, name });
    (async () => {
      QueryFullAllUsers(null, name, page, limit, false, orderBy)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users, total, requests, onlines } = data.allUsers;
          if (ok) {
            this.setState({
              users,
              loading: false,
              total,
              page,
              limit,
              requests,
              onlines,
              length: name ? name.length : 0,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({
            loading: false,
          });
        });
    })();
  };
  handleEditUser = user => {
    this.setState({ edit_user: user });
  };

  handleChangePage = newPage => {
    this.updateUsers(null, newPage, this.state.limit);
  };

  handleChangeRowsPerPage = limit => {
    this.updateUsers(null, 0, limit);
  };
  handleChangeName = event => {
    event.preventDefault();
    this.updateUsers(event.target.value);
  };
  handleOpenCreateUser = () => {
    const { view } = this.state;
    if (view === 'temp_users') {
      this.holder.addTableTemp();
    } else {
      this.setState({ create_user: !this.state.create_user });
    }
  };
  handleAddNewUser = user => {
    let { users, total } = this.state;
    const index = users.map(x => x.id).indexOf(user.id);
    if (index === -1) {
      users.unshift(user);
      total++;
    }
    this.setState({ users, create_user: false, total });
  };
  handleUpdateUserSettings = setting => {
    const { users } = this.state;
    const index = users.map(x => x.id).indexOf(setting.idUser);
    if (index !== -1) {
      users[index].account = setting.type;
      this.setState({ users });
    }
  };
  handleUpdateUser = user => {
    const { users } = this.state;
    const index = users.map(x => x.id).indexOf(user.id);
    if (index !== -1) {
      users[index] = {
        ...user,
      };
      this.setState({ users, edit_user: { ...user } });
    }
  };
  handleUserRequest = (idUser, added) => {
    let { users } = this.state;
    const index = users.map(x => x.id).indexOf(idUser);
    if (index === -1) {
      return;
    }
    if (added) {
      users[index].isActive = false;
    } else {
      users = users.filter(x => x.id !== idUser);
    }
    this.setState({ users, edit_user: false });
  };
  handleOnline = online => {
    if (online === undefined || !online) return;
    let { onlines, users } = this.state;
    if (onlines) {
      const i = users.map(x => x.id).indexOf(online.id);
      const index = onlines.map(x => x.id).indexOf(online.id);
      if (index !== -1) {
        if (online.isOnline) return;
        onlines = onlines.filter(x => x.id !== online.id);
        if (i !== -1) {
          users[i].isOnline = false;
        }
      } else if (online.isOnline) {
        onlines.push(online);
        if (i !== -1) {
          users[i].isOnline = true;
        }
      } else {
        return;
      }
    } else if (online.isOnline) {
      onlines = [online];
    } else {
      return;
    }
    this.setState({ onlines, users });
  };
  handleClearSelected = () => {
    const { view } = this.state;
    if (view === 'temp_users') {
      this.holder.cleanTableTemp();
    } else {
      if (this.holder.clearTable) this.holder.clearTable();
    }
    this.setState({ selected: [] });
  };
  handleViews = (event, view) => {
    this.setState({ view, selected: [] });
    this.props.history.push(`/administrator/users/${view}`);
  };
  handleReloadTable = () => {
    const { view } = this.state;
    if (view === 'temp_users') {
      this.holder.reloadTableTemp();
    } else {
      this.updateUsers();
    }
  };
  handleSave = () => {
    const { view } = this.state;
    if (view === 'temp_users') {
      this.holder.saveTableTemp();
    }
  };
  render() {
    const {
      loading,
      deleting,
      edit_user,
      create_user,
      page,
      limit,
      total,
      name,
    } = this.state;
    let { users } = this.state;
    const { height } = this.props;
    if (!this.props.isActive) return null;
    if (create_user) {
      return (
        <React.Fragment>
          <SubscribeOnline
            handleOnline={this.handleOnline}
            idReceiver={this.props.user.id}
          />
          <CreateNewUser
            handleAddError={this.props.handleAddError}
            handleAddNewUser={this.handleAddNewUser}
            handleOpenCreateUser={this.handleOpenCreateUser}
          />
        </React.Fragment>
      );
    }
    if (edit_user) {
      return (
        <React.Fragment>
          <SubscribeOnline
            handleOnline={this.handleOnline}
            idReceiver={this.props.user.id}
          />
          <UserView
            edit_user={edit_user}
            handleAddError={this.props.handleAddError}
            handleAddSuccess={this.props.handleAddSuccess}
            handleOpenEditUser={this.handleEditUser}
            handleUpdateUser={this.handleUpdateUser}
            handleUpdateUserSettings={this.handleUpdateUserSettings}
            handleUserRequest={this.handleUserRequest}
            height={height}
          />
        </React.Fragment>
      );
    }
    let count = users.map(x => x.isOnline).filter(x => x).length;
    let active = users.map(x => x.isActive).filter(x => !x).length;
    if (this.state.onlines) {
      count = this.state.onlines.length;
    }
    if (this.state.requests) {
      active = this.state.requests.length;
    }
    if (this.state.view === 'online') {
      if (this.state.onlines)
        users = this.state.onlines.map(x => ({
          ...x,
          isOnline: true,
          isActive: true,
        }));
      else users = users.filter(x => x.isOnline);
    } else if (this.state.view === 'requests') {
      if (this.state.requests)
        users = this.state.requests.map(x => ({
          ...x,
          isOnline: false,
          isActive: false,
          avatar: '/images/avatars/avatar_0.png',
        }));
      else users = users.filter(x => !x.isActive);
    }
    let height_table = null;
    if (height - 250 > 200) height_table = height - 250;
    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start"
        spacing={1}>
        <SubscribeOnline
          handleOnline={this.handleOnline}
          idReceiver={this.props.user.id}
        />
        <Grid item sx={{ width: '100%' }} xs={12}>
          <ToolBarUserActions
            handleAddError={this.props.handleAddError}
            handleChangeName={this.handleChangeName}
            handleClearSelected={this.handleClearSelected}
            handleOpenCreateUser={this.handleOpenCreateUser}
            handleReloadTable={this.handleReloadTable}
            handleSave={this.handleSave}
            handleState={state => this.setState(state)}
            handleViews={this.handleViews}
            history={this.props.history}
            holder={this.holder}
            loading={loading}
            match={this.props.match}
            name={name}
            numSaving={this.state.numSaving}
            numSelected={this.state.selected.length}
            onlines={count}
            requests={active}
            saving={this.state.saving}
            selected={this.state.selected}
            users={this.state.users}
            view={this.state.view}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }} xs={12}>
          <Paper>
            <TableSiteUsers
              deleting={deleting}
              dense
              disable_splice
              handleAddError={this.props.handleAddError}
              handleOpenEditUser={this.handleEditUser}
              handleReloadTable={this.handleReloadTable}
              handleSelected={selected => this.setState({ selected })}
              height={height_table}
              hide_toolbar
              holder={this.holder}
              limit={limit}
              loading={loading}
              onOrderByChanged={this.handleOrderByChanged}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              page={page}
              paper={false}
              setState={state => this.setState(state)}
              title={`Site Users (Online: ${count}, Request: ${active})`}
              total={total}
              users={users}
              view={this.state.view}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TabUsers.propTypes = {
  classes: PropTypes.object,
};

export default TabUsers;
