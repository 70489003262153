import { FormatBytes, FormatTime, GetThumbnailFile, IsInvalid } from 'helpers';

export const GetFileFromContent = item => {
  if (!item || item === undefined) return null;
  const { idFolder, Series, File } = item;
  if (Series) {
    return null;
  } else if (File) {
    return File;
  } else if (idFolder !== undefined && idFolder) {
    return null;
  }
  return null;
};

export const GetContentMonth = item => {
  if (!item || item === undefined) return '';
  return GetContentDate(item, 'MMMM - YYYY');
};

export const GetContentName = item => {
  if (!item || item === undefined) return '';
  const { idFolder, Series, File } = item;
  if (Series) {
    return Series.SeriesDescription;
  } else if (File) {
    return File.original_name;
  } else if (idFolder !== undefined && idFolder) {
    if (item.name !== undefined && item.name !== '') return item.name;
  }
  return 'Unknow name';
};
export const GetContentSize = item => {
  const { idFolder, Series, File } = item;
  if (Series) {
    return FormatBytes(Series.Size);
  } else if (File) {
    return FormatBytes(File.Size);
  } else if (idFolder !== undefined && idFolder) {
    return FormatBytes(item.Size);
  }
  return '0 Kb';
};

export const GetContentOwner = item => {
  const { Owner } = item;
  if (typeof Owner !== 'undefined' && Owner) {
    return Owner.FullName;
  }
  return 'Unknow name';
};
export const GetContentDate = (item, format = 'LLLL', key = 'createdAt') => {
  const { idFolder, Series, File } = item;
  if (Series) {
    return FormatTime(Series[key], format);
  } else if (File) {
    return FormatTime(File[key], format);
  } else if (idFolder !== undefined && idFolder) {
    return FormatTime(item[key], format);
  }
  return '0 Kb';
};

export const GetContentKind = item => {
  const { idFolder, Series, File } = item;
  if (Series) {
    return 'DCM';
  } else if (File) {
    return File.mimetype;
  } else if (idFolder !== undefined && idFolder) {
    return '--';
  }
  return '0 Kb';
};

export const GetContentRights = item => {
  let { Rights } = item;
  if (Rights.length) return Rights[0];
  return null;
};
export const GetContentThumbnail = item => {
  if (!item || item === undefined) return '';
  let { idFolder, Series, File } = item;
  if (Series) {
    if (Series.Thumbnail !== undefined && Series.Thumbnail !== '') {
      return Series.Thumbnail;
    } else {
      return '/images/icons/dcm.png';
    }
  } else if (File) {
    let Thumbnail = '';
    if (File.Thumbnail) {
      Thumbnail = `data:image/jpeg;base64,${File.Thumbnail}`;
    } else if (typeof File.original_name !== 'undefined') {
      Thumbnail = GetThumbnailFile(File.original_name);
    } else {
      Thumbnail = '/images/icons/bin.png';
    }
    return Thumbnail;
  } else if (idFolder !== undefined && idFolder) {
    return null;
  }
  return null;
};
export const IsFile = content => {
  if (content === undefined || !content) return false;
  const { FolderContent } = content;
  if (FolderContent === undefined || !FolderContent) return false;
  const { File } = FolderContent;
  if (File === undefined || !File) return false;
  return true;
};
export const IsFolder = content => {
  if (content === undefined || !content) return false;
  const { idFolder, idFolderContent, idFileObject, idSeries } = content;
  if (idFolderContent) return false;
  if (idFileObject) return false;
  if (idSeries) return false;
  if (idFolder === undefined || !idFolder) return false;
  return true;
};
export const IsJPEG = content => {
  if (content === undefined || !content) return false;
  // const { FolderContent } = content;
  // if (FolderContent === undefined || !FolderContent) return false;
  const { File } = content;
  if (File === undefined || !File) return false;
  const { mimetype } = File;
  if (mimetype === undefined || !mimetype) return false;
  if (mimetype !== 'image/jpeg') return false;
  return true;
};
export const IsSelectedJPEGs = selected_objs => {
  if (IsInvalid(selected_objs) || !selected_objs.length) return false;
  for (let i = 0; i < selected_objs.length; i++) {
    const selected = selected_objs[i];
    const { File } = selected;
    if (IsInvalid(File)) return false;
    const { mimetype } = File;
    if (mimetype === undefined || !mimetype) return false;
    if (mimetype !== 'image/jpeg') return false;
  }
  return true;
};
