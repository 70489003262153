import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { ButtonLoad, InputUsers, SnackMessage } from 'components';
import { GetData, PaperComponent, ServerErrorsString } from 'helpers';
import { useStyles } from '../Styles';
import { MutationAddTeamMembers } from 'graphql/Teams';

class DialogAddTeamMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      loading: false,
    };
  }

  handleSelected = selected => {
    this.setState({ selected });
  };
  handleAddMembers = () => {
    const { loading, selected } = this.state;
    if (loading) return;
    const { team, idProject } = this.props;
    if (typeof team === 'undefined' || !team || team.id < 1) {
      this.setState({ error: 'Team is missing' });
      return;
    }
    this.setState({ loading: true });
    const emails = selected.map(x => x.email);
    (async () => {
      MutationAddTeamMembers(emails, team.id, idProject)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.addTeamMembers;
          if (ok) {
            this.setState({ loading: false, selected: [], error: '' });
            this.props.handleAddMembers(selected);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({
            loading: false,
            error: ServerErrorsString(errors),
          });
        });
    })();
  };
  handleErrorClose = () => {
    this.setState({ error: '' });
  };

  render() {
    const { loading, error, selected } = this.state;
    const { classes, open, handleClose } = this.props;
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          Add Users To Team
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            To add a new user to team, please fill all the necessary
            information.
          </DialogContentText>
          <InputUsers
            active_only
            handleAddUsers={this.handleAddMembers}
            onChange={this.handleSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <ButtonLoad
            className={classes.button}
            disable_button={selected.length ? false : true}
            handleClick={this.handleAddMembers}
            loading={loading}
            name="Add Members"
            variant="text"
          />
        </DialogActions>
        <SnackMessage
          handleClose={this.handleErrorClose}
          message_text={error}
          open={error !== ''}
          type="error"
        />
      </Dialog>
    );
  }
}

DialogAddTeamMembers.propTypes = {
  classes: PropTypes.object,
};
DialogAddTeamMembers.defaultProps = {
  open: false,
};
export default withStyles(DialogAddTeamMembers, useStyles);
