/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { FormatBytes, FormatTime } from 'helpers';
import {
  fontFamilyInter,
  fontFamily,
  BpCheckedIcon,
} from './styles_upload_right';

class UploadRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: {
        src: '/images/activity/unknown.png',
        color: 'rgb(189 189 189/ 0.5)',
      },
      type: 'none',
      isNifti: false,
      isCropped: false,
    };
  }
  handleImageType = event => {
    const type = event.target.value;
    let selected = {
      src: '/images/activity/unknown.png',
      color: 'rgb(189 189 189 / 0.3)',
    };
    let isNifti = false;
    if (type === 'dicom') {
      selected = {
        src: '/images/icons/dcm.png',
        color: 'rgb(189 189 189/ 0.5)',
      };
    } else if (type === 'nifti') {
      selected = {
        src: '/images/icons/nii.png',
        color: 'rgb(189 189 189 / 0.3)',
      };
      isNifti = true;
    }
    this.setState({ type, selected });
    this.props.setState({
      disabled_all: type === 'none' ? true : false,
      isNifti,
    });
    this.props.setParentState({ uploaded: 0, progress: 0 });
  };
  handleOnClick = () => {
    const { file } = this.props;
    this.props.onDelete(file);
    this.setState({
      uploaded: 0,
      progress: 0,
      selected: {
        src: '/images/activity/unknown.png',
        color: 'rgb(189 189 189/ 0.5)',
      },
      type: 'none',
    });
  };
  handleChangeCheck = event => {
    event.preventDefault();
    const isCropped = event.target.checked;
    this.setState({ isCropped }, () => {
      this.props.setState({ isCropped });
    });
  };
  render() {
    const { selected, type } = this.state;
    const { file, progress, uploaded } = this.props;
    const radio = {
      '&.MuiRadio-root': {
        padding: '2px', // adjust this value
        paddingLeft: '9px',
        paddingRight: '6px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px', // custom size for the radio icon
      },
    };
    let name = '';
    let size = '';
    let lastModified = undefined;
    if (file) {
      name = file.name;
      size = file.size;
      lastModified = file.lastModified;
    }
    return (
      <Paper sx={{ width: '100%', height: '100%', padding: 2, minHeight: 180 }}>
        <Grid container>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            xs={12}>
            <Grid item xs={3}>
              <Avatar
                src={selected.src}
                sx={{
                  width: '80%',
                  height: '50%',
                  minHeight: 60,
                  backgroundColor: selected.color,
                  p: '2px',
                }}
                variant="rounded"
              />
            </Grid>
            <Grid
              container
              direction="row"
              item
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              xs>
              <Grid
                container
                direction="row"
                item
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
                xs={12}>
                <IconButton disabled={!file} onClick={this.handleOnClick}>
                  <DeleteIcon sx={{ color: file ? 'red' : 'gray' }} />
                </IconButton>
              </Grid>
              <Grid
                container
                direction="row"
                item
                sx={{
                  margin: '2px 0px 2px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                xs={12}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      width: '100%',
                      fontFamily,
                      fontWeight: 600,
                      fontSize: '0.875rem',
                      lineHeight: 1.57143,
                      overflow: 'hidden',
                      display: ' -webkit-box',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2,
                      MozBoxOrient: 'vertical',
                      height: '32px',
                    }}>
                    {name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      MozBoxAlign: 'center',
                      alignItems: 'center',
                      maxWidth: '100%',
                      fontWeight: 400,
                      fontSize: '0.75rem',
                      lineHeight: 1.5,
                      color: '#919EAB',
                      width: '99%',
                    }}>
                    {size !== '' ? (
                      <>
                        <div style={{ minWidth: 40, padding: 0, margin: 0 }}>
                          {FormatBytes(size)}
                        </div>
                        <Box
                          sx={{
                            marginLeft: '6px',
                            marginRight: '6px',
                            width: '2px',
                            height: '2px',
                            flexShrink: 0,
                            borderRadius: '50%',
                            backgroundColor: 'currentcolor',
                          }}
                        />
                      </>
                    ) : null}
                    <Typography
                      sx={{
                        margin: '0px',
                        fontFamily,
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.5,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '99%',
                        maxWidth: '99%',
                      }}>
                      {FormatTime(lastModified, 'MMM Do, YYYY')}
                      {/* 16 Nov 2024 5:10 pm */}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
            xs={12}>
            <Grid item xs={6}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    fontFamily: fontFamilyInter,
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                  }}>
                  File Type
                </FormLabel>
                <RadioGroup
                  aria-labelledby="selection-file"
                  name="row-radio-buttons-group"
                  onChange={this.handleImageType}
                  row
                  value={type}>
                  <FormControlLabel
                    control={<Radio size="small" sx={radio} />}
                    label="None"
                    size="small"
                    style={{ marginRight: 4 }}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px', // or whatever size you want
                        marginRight: 0,
                      },
                    }}
                    value="none"
                  />
                  <FormControlLabel
                    control={<Radio size="small" sx={radio} />}
                    label="DICOM"
                    size="small"
                    style={{ marginRight: 4 }}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px', // or whatever size you want
                        marginRight: 0,
                      },
                    }}
                    value="dicom"
                  />
                  <FormControlLabel
                    control={<Radio size="small" sx={radio} />}
                    label="NIfTI"
                    style={{ marginRight: 0, maringLeft: '15px' }}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px', // or whatever size you want
                      },
                    }}
                    value="nifti"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isCropped}
                    checkedIcon={<BpCheckedIcon />}
                    onChange={this.handleChangeCheck}
                    size="small"
                    sx={{
                      padding: 0,
                      '&.MuiButtonBase-root.MuiCheckbox-root': {
                        padding: '2px',
                        paddingLeft: '8px',
                      },
                    }}
                  />
                }
                label="Image is Cropped"
                style={{ marginRight: 0 }}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: '12px', // or whatever size you want
                    maring: 0,
                    padding: 0,
                  },
                }}
                value="cropped"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            xs={12}>
            {progress ? (
              <>
                <LinearProgress
                  sx={{
                    minWidth: progress >= 100 ? '82%' : '88%',
                    // [`&.${linearProgressClasses.colorPrimary}`]: {
                    //   backgroundColor: 'red',
                    // },
                    [`& .${linearProgressClasses.bar}`]: {
                      backgroundColor: progress >= 100 ? 'green' : undefined,
                    },
                  }}
                  value={progress}
                  variant="determinate"
                />
                {progress >= 100 ? (
                  <DoneAllIcon sx={{ color: 'green' }} />
                ) : null}
                <Typography
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    MozBoxAlign: 'center',
                    alignItems: 'center',
                    maxWidth: '100%',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.5,
                    color: '#919EAB',
                    width: '10%',
                  }}>
                  {FormatBytes(uploaded)}
                </Typography>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

UploadRight.propTypes = {
  classes: PropTypes.object,
};

export default UploadRight;
