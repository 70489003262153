import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { DateRangePicker, SearchUserProjects } from 'components';
import {
  MutationUpdateDashboardSettings,
  QueryUserDashboard,
} from 'graphql/Users/utils_dashboard';
import { GetData } from 'helpers';
import { ServerErrorsString } from 'helpers';
import { projectFilterOptions } from 'common';
import { IsInvalid } from 'helpers';

import { connect } from 'react-redux';
import { setDashboardSetting } from 'redux-store/actions/page_settings';
import moment from 'moment';

class UserViewDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      settings_saved: null,
      idUser: -1,
      dashboard: {
        selected_project: null,
        datestart: moment().subtract(1, 'day').toISOString(),
        dateend: moment().toISOString(),
        fullHistory: false,
      },
      original: null,
    };
    const { holder } = props;
    if (holder) {
      holder.handleSaveDashboard = () => this.handleSaveDashboard();
    }
  }
  componentDidMount() {
    this.handleDashboardUser();
  }
  componentDidUpdate() {
    const { edit_user } = this.props;
    if (edit_user && edit_user.id !== this.state.idUser) {
      this.handleDashboardUser();
    }
  }
  handleSaveDashboard = () => {
    const { edit_user } = this.props;
    const { saving, dashboard } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    this.props.holder.setSavingDashboard(true);
    const to_save = {
      idDashboard: dashboard.idDashboard,
      idUser: edit_user.id,
      fullHistory: dashboard.fullHistory,
      datestart: dashboard.datestart,
      dateend: dashboard.dateend,
      visualization_mode: dashboard.visualization_mode,
      idProject: dashboard.selected_project
        ? dashboard.selected_project.id
        : undefined,
    };
    (async () => {
      MutationUpdateDashboardSettings(to_save)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateDashboardSetting;
          if (ok) {
            this.setState({
              saving: false,
              original: {
                ...dashboard,
                selected_project: {
                  ...dashboard.selected_project,
                },
              },
            });
            this.props.holder.setSavingDashboard(false);
            this.props.handleChangeDashboard(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.holder.setSavingDashboard(false);
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsChanged = dashboard => {
    const { original } = this.state;
    if (!original) return false;
    if (original.visualization_mode !== dashboard.visualization_mode)
      return true;
    if (original.fullHistory !== dashboard.fullHistory) return true;
    if (original.dateend !== dashboard.dateend) return true;
    if (original.datestart !== dashboard.datestart) return true;
    if (original.selected_project.id !== dashboard.selected_project.id)
      return true;
    return false;
  };
  handleDashboardUser = () => {
    console.log('handleLoad');
    const { edit_user } = this.props;
    if (IsInvalid(edit_user)) return;
    const { loading } = this.state;
    if (loading || IsInvalid(edit_user)) return;
    this.setState({ loading: true });
    const idUser = edit_user.id;
    (async () => {
      QueryUserDashboard(idUser)
        .then(res => {
          const data = GetData(res);
          const { ok, setting_dashboard, errors } = data.userDashboard;
          console.log('handleLoad', { setting_dashboard });
          if (ok) {
            this.setState({
              loading: false,
              idUser,
              dashboard: setting_dashboard,
              settings_saved: setting_dashboard,
              original: {
                ...setting_dashboard,
                selected_project: {
                  ...setting_dashboard.selected_project,
                },
              },
            });
            this.props.handleChangeDashboard(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };

  handleVisualizationMode = (event, new_selection) => {
    const { dashboard_view, setDashboardSetting } = this.props;
    const { dashboard } = this.state;
    new_selection = new_selection ? new_selection.value : 4;
    let selected_project = dashboard_view.selected_project;
    if (new_selection !== 3) selected_project = null;
    setDashboardSetting({
      ...dashboard_view,
      visualization_mode: new_selection,
      selected_project,
    });
    if (new_selection) {
      dashboard.visualization_mode = new_selection;
    } else {
      dashboard.visualization_mode = 1;
    }
    const changed = this.IsChanged(dashboard);
    this.props.handleChangeDashboard(changed);
    this.props.handleChangeDashboard(changed);
    this.setState({ dashboard });
  };

  handleProjectSelection = selected_project => {
    console.log(selected_project);
    const { dashboard } = this.state;
    if (selected_project) {
      dashboard.idProject = selected_project.id;
      dashboard.selected_project = selected_project;
    }
    const changed = this.IsChanged(dashboard);
    this.props.handleChangeDashboard(changed);
    this.setState({ dashboard });
  };
  handleDateChange = date => {
    const { dashboard_view, setDashboardSetting } = this.props;
    const { dashboard } = this.state;
    dashboard.datestart = date[0].toISOString();
    dashboard.dateend = date[1].toISOString();
    const changed = this.IsChanged(dashboard);
    this.props.handleChangeDashboard(changed);
    this.setState({ dashboard });

    setDashboardSetting({
      ...dashboard_view,
      datestart: date[0].toISOString(),
      dateend: date[1].toISOString(),
    });
  };
  handleHistory = event => {
    const { dashboard_view, setDashboardSetting } = this.props;
    
    const fullHistory = event.target.checked;
    const { dashboard } = this.state;
    dashboard.fullHistory = fullHistory;
    const changed = this.IsChanged(dashboard);
    this.props.handleChangeDashboard(changed);
    this.setState({ dashboard });
    
    let datestart = null;
    let dateend = null;
    if (!fullHistory) {
      datestart = dashboard.datestart;
      dateend = dashboard.dateend;
    }
    setDashboardSetting({
      ...dashboard_view,
      fullHistory,
      datestart,
      dateend,
    });
  };
  render() {
    const { edit_user, dashboard_view } = this.props;
    const { loading, dashboard } = this.state;
    let {
      visualization_mode,
      selected_project,
      datestart,
      dateend,
      fullHistory,
    } = dashboard;
    let index = projectFilterOptions
      .map(x => x.value)
      .indexOf(visualization_mode);
    if (index === -1) index = 1;

    if (dashboard_view) {
      visualization_mode = dashboard_view.visualization_mode;
      selected_project = dashboard_view.selected_project;
      datestart = dashboard_view.datestart;
      dateend = dashboard_view.dateend;
      fullHistory = dashboard_view.fullHistory;
    }

    return (
      <Grid
        alignItems="flex-start"
        container
        direction="column"
        justifyContent="flex-start"
        p={1}
        pt={2}
        spacing={2}>
        <Grid item style={{ width: '50%' }} xs={12}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={option => option.label}
            id="visualization_mode"
            isOptionEqualToValue={(option, value) => {
              if (value) return option.label === value.label;
              else return false;
            }}
            onChange={this.handleVisualizationMode}
            options={projectFilterOptions}
            renderInput={params => (
              <TextField {...params} label="Visualization Modes" />
            )}
            size="small"
            value={projectFilterOptions[index]}
          />
        </Grid>
        <Grid item sx={{ paddingTop: 1, width: '100%' }} xs={11}>
          <SearchUserProjects
            disabled={visualization_mode !== 3}
            external_selected={selected_project}
            handleAddProject={this.handleProjectSelection}
            handleSetProject={this.handleProjectSelection}
            idUser={edit_user ? edit_user.id : null}
            label="Asign upload to Project"
            no_icon
            placeholder="Search My Projects..."
            selected={selected_project}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={fullHistory}
                disabled={loading}
                name="history"
                onChange={this.handleHistory}
              />
            }
            label="Full History"
          />
        </Grid>
        <Grid item style={{ width: '100%' }} xs={12}>
          <DateRangePicker
            dateend={dateend}
            datestart={datestart}
            disabled={fullHistory ? true : false}
            handleDateChange={this.handleDateChange}
            small
          />
        </Grid>
      </Grid>
    );
  }
}

UserViewDashboard.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { dashboard_view } = state.page_settings;
  const { user } = state.info_user;
  return { dashboard_view, user };
};

export default connect(mapStateToProps, {
  setDashboardSetting,
})(UserViewDashboard);
