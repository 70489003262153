import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetData, QueryAllUsers } from 'helpers';

import { useStyles } from '../Styles';
import { inputProps } from 'common';
import { IsInvalid } from 'helpers';

class InputUsers extends Component {
  constructor(props) {
    super(props);
    const users = [];
    let email = '';
    if (props.value) {
      users.push(props.value);
      email = props.value.email;
      if (props.withname) email = props.value.FullName;
    }
    this.state = {
      email,
      loading: false,
      users,
      open: false,
      length: 0,
    };
  }

  componentDidMount() {
    this.runSearchUser('');
  }

  componentDidUpdate() {
    const { value } = this.props;
    let { length, email, defaultValue } = this.state;
    let state = null;
    if (value && IsInvalid(defaultValue)) {
      state = { defaultValue: value };
      if (this.props.withname) {
        state.email = value.FullName;
      } else state.email = value.email;
      state.users = [value];
      email = state.email;
      length = email.length;
    } else if (length !== email.length) {
      // console.log({ length, email });
      this.runSearchUser(email);
    }
    if (state) this.setState(state);
  }

  handleUserChanged = user => {
    if (IsInvalid(user)) return;
    this.props.onChange(user);
    this.setState({ user });
  };
  handleUserNameChanged = email => {
    if (email !== this.state.email) {
      console.log('handleUserNameChanged', { email });
      this.runSearchUser(email);
      this.setState({ email });
    }
  };
  runSearchUser = new_email => {
    const { active_only } = this.props;
    let { loading, email } = this.state;
    if (loading) return;
    this.setState({
      loading: true,
      users: [],
      email: new_email,
    });
    let name = null;
    if (this.props.withname) {
      name = new_email;
      email = null;
    }
    (async () => {
      QueryAllUsers(email, name, 0, 5, this.props.filter)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users } = data.allUsers;
          if (ok) {
            if (!users.length) {
              users.push({ FullName: 'Not found' });
            }
            this.setState({
              users: active_only
                ? users.filter(u => u.status === 'active')
                : users,
              loading: false,
              email: new_email,
              length: new_email.length,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ users: [], loading: false });
        });
    })();
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handleAddUser = () => {
    this.setState({
      email: '',
      users: [],
      length: 0,
      open: false,
      user: undefined,
    });
    this.props.handleAddUsers();
  };

  render() {
    // const { classes, style } = this.props;
    const { users, open, loading, email } = this.state;
    const { textfield, enable_input_props, value } = this.props;
    const height = '40px';
    let style_input_props = null;
    if (enable_input_props) {
      style_input_props = {
        height,
        margin: 0,
        padding: 0,
        borderWidth: 0,
        border: 0,
      };
    }
    if (!users.length && value) {
      users.push(value);
    }
    let inputValue = email;
    if (!open && value && email === '') {
      inputValue = value.email;
      if (this.props.withname) inputValue = value.FullName;
    }
    return (
      <Autocomplete
        disabled={this.props.disabled}
        getOptionLabel={option => {
          if (typeof option === 'undefined') return '';
          const { FullName } = option;
          if (typeof FullName === 'undefined') return '';
          return FullName;
        }}
        id="search-users-input"
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) =>
          option.FullName === value.FullName
        }
        loading={loading}
        multiple={this.props.multiple}
        onChange={(event, newValue) => {
          this.handleUserChanged(newValue);
        }}
        onClose={() => {
          this.handleOpen(false);
        }}
        onInputChange={(event, newInputValue) => {
          this.handleUserNameChanged(newInputValue);
        }}
        onOpen={() => {
          this.handleOpen(true);
        }}
        open={open}
        options={users}
        renderInput={params => (
          <TextField
            {...params}
            {...textfield}
            InputLabelProps={{
              shrink: true,
              style: {
                borderWidth: 0,
                border: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              style: { style_input_props },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
              ...inputProps,
            }}
            name="user"
            onKeyDown={event => {
              const { key } = event;
              if (key === 'Enter') {
                this.handleAddUser();
              }
            }}
            placeholder={this.props.placeholder}
            variant={this.props.variant}
          />
        )}
        style={{ width: '100%', height, borderWidth: 0, border: 0 }}
      />
    );
  }
}

InputUsers.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enable_input_props: PropTypes.bool,
  filter: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  textfield: PropTypes.object,
  value: PropTypes.object,
  variant: PropTypes.string,
  withname: PropTypes.bool,
};
InputUsers.defaultProps = {
  textfield: {},
  placeholder: 'Search Users...',
  variant: 'standard',
  enable_input_props: true,
  multiple: true,
  value: null,
  filter: null,
  disabled: false,
  withname: true,
  handleAddUsers: () => '',
  onChange: () => '',
  active_only: false,
};

export default withStyles(InputUsers, useStyles);
