import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';
import moment from 'moment';

export const columns_patient_signals = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'GivenName',
    headerName: 'FirstName',
    align: 'left',
  },
  {
    field: 'FamilyName',
    headerName: 'LastName',
    align: 'left',
  },
  {
    field: 'PatientSex',
    headerName: 'Sex',
    align: 'left',
  },
  {
    field: 'PatientBirthDate',
    headerName: 'BirthDate',
    align: 'left',
    width: 110,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('PatientBirthDate');
      if (value === undefined || !value) return '';
      value = moment(value, 'YYYY-MM-DD').valueOf();
      return FormatTime(value, 'MMM Do - YYYY');
    },
  },
  {
    field: 'PatientID',
    headerName: 'PatientID',
    align: 'left',
  },
  {
    field: 'NumberOfPatientRelatedInstances',
    headerName: '#EDFs',
    align: 'left',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
