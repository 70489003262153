export const useStyles = () => ({
  root: {
    padding: '2px 4px 2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
});

export const useStylesTableShare = theme => ({
  table: {
    maringTop: theme.spacing(100),
    height: '100%',
    width: '100%',
    minWidth: 400,
  },
  header: {
    flexGrow: 1,
    maxHeight: '10px',
  },
  button_no_dense: {
    height: '35px',
  },
  button_dense: {
    height: '20px',
    textTransform: 'none',
  },
});

export let headCells = [
  {
    id: 'sequence_name',
    align: 'center',
    numeric: false,
    disablePadding: true,
    label: 'Sequence/Series ID',
  },
  {
    id: 'idJob',
    align: 'center',
    numeric: false,
    disablePadding: true,
    label: '# Job',
  },
  // {
  //   id: 'permission',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Permission (W/R/Do/De/F)',
  //   short: '(W/R/Do/De/F)',
  // },
];
