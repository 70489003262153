import React from 'react';

import Draggable from 'react-draggable';
import { Paper } from '@mui/material';

export const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 30,
    },
    format: {
      pattern: '[a-z0-9\\s\\-\\_]+',
      flags: 'i',
      message: 'can only contain a-z, 0-9, - and _ , without spaces',
    },
  },
  email: {
    presence: false, // { allowEmpty: true, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
};
export const PaperComponent = props => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

export const useStyles = () => ({
  root: {},
  textField: {
    // width: 200
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
  },
});
