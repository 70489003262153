import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { GetData, ServerErrorsString, FormatNumber } from 'helpers';
import {
  QueryProjectSettings,
  MutationUpdateProjectSettings,
} from 'graphql/Projects/utils_project';
import { ButtonLoading, DivGrow, TabPanel } from 'components';

import {
  useStyle,
  TabProjectSettingMain,
  TabProjectSettingMembers,
  ProjectMemberSettingEditor,
  TabProjectSettingCenters,
  TabProjectSettingObjects,
  TabProjectSettingMapping,
  TabProjectSettingStudies,
  TabProjectSettingPatients,
} from './components';
import { IsProjectAdmin, IsProjectFullAccess } from 'common';
import { getParams } from 'helpers';
import { IsInvalid } from 'helpers';
import validate from 'validate.js';
const schema_email = {
  text: {
    presence: false,
    email: true,
    length: {
      maximum: 64,
    },
  },
};
class TabProjectSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project_setting: null,
      original: null,
      updating: false,
      open_edit: false,
      selected: [],
      empty: false,
      page: 0,
      limit: 50,
      total: 0,
      open_add_member: false,
      tabValue: 0,
      tabs: [
        { name: 'setting', TabComponent: TabProjectSettingMain },
        { name: 'members', TabComponent: TabProjectSettingMembers },
        { name: 'centers', TabComponent: TabProjectSettingCenters },
        { name: 'patients', TabComponent: TabProjectSettingPatients },
        { name: 'studies', TabComponent: TabProjectSettingStudies },
        { name: 'objects', TabComponent: TabProjectSettingObjects },
        { name: 'mapping', TabComponent: TabProjectSettingMapping },
      ],
    };
    const { holder } = props;
    if (holder && !holder.handleMemberDeleted) {
      holder.handleMemberDeleted = members => {
        console.log('holder.handleMemberDeleted = members');
        let { original, project_setting } = this.state;
        if (!original || !project_setting || project_setting === undefined)
          return false;
        for (let i = 0; i < members.length; i++) {
          const element = members[i];
          let index = project_setting.member_settings
            .map(x => x.idUser)
            .indexOf(element.idUser);
          if (index !== -1) {
            project_setting.member_settings = project_setting.member_settings.splice(
              index,
              1
            );
          }
          index = original.member_settings.map(x => x.id).indexOf(element.id);
          if (index === -1) {
            original.member_settings = original.member_settings.splice(
              index,
              1
            );
          }
        }
        this.setState({ original, project_setting, selected: [] });
      };
    }
  }
  componentDidMount() {
    const { access_code, user, isAdmin } = this.props;
    const { level_2 } = getParams(this.props);
    const full_access = IsProjectFullAccess(isAdmin, access_code, user);
    const admin = IsProjectAdmin(isAdmin, access_code, user);
    console.log({ full_access, admin, access_code });
    let tabs = [];
    if (full_access && !admin) {
      if (access_code === 3)
        tabs.push({
          name: 'members',
          TabComponent: TabProjectSettingMembers,
        });
      tabs = tabs.concat([
        { name: 'centers', TabComponent: TabProjectSettingCenters },
        { name: 'patients', TabComponent: TabProjectSettingPatients },
        { name: 'studies', TabComponent: TabProjectSettingStudies },
        { name: 'objects', TabComponent: TabProjectSettingObjects },
      ]);
    } else if (access_code === 3 && !admin) {
      tabs = [
        { name: 'members', TabComponent: TabProjectSettingMembers },
        { name: 'centers', TabComponent: TabProjectSettingCenters },
        { name: 'patients', TabComponent: TabProjectSettingPatients },
        { name: 'studies', TabComponent: TabProjectSettingStudies },
        { name: 'objects', TabComponent: TabProjectSettingObjects },
      ];
    }
    let tabValue = 0;
    if (level_2 && level_2 !== '') {
      let i = tabs.map(x => x.name).indexOf(level_2);
      if (!tabs.length) i = this.state.tabs.map(x => x.name).indexOf(level_2);
      if (i !== -1) {
        tabValue = i;
      }
    }
    if (tabs.length) {
      this.setState({ tabs, tabValue });
    } else {
      this.setState({ tabValue });
    }
    this.updateSettings();
  }
  componentDidUpdate() {
    const { project } = this.props;
    if (!project || project === undefined) return;
    const { project_setting, empty } = this.state;
    if (!project_setting && !empty) {
      this.updateSettings();
    } else if (project_setting && project_setting.idProject === project.id)
      return;
  }
  updateSettings = (restart = null) => {
    const { project, access_code } = this.props;
    const { loading } = this.state;
    if (loading || IsInvalid(project)) return;
    const { id: idProject } = project;
    if (IsInvalid(idProject)) return;
    this.setState({ loading: true, empty: false });
    (async () => {
      QueryProjectSettings(idProject, this.state.page, this.state.limit)
        .then(res => {
          const data = GetData(res);
          const { ok, project_setting, errors } = data.projectSetting;
          if (ok) {
            const state = {
              loading: false,
              project_setting,
              empty: !project_setting,
            };
            if (!this.state.original || restart) {
              state.original = { ...project_setting };
            }
            this.setState(state);
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          this.setState({ loading: false, empty: true });
          let error = ServerErrorsString(errors);
          if (error && error.indexOf('Invalid Credentials') !== -1) {
            if (IsProjectFullAccess(null, access_code)) return;
          }
          this.props.handleAddError(error);
        });
    })();
  };
  handleUpdateProjectSetting = (project_setting, original = null) => {
    if (project_setting && original) {
      this.setState({ project_setting, original });
    } else if (project_setting) {
      this.setState({ project_setting });
    }
  };
  handleUpdateSettings = () => {
    const { updating, project_setting } = this.state;
    console.log({ updating, project_setting });
    if (updating || IsInvalid(project_setting)) return;
    const { idProject } = project_setting;
    console.log({ idProject });
    if (IsInvalid(idProject)) return;
    const { email } = project_setting;
    if (email) {
      const validation = validate({ text: email }, schema_email);
      if (validation) {
        this.props.handleAddError(validation.text.join('\n-'));
        return;
      }
    }
    this.setState({ updating: true });
    (async () => {
      MutationUpdateProjectSettings({ ...project_setting })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateProjectSetting;
          if (ok) {
            this.setState({
              updating: false,
              project_setting,
              original: { ...project_setting },
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ updating: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsChanged = () => {
    const { original, project_setting } = this.state;
    if (!original || !project_setting || project_setting === undefined)
      return false;
    if (original.private_folder !== project_setting.private_folder) return true;
    if (original.delete_folder !== project_setting.delete_folder) return true;
    if (original.email !== project_setting.email) return true;
    if (original.patient_code !== project_setting.patient_code) return true;
    if (original.secondary_emails !== project_setting.secondary_emails)
      return true;
    if (original.patient_name_id !== project_setting.patient_name_id)
      return true;
    if (original.storage && project_setting.storage) {
      let A = FormatNumber(original.storage);
      let B = FormatNumber(project_setting.storage);
      if (A.number !== B.number || A.format !== B.format) return true;
    }
    if (original.file_size && project_setting.file_size) {
      let A = FormatNumber(original.file_size);
      let B = FormatNumber(project_setting.file_size);
      if (A.number !== B.number || A.format !== B.format) return true;
    }
    return false;
  };
  handleEditMembersSettings = (selected = []) => {
    this.setState({ open_edit: !this.state.open_edit, selected });
  };
  handleEditedMembers = modified => {
    const { original, project_setting, selected } = this.state;
    for (let i = 0; i < modified.length; i++) {
      const { id } = modified[i];
      if (project_setting && project_setting.member_settings) {
        const index = project_setting.member_settings
          .map(x => x.id)
          .indexOf(id);
        if (index !== -1) {
          project_setting.member_settings[index] = { ...modified[i] };
        }
      }
      if (original && original.member_settings) {
        const index = original.member_settings.map(x => x.id).indexOf(id);
        if (index !== -1) {
          original.member_settings[index] = { ...modified[i] };
        }
      }
      if (selected && selected.length) {
        const index = selected.map(x => x.id).indexOf(id);
        if (index !== -1) {
          selected[index] = { ...modified[i] };
        }
      }
    }
    this.setState({ original, project_setting, selected });
  };
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    this.setState({ tabValue });
    const { project } = this.props;
    const { tabs } = this.state;
    let idProject = -1;
    if (idProject < 1 && project) {
      idProject = project.id;
    }
    if (tabValue >= 0 && tabValue < tabs.length) {
      this.props.history.push(
        `/project_management/${idProject}/settings/${tabs[tabValue].name}`
      );
    }
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, height, project } = this.props;
    const { loading, project_setting, updating } = this.state;
    let idProject = -1;
    if (project_setting && !loading) {
      idProject = project_setting.idProject;
    }
    if (idProject < 1 && project) {
      idProject = project.id;
    }
    let style = null;
    if (height) {
      style = { height, maxHeight: height };
    }
    if (this.state.open_edit) {
      return (
        <div className={classes.root} style={style}>
          <div className={classes.root_setting}>
            <ProjectMemberSettingEditor
              handleAddError={this.props.handleAddError}
              handleEditedMembers={this.handleEditedMembers}
              handleOpenEditView={this.handleEditMembersSettings}
              members={this.state.selected}
            />
          </div>
        </div>
      );
    }
    let tab_value = parseInt(this.state.tabValue, 10);
    let show_action = false;
    if (this.state.tabs.length > 2) {
      show_action = tab_value === 0 || tab_value === 1;
    }
    let ComponentCardActions = null;
    if (show_action) {
      ComponentCardActions = (
        <React.Fragment>
          <Divider />
          <CardActions>
            <DivGrow />
            <ButtonLoading
              disable_button={!this.IsChanged()}
              handleClick={this.handleUpdateSettings}
              loading={updating}
              name="Save"
              transform={false}
              variant="outlined"
            />
          </CardActions>
        </React.Fragment>
      );
    }
    // console.log({ tab_value });
    let height_content = height - 110;
    if (height_content < 100) height_content = height;
    let tabValue = parseInt(this.state.tabValue, 10);
    if (tabValue === undefined) tabValue = 0;
    return (
      <div className={classes.root} style={style}>
        <div className={classes.root_setting}>
          <Card style={{ height: '100%', overflowY: 'auto' }}>
            <Divider />
            <CardContent style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Tabs
                indicatorColor="primary"
                onChange={this.handleTabValue}
                textColor="primary"
                value={tab_value}
                variant="scrollable">
                {this.state.tabs.map((x, index) => {
                  const { name } = x;
                  let label = name.charAt(0).toUpperCase() + name.slice(1);
                  if (name === 'files') label = 'Folders/' + label;
                  return (
                    <Tab
                      key={`tab-project-setting-${index}`}
                      label={label}
                      style={{
                        textTransform: 'none',
                        padding: 0,
                        margin: 0,
                        maxHeight: 60,
                      }}
                    />
                  );
                })}
              </Tabs>
              <Divider />
              {this.state.tabs.map((x, index) => {
                const { TabComponent } = x;
                return (
                  <TabPanel
                    className={classes.files}
                    index={index}
                    key={`tab-tabpanel-project-setting-${index}`}
                    value={tabValue}>
                    <TabComponent
                      access_code={this.props.access_code}
                      handleAddError={this.props.handleAddError}
                      handleEditMembersSettings={this.handleEditMembersSettings}
                      handleUpdateProjectSetting={
                        this.handleUpdateProjectSetting
                      }
                      height={height_content}
                      idProject={idProject}
                      isActive={index === tabValue}
                      isAdmin={this.props.isAdmin}
                      loading={loading}
                      original={this.state.original}
                      project_setting={project_setting}
                      setStateSetting={state => this.setState(state)}
                      user={this.props.user}
                    />
                  </TabPanel>
                );
              })}
            </CardContent>
            {ComponentCardActions}
          </Card>
        </div>
      </div>
    );
  }
}

TabProjectSetting.propTypes = {
  classes: PropTypes.object,
};
TabProjectSetting.defaultProps = {
  handleChange: () => '',
};
export default withStyles(withRouter(TabProjectSetting), useStyle);
