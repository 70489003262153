export const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 3,
      maximum: 40,
    },
    format: {
      pattern: '[a-z0-9\\-\\_]+',
      flags: 'i',
      message: 'can only contain a-z and 0-9 and (-,_) without spaces',
    },
  },
  description: {
    presence: { allowEmpty: true },
  },
};

export const useStyles = () => ({
  root: {},
  textField: {
    // width: 200
  },
  button: {
    marginTop: 0,
    marginBottom: 0,
  },
});
