import React from 'react';
import PropTypes from 'prop-types';
import { DialogDeleteWarning, TableBlock } from 'components';
import { header_temp } from './components/utils';
import {
  MutationDeleteUsersTempAccess,
  MutationUpdateUsersTempAccess,
  QueryUsersTempAccess,
} from 'graphql/Users';
import { GetData, rowsPerPageBig, ServerErrorsString } from 'helpers';
import { Divider, TablePagination } from '@mui/material';
import { DialogAddTempUser } from './components';
class TableTempAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      users: [],
      edited: [],
      email: '',
      page: 0,
      limit: 100,
      open_delete: false,
      deleting: false,
      open_add: false,
    };
    this.holder = { clean: null };
    const { holder } = props;
    if (holder) {
      holder.reloadTableTemp = () => this.handleLoad();
      holder.cleanTableTemp = () => this.holder.clean();
      holder.deleteTableTemp = () => this.setState({ open_delete: true });
      holder.addTableTemp = () => this.setState({ open_add: true });
      holder.saveTableTemp = () => this.handleSaving();
    }
  }
  componentDidMount() {
    this.handleLoad();
  }
  handleLoad = (newPage, newLimit) => {
    const { loading, email } = this.state;
    if (loading) return;
    let { page, limit } = this.state;
    this.setState({ loading: true, users: [] });
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.props.setState({ loading: true });
    (async () => {
      QueryUsersTempAccess(email, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users, total } = data.allUserTempAccess;
          if (ok) {
            this.setState({
              users,
              loading: false,
              total,
              page,
              limit,
            });
            this.props.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({
            loading: false,
          });
          this.props.setState({ loading: false });
        });
    })();
  };
  handleSave = (data, new_row) => {
    const { edited } = this.state;
    const index = edited.findIndex(r => r.id === new_row.id);
    if (index === -1) {
      edited.push(new_row);
    } else {
      edited[index] = { ...new_row };
    }
    this.setState({ edited });
    this.props.setState({ numSaving: edited.length });
  };
  handleSelected = selected => {
    this.setState({ selected });
    this.props.handleSelected(selected);
  };
  handleDeleteSelected = () => {
    const { deleting, selected, users } = this.state;
    if (deleting) return;
    this.setState({ deleting: true });
    this.props.holder.setDeleting(true);
    const ids = selected.map(x => x.id);
    (async () => {
      MutationDeleteUsersTempAccess(ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteUserTempAccess;
          if (ok) {
            this.setState({
              users: users.filter(x => ids.indexOf(x.id) === -1),
              deleting: false,
              selected: [],
              open_delete: false,
            });
            this.props.handleSelected([]);
            this.holder.clean();
            this.props.holder.setDeleting(false);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false });
          this.props.holder.setDeleting(false);
        });
    })();
  };
  handleCreateTempUser = access => {
    const { users } = this.state;
    const index = users.findIndex(u => u.id === access.id);
    if (index === -1) users.unshift(access);
    this.setState({ users, open_add: false });
  };
  handleSaving = () => {
    const { saving, edited } = this.state;
    if (saving) return;
    this.setState({ saving: true });
    this.props.setState({ saving: true });
    const inputs = edited.map(({ id, docker, email, code, runs }) => ({
      id,
      idDocker: docker ? docker.id : null,
      email,
      code,
      runs,
    }));
    console.log({ edited });
    (async () => {
      MutationUpdateUsersTempAccess(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateUserTempAccess;
          if (ok) {
            this.setState({
              saving: false,
              selected: [],
              edited: [],
            });
            this.props.setState({ saving: false, numSaving: 0 });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ deleting: false });
          this.props.setState({ saving: false });
        });
    })();
  };
  handleChangePage = (event, page) => {
    event.preventDefault();
    this.handleLoad(page, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value }, () => {
      this.handleLoad(0, +event.target.value);
    });
  };
  render() {
    const { height } = this.props;
    const { users, page, limit } = this.state;
    return (
      <>
        <TableBlock
          data={users}
          dense
          handleSave={this.handleSave}
          headers={header_temp}
          height_table={height}
          holder={this.holder}
          limit={limit}
          loading={this.state.loading}
          onChangeSelected={this.handleSelected}
          page={page}
          paginationMode="server"
        />
        <Divider />
        <TablePagination
          component="div"
          count={this.state.total}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text="The delete temp user was triggered"
          title_text="Delete Users"
        />
        <DialogAddTempUser
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_add: false })}
          handleCreateTempUser={this.handleCreateTempUser}
          open={this.state.open_add}
        />
      </>
    );
  }
}

TableTempAccess.propTypes = {
  classes: PropTypes.object,
};

export default TableTempAccess;
