import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { GetData, IsValid, ServerErrorsString } from 'helpers';
// import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButtonBadge } from 'components/Buttons';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { setRunningProcesses } from 'redux-store/actions';
import {
  MutationStopProcess,
  QueryRunningProcess,
} from 'graphql/Dockers/utils_docker_process';
import SnackMessage from 'components/SnackMessage';

class ButtonShowRunningProcesses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTarget: null,
      open_running_processes: false,
      cancelling_process: null,
    };
  }
  PaperComponent(props) {
    return (
      <Draggable>
        <Paper {...props} />
      </Draggable>
    );
  }
  componentDidMount() {
    (async () => {
      QueryRunningProcess()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, docker_process } = data.runningProcess;
          if (ok) {
            this.props.setRunningProcesses(docker_process);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.props.setRunningProcesses([]);
        });
    })();
  }
  checkField = field => {
    return IsValid(field) && field !== '';
  };
  handleShowProcesses = event => {
    const { open_running_processes } = this.state;
    const currentTarget = event.currentTarget;

    this.setState({
      open_running_processes: !open_running_processes,
      currentTarget,
    });
  };
  handleCloseProcess = (event, item) => {
    const { running_processes } = this.props;
    this.setState({ cancelling_process: item });
    (async () => {
      MutationStopProcess(item.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.stopProcess;
          if (ok) {
            console.log('Process removed', { item });
            let newArray = JSON.parse(JSON.stringify(running_processes)); // deep copy
            newArray = newArray.filter(x => x.id !== item.id);
            this.props.setRunningProcesses(newArray);
            this.setState({
              cancelling_process: null,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleClose = () => {
    const { open_running_processes } = this.state;
    this.setState({ open_running_processes: !open_running_processes });
  };
  getAmountRunningProcesses = () => {
    const { running_processes } = this.props;
    const n_running = running_processes.filter(
      process => process.status === 'running'
    ).length;
    return n_running;
  };
  // eslint-disable-next-line no-unused-vars
  isDisabledCancelProcess = item => {
    const { cancelling_process } = this.state;
    return IsValid(cancelling_process); // && item.id === cancelling_process.id;
  };
  handleCloseSnack = () => {
    this.setState({ cancelling_process: null });
  };
  render() {
    const { running_processes } = this.props;
    const {
      cancelling_process,
      currentTarget,
      open_running_processes,
    } = this.state;
    return (
      <React.Fragment>
        <SnackMessage
          handleClose={this.handleCloseSnack}
          location={{
            vertical: 'top',
            horizontal: 'left',
          }}
          message_text={`Cancelling process ID:${
            IsValid(cancelling_process) ? cancelling_process.id : ''
          }`}
          open={IsValid(cancelling_process) ? true : false}
          type="info"
        />
        <IconButtonBadge
          color_icon="grey"
          Icon={PlaylistPlayIcon}
          onClick={this.handleShowProcesses}
          size={
            !open_running_processes && this.getAmountRunningProcesses() > 0
              ? this.getAmountRunningProcesses()
              : null
          }
          size_buttom="small"
          title={
            !open_running_processes && this.getAmountRunningProcesses() > 0
              ? 'Running processes'
              : null
          }
          vertical="top"
        />
        <Popper
          anchorEl={currentTarget}
          open={open_running_processes}
          placement="top-start"
          sx={{ zIndex: 1200 }}>
          <Paper
            anchorEl={document.body}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 20],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  altBoundary: true,
                  rootBoundary: 'document',
                  padding: 8,
                },
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['bottom-end'],
                },
              },
            ]}
            placement="bottom-end">
            <Grid
              alignItems="flex-start"
              container
              direction="row"
              justifyContent="flex-start"
              style={{
                backgroundColor: '#095275',
                alignItems: 'center',
              }}>
              <Typography component="div" sx={{ ml: 1, color: 'white' }}>
                <Box display="inline" fontWeight="fontWeightMedium">
                  Running Processes..
                </Box>
              </Typography>
              <Box sx={{ minWidth: '5px', flexGrow: 1 }} />
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
                size="small"
                sx={{ ml: 'auto', color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            {running_processes.length ? (
              <List dense>
                {running_processes.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      {item.status === 'running' ? (
                        <CircularProgress color="success" size={20} />
                      ) : (
                        <CheckCircleIcon color="success" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={`${item.operation} (${item.id})`} />
                    <IconButton
                      aria-label="close"
                      disabled={this.isDisabledCancelProcess(item)}
                      onClick={event => this.handleCloseProcess(event, item)}
                      size="small"
                      sx={{ ml: 2, color: 'grey' }}>
                      <CloseIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography component="p" sx={{ m: 1 }}>
                No running processes
              </Typography>
            )}
          </Paper>
        </Popper>
      </React.Fragment>
    );
  }
}

ButtonShowRunningProcesses.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { running_processes } = state.running_processes;
  return {
    running_processes: running_processes ? running_processes : [],
  };
};
export default connect(mapStateToProps, { setRunningProcesses })(
  ButtonShowRunningProcesses
);
