import React from 'react';
import PropTypes from 'prop-types';
import { Divider, TablePagination } from '@mui/material';

import { stableSort } from '../styles';
import { rowsPerPageSmall } from 'helpers';
import { TableBlock } from 'components';
import headers from './headers';

class TableCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      data: [
        {
          user: { name: 'Fran Perez', email: 'fran.perez@domain.com' },
          progress: 50,
          phone: '(815) 704-0045',
          created: 'Nov 11, 2024 7:52 PM',
          status: 'Active',
        },
        {
          user: { name: 'Penjani Inyene', email: 'penjani.inyene@domain.com' },
          progress: 100,
          phone: '(803) 937-8925',
          created: 'Nov 11, 2024 5:52 PM',
          status: 'Active',
        },
        {
          user: { name: 'Carson Darrin', email: 'carson.darrin@domain.com' },
          progress: 10,
          phone: '(715) 278-5041',
          created: 'Nov 10, 2024 7:52 PM',
          status: 'Pending',
        },
        {
          user: {
            name: 'Siegbert Gottfried',
            email: 'siegbert.gottfried@domain.com',
          },
          progress: 0,
          phone: '(603) 766-0431',
          created: 'Nov 10, 2024 1:52 PM',
          status: 'Blocked',
        },
        {
          user: {
            name: 'Miron Vitold',
            email: 'miron.vitold@domain.com',
          },
          progress: 50,
          phone: '(425) 434-5535',
          created: 'Nov 9, 2024 6:52 PM',
          status: 'Active',
        },
        {
          user: {
            name: 'John Doe',
            email: 'john.doe@domain.com',
          },
          progress: 50,
          phone: '(425) 555-5555',
          created: 'Nov 9, 2022 6:52 PM',
          status: 'Blocked',
        },
      ],
    };
  }
  componentDidMount() {
    this.props.setState({
      all: this.state.data.length,
      active: this.state.data.filter(d => d.status === 'Active').length,
      pending: this.state.data.filter(d => d.status === 'Pending').length,
      blocked: this.state.data.filter(d => d.status === 'Blocked').length,
    });
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ limit: +event.target.value, page: 0 });
  };
  handleSave = data => {
    this.setState({ data }, () => {
      this.props.setState({
        all: this.state.data.length,
        active: this.state.data.filter(d => d.status === 'Active').length,
        pending: this.state.data.filter(d => d.status === 'Pending').length,
        blocked: this.state.data.filter(d => d.status === 'Blocked').length,
      });
    });
  };
  render() {
    const { page, limit } = this.state;
    const {
      textsearchs,
      search,
      status,
      height_table,
      count_searchs,
    } = this.props;
    console.log({ textsearchs, search, status, height_table, count_searchs });
    let data = this.state.data.filter(d =>
      status === 'All' ? true : d.status === status
    );
    if (count_searchs && !search) {
      if (textsearchs.email) {
        data = data.filter(d =>
          d.user.name.toLowerCase().includes(textsearchs.email.toLowerCase())
        );
      }
      if (textsearchs.phone) {
        data = data.filter(d =>
          d.phone.toLowerCase().includes(textsearchs.phone.toLowerCase())
        );
      }
    }
    data = stableSort(data, this.state.menu_selected);
    return (
      <>
        <TableBlock
          data={this.state.data}
          handleSave={this.handleSave}
          headers={headers}
          height_table={height_table}
          limit={limit}
          page={page}
        />
        <Divider />
        <TablePagination
          component="div"
          count={data.length}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageSmall}
        />
      </>
    );
  }
}

TableCustom.propTypes = {
  classes: PropTypes.object,
};
TableCustom.defaultProps = {
  status: 'All',
};
export default TableCustom;
