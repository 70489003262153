import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { series_full } from './utils_series_query';
const QUERY_SERIES = gql`
  query($SeriesQuery: SeriesQuery, $setting: SettingQuerySeries) {
    Series(SeriesQuery: $SeriesQuery, setting: $setting) {
      ok
      Total
      Series {
        idSeries
        idStudy
        SeriesDate
        SeriesTime
        SeriesInstanceUID
        SeriesDescription
        NumberOfSeriesRelatedInstances
        Modality
        Size
        nifti

        Owner {
          id
          FullName
        }
        project {
          id
          name
        }
        sequence {
          idSequence
          name
        }
        Patient {
          PatientName
          PatientBirthDate
          PatientID
        }
        ImageSize {
          Rows
          Columns
          Size
        }
        ImageResolution {
          PixelSpacingX
          PixelSpacingY
          SpacingBetweenSlices
          SliceThickness
          Spacing
        }
        CurrentNumberOfFiles
        updatedAt
      }
    }
  }
`;
export const QuerySeries = (description, setting, SeriesDate) => {
  console.log('QuerySeries', { setting });
  const SeriesQuery = {};
  if (description !== undefined && description !== '') {
    SeriesQuery.SeriesDescription = description;
  }
  if (SeriesDate !== undefined && SeriesDate !== '') {
    SeriesQuery.SeriesDate = SeriesDate;
  }
  if (setting && setting.idSequence !== undefined && setting.idSequence) {
    SeriesQuery.idSequence = setting.idSequence;
  }
  let level = 0;
  if (setting) {
    if (setting.id_sequences) level++;
    if (setting.id_projects) level = level + 3;
    if (level > 2) setting.level = 1;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES),
    variables: {
      SeriesQuery,
      setting,
    },
  });
};

const QUERY_SERIES_FULL = gql`
  query($SeriesQuery: SeriesQuery, $setting: SettingQuerySeries) {
    Series(SeriesQuery: $SeriesQuery, setting: $setting) {
      ok
      Total
      errors {
        message
      }
      Series {
        ${series_full}
      }
    }
  }
`;
export const QuerySeriesThumbnails = (description, setting) => {
  console.log('QuerySeriesThumbnails', { setting });
  const SeriesQuery = {};
  if (description !== undefined && description !== '') {
    SeriesQuery.SeriesDescription = description;
  }
  if (setting && setting.search_type !== undefined) {
    SeriesQuery.search_type = setting.search_type;
    delete setting.search_type;
  }
  if (
    setting &&
    setting.SeriesDate !== undefined &&
    setting.SeriesDate !== ''
  ) {
    SeriesQuery.SeriesDate = setting.SeriesDate;
    delete setting.SeriesDate;
  }
  if (setting && setting.modalities && setting.modalities.length) {
    SeriesQuery.Modality = setting.modalities;
    delete setting.modalities;
  }
  let level = 0;
  if (setting) {
    if (setting.id_sequences) level++;
    if (setting.id_projects) level = level + 3;
    if (level > 2) setting.level = 1;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_FULL),
    variables: {
      SeriesQuery,
      setting,
    },
  });
};

const QUERY_SERIES_AT_STUDY = gql`
  query($SeriesQuery: SeriesQuery, $setting: SettingQuerySeries) {
    Series(SeriesQuery: $SeriesQuery, setting: $setting) {
      ok
      Total
      Series {
        idSeries
        idStudy
        SeriesDate
        SeriesTime
        SeriesInstanceUID
        SeriesDescription
        NumberOfSeriesRelatedInstances
        Modality
        Size
        ImageType
        Patient {
          PatientName
          PatientBirthDate
          PatientID
        }
        ImageSize {
          Rows
          Columns
        }
        ImageResolution {
          PixelSpacingX
          PixelSpacingY
          SpacingBetweenSlices
          SliceThickness
        }
        sequence {
          idSequence
          name
        }
        locations {
          idFolderContent
          idFolder
        }
        updatedAt
      }
    }
  }
`;
export const QuerySeriesAtStudy = (idStudy, queries = {}) => {
  const SeriesQuery = {
    idStudy: idStudy,
  };
  let setting = null;
  const { modalities, imagetype } = queries;
  if (modalities && modalities.length) {
    SeriesQuery.Modality = modalities;
  }
  let level = 0;
  if (queries.id_sequences) {
    setting = { id_sequences: queries.id_sequences };
    level++;
  }
  if (queries.id_projects) {
    setting = { ...setting, id_projects: queries.id_projects };
    level = level + 3;
  }
  if (imagetype && Object.keys(imagetype).length) {
    const { spacing, size } = imagetype;
    if (size) {
      SeriesQuery.ImageSize = size;
    }
    if (spacing) {
      SeriesQuery.ImageSpacing = spacing;
    }
    if (imagetype.nifti && imagetype.nifti !== '') {
      let nifti = 0; //'With NIfTI', 'Without NIfTI'
      if (imagetype.nifti === 'With NIfTI') nifti = 1;
      if (imagetype.nifti === 'Without NIfTI') nifti = 2;
      setting = {
        nifti,
      };
    }
  }
  if (Object.keys(queries).length)
    console.log('QuerySeriesAtStudy', { queries, setting });
  if (level > 2) setting.level = 1;
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_AT_STUDY),
    variables: {
      SeriesQuery,
      setting,
    },
  });
};

const QUERY_SERIES_AT_STUDY_THUMBNAILS = gql`
  query($SeriesQuery: SeriesQuery) {
    Series(SeriesQuery: $SeriesQuery) {
      ok
      Total
      Series {
        ${series_full}
      }
    }
  }
`;
export const QuerySeriesAtStudyThumbnails = idStudy => {
  const SeriesQuery = {
    idStudy,
  };
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_AT_STUDY_THUMBNAILS),
    variables: {
      SeriesQuery,
    },
  });
};

const QUERY_SERIES_FULL_AT_QUERY = gql`
  query($SeriesQuery: SeriesQuery) {
    Series(SeriesQuery: $SeriesQuery) {
      ok
      Total
      Series {
        ${series_full}
      }
    }
  }
`;
export const FullSeries = idSeries => {
  const SeriesQuery = { idSeries };

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_FULL_AT_QUERY),
    variables: {
      SeriesQuery,
    },
  });
};
const QUERY_SERIES_FULL_AT_USER = gql`
  query($SeriesQuery: SeriesQuery, $setting: SettingQuerySeries) {
    Series(SeriesQuery: $SeriesQuery, setting: $setting) {
      ok
      errors {
        path
        message
      }
      Total
      Series {
        idSeries
        idStudy
        idUser
        SeriesDate
        SeriesInstanceUID
        SeriesDescription
        NumberOfSeriesRelatedInstances
        NumberOfFrames
        Modality
        Size
        locations {
          idFolderContent
          Folder {
            idFolder
            name
            folders_paths {
              idFolder
              name
            }
          }
        }
        Owner {
          id
          FullName
        }
        Patient {
          PatientName
          PatientBirthDate
          PatientID
        }
        sequence {
          idSequence
          name
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const QuerySeriesAtUser = (description, idUser, page, limit) => {
  console.log('QuerySeriesAtUser', { description, idUser, page, limit });
  const SeriesQuery = {};
  if (typeof description !== 'undefined' && description !== '') {
    SeriesQuery.SeriesDescription = description;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_FULL_AT_USER),
    variables: {
      SeriesQuery,
      setting: { idUser, page, limit, full_search: true },
    },
  });
};
