import { FormatTime } from 'helpers';
import { IsInvalid } from 'helpers';
import { getPrivateAccess } from '../../TabObjectsShared/components';

export const columns = [
  { field: 'idDownload', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'user',
    headerName: 'Owner',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('user');
      if (IsInvalid(value)) return '';
      const { FullName } = value;
      return FullName ? FullName : '';
    },
  },
  {
    field: 'private_access',
    headerName: 'Privated',
    align: 'left',
    edit: true,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const private_access = params.getValue('private_access');
      if (IsInvalid(private_access)) return 'FALSE';
      return getPrivateAccess(private_access) ? 'TRUE' : 'FALSE';
    },
  },
  {
    field: 'download_count',
    headerName: '#Downloads',
    align: 'left',
    edit: true,
  },
  {
    field: 'key',
    headerName: 'Key',
    align: 'left',
    edit: true,
  },
  {
    field: 'save',
    headerName: 'Save',
    align: 'left',
    edit: true,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const saved = params.getValue('save');
      if (IsInvalid(saved)) return 'FALSE';
      return saved ? 'TRUE' : 'FALSE';
    },
  },
  {
    field: 'folder',
    headerName: 'Folder',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const folder = params.getValue('folder');
      if (folder) return folder.name;
      const content = params.getValue('folder_content');
      if (content) {
        const { Folder } = content;
        if (Folder) {
          const { idFolder, folders_paths } = Folder;
          let parts = [];
          if (idFolder) parts.push(`FolderID: ${Folder.idFolder}`);
          if (folders_paths && folders_paths.length)
            parts.push(
              `Name: "${folders_paths[folders_paths.length - 1].name}"`
            );
          return `${parts.join(', ')}`;
        }
      }
      const idFolder = params.getValue('idFolder');
      if (idFolder) return `Folder ID: ${idFolder}, missing`;
      return '';
    },
  },
  {
    field: 'link',
    headerName: 'Link',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const link = params.getValue('link');
      if (link) return link.code;
      const idLink = params.getValue('idLink');
      if (idLink) return `Link ID: ${idLink}, missing`;
      return '';
    },
  },
  {
    field: 'folder_content',
    headerName: 'Content',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const content = params.getValue('folder_content');
      let ID_Content = params.getValue('idFolderContent');
      if (content) {
        const { Series, File, idFolderContent } = content;
        if (idFolderContent) ID_Content = idFolderContent;
        if (Series) return `ContentID: ${ID_Content} with Series`;
        else if (File) return `ContentID: ${ID_Content} with File`;
      }
      if (ID_Content) {
        return `Content ID: ${ID_Content}, missing content`;
      }
      return '';
    },
  },
  {
    field: 'file_object',
    headerName: 'File',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const file_object = params.getValue('file_object');
      if (file_object) return file_object.original_name;
      const content = params.getValue('folder_content');
      if (content) {
        const { File } = content;
        if (File)
          return `FileID: ${File.idFileObject}, ${File.original_name} (File)`;
      }
      const idFile = params.getValue('idFile');
      if (idFile) return `File ID: ${idFile}, missing`;
      return '';
    },
  },
  {
    field: 'series',
    headerName: 'Series',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const series = params.getValue('series');
      if (series) return '';
      const idSeries = params.getValue('idSeries');
      if (idSeries) return `Series ID: ${idSeries}, missing`;
      const content = params.getValue('folder_content');
      if (content) {
        const { Series } = content;
        if (Series)
          return `SeriesID: ${Series.idSeries}, ${Series.SeriesDescription} (Series)`;
      }
      return '';
    },
  },
  {
    field: 'project',
    headerName: 'Project',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const project = params.getValue('project');
      if (project) return project.name;
      const idProject = params.getValue('idProject');
      if (idProject) return `Project ID: ${idProject}, missing`;
      return '';
    },
  },
  {
    field: 'location',
    headerName: 'Location',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const folder_content = params.getValue('folder_content');
      if (folder_content) {
        const { Folder } = folder_content;
        if (Folder) return Folder.folders_paths.map(x => x.name).join('/');
      }
      const folder = params.getValue('folder');
      if (folder) return folder.folders_paths.map(x => x.name).join('/');
      else return '';
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('updatedAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
