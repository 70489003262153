import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ProgressUndefined from './ProgressUndefined';
import { chart } from './utils';
import { GetData, ServerErrorsString } from 'helpers';
import { MutationRunDockerTempAccess } from 'graphql/Dockers';

import { keyframes } from '@emotion/react';
import { GridChartItem } from './components';

class AaocaResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option_left: {
        ...chart,
        series: [0],
        labels: ['Presence'],
      },
      option_center: {
        ...chart,
        series: [0],
        labels: ['Origins'],
      },
      option_right: {
        ...chart,
        series: [0],
        labels: ['Risk'],
        pathRadius: '10', // P
        grid: {
          padding: {
            top: -10,
            left: -40,
            right: -40,
            bottom: -30,
          },
        },
      },
      running: false,
      finished: false,
      diagnosis: '',
      AAOCA: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reset = () => {
        console.log('run - reset');
        const { option_left, option_center, option_right } = this.state;
        this.setState({
          running: false,
          finished: false,
          option_left: { ...option_left, series: [0] },
          option_center: { ...option_center, series: [0] },
          option_right: { ...option_right, series: [0] },
          diagnosis: '',
        });
      };
      holder.startRun = () => this.handleRun();
      holder.setStateResults = state => this.setState(state);
    }
  }
  componentDidUpdate() {
    const { disabled_all } = this.props;
    const { diagnosis } = this.state;
    if (disabled_all && diagnosis !== '' && this.props.holder.reset) {
      this.props.holder.reset();
    }
  }
  generateCSV = () => {
    const {
      option_left,
      option_center,
      option_right,
      diagnosis,
      AAOCA,
    } = this.state;
    const data = {
      presence: option_left.series[0],
      origin: option_center.series[0],
      risk: option_right.series[0],
    };
    let presence = '';
    let origin = '';
    let risk = '';
    if (AAOCA) {
      presence = AAOCA.txt_anomaly;
      origin = AAOCA.txt_origin;
      risk = AAOCA.txt_risk;
    }
    // prettier-ignore
    const content = `
############################################################################################################################
##################################################      Model Outputs     ##################################################
                              |   Anomaly Detection          |     Origin Classification    |Anatomical Risk Classification
         Probability          |            ${data.presence.toFixed(2)}             |            ${data.origin.toFixed(2)}             |            ${data.risk.toFixed(2)}             
        Final Results         |            ${presence}             |           ${origin}            |          ${risk}           
############################################################################################################################
################################################## Final Diagnosis Report ##################################################
${diagnosis}
############################################################################################################################
    `;

    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'diagnosis_report.txt';
    link.click();

    URL.revokeObjectURL(url); // Clean up the URL object
  };
  handleRun = () => {
    const {
      access,
      folder_content,
      threshold,
      isNifti,
      isCropped,
    } = this.props;
    const { running } = this.state;
    if (running) return;
    console.log('AaocaResults - handleRun', {
      access,
      folder_content,
      threshold,
      isNifti,
      isCropped,
    });
    this.setState({ running: true });
    if (this.props.holder.setTotal) {
      this.props.holder.setTotal(1);
    }
    const { idFolderContent } = folder_content;
    const inputs = {
      idUserTemp: access.id,
      idDockerImage: access.idDocker,
      idFolderContent,
      setting: JSON.stringify({
        threshold: threshold / 100,
        isNifti,
        isCropped,
      }),
    };
    (async () => {
      MutationRunDockerTempAccess(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, results } = data.runDockerTempAccess;
          if (ok) {
            console.log({ results });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ running: false });
          this.props.setState({ running: false, active: 0 });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleStart = () => {
    const { option_left, option_center, option_right } = this.state;
    this.setState(
      {
        running: false,
        finished: false,
        option_left: { ...option_left, series: [0] },
        option_center: { ...option_center, series: [0] },
        option_right: { ...option_right, series: [0] },
        diagnosis: '',
      },
      () => {
        this.props.handleStart();
      }
    );
  };
  render() {
    const { diagnosis, finished, running } = this.state;
    const { threshold, full_run, active, disabled_all, files } = this.props;
    const divider = {
      margin: '0px',
      flexShrink: '0',
      borderWidth: '0px thin 0px 0px',
      borderColor: 'rgba(145 158 171 / 0.5)',
      height: 'auto',
      alignSelf: 'stretch',
      borderStyle: 'dashed',
    };
    let ComponentProgress = null;
    if (running) {
      ComponentProgress = <ProgressUndefined />;
    }
    const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;
    return (
      <Paper
        disabled={active || full_run}
        sx={{
          backgroundImage: 'none',
          overflow: 'hidden',
          position: 'relative',
          borderRadius: '16px',
          width: '100%',
          border: `${active ? '2px' : '1px'} solid ${
            active ? 'green' : full_run ? '#f5f5f5' : ''
          }`,
        }}>
        <Grid
          container
          direction="row"
          item
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
          }}
          xs={12}>
          <Grid item xs={2}>
            <Button
              disabled={
                disabled_all ||
                running ||
                !threshold ||
                full_run ||
                !files.length
              }
              onClick={this.handleStart}
              startIcon={<PlayArrowIcon />}
              sx={{
                textTransform: 'none',
                backgroundColor: '#43a047',
                '&:hover': {
                  backgroundColor: '#66bb6a', // Hover background color
                },
              }}
              variant="contained">
              Run
            </Button>
          </Grid>
          <Grid
            container
            item
            sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
            xs={2}>
            <Button
              disabled={running || !finished || full_run}
              endIcon={<CloudDownloadIcon />}
              onClick={this.generateCSV}
              sx={{
                textTransform: 'none',
                backgroundColor: '#039be5',
                '&:hover': {
                  backgroundColor: '#29b6f6', // Hover background color
                },
              }}
              variant="contained">
              Export
            </Button>
          </Grid>
        </Grid>
        {ComponentProgress}
        <Stack sx={{ flexDirection: 'row' }}>
          <Grid container direction="row">
            <Grid item md={3.9} xs={12}>
              <GridChartItem
                options={this.state.option_left}
                running={running}
                textLeft="AAOCA"
                textRight="Normal"
                title="Probabilites of AAOCA presence"
              />
            </Grid>
            <Divider orientation="vertical" sx={divider} />
            <Grid item md={3.9} xs={12}>
              <GridChartItem
                options={this.state.option_center}
                running={running}
                textLeft="Left coronary"
                textRight="Right coronary"
                title="Probabilites of AAOCA origin"
              />
            </Grid>
            <Divider orientation="vertical" sx={divider} />
            <Grid item md={3.9} xs={12}>
              <GridChartItem
                options={this.state.option_right}
                running={running}
                sx={{ paddingRight: '10px' }}
                textLeft="High"
                textRight="Low"
                title="Probabilites of AAOCA anatomical risk*"
              />
            </Grid>
          </Grid>

          {/* <Box
            sx={{
              ...box,
              direction: 'column',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
    
          </Box> */}
        </Stack>
        <Grid container p={2}>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <Grid item sx={{ width: 30 }}>
              <CheckIcon sx={{ color: 'green' }} />
            </Grid>
            <Grid item sx={{ width: 90, color: 'green', fontWeight: 600 }}>
              Diagnosis:
            </Grid>
            <Grid item xs>
              {running ? (
                <Typography
                  sx={{
                    color: '#fb8c00',
                    fontWeight: 600,
                    textAlign: 'center',
                    animation: `${blinkAnimation} 3s linear infinite`,
                  }}>
                  Please wait 1 to 2 minutes...
                  <br />
                  Processing time may vary depending on the size of your image
                  <br />
                  (up to 12 minutes if a GPU is not available)
                </Typography>
              ) : diagnosis !== '' ? (
                <Typography sx={{ color: 'blue', fontWeight: 600 }}>
                  {diagnosis}
                </Typography>
              ) : (
                <Typography sx={{ color: 'red' }}>
                  Please run the AAOCA model to get the diagnosis of your input
                  data
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

AaocaResults.propTypes = {
  classes: PropTypes.object,
};

export default AaocaResults;
