import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { DialogDeleteWarning, TableGrid } from 'components';
import { ServerErrorsString, GetData, rowsPerPageBig } from 'helpers';
import {
  QueryProjectCenter,
  MutationDeleteProjectCenter,
} from 'graphql/Projects/utils_project';
import { withStyles } from 'tss-react/mui';
import {
  ProjectSettingCenterToolBar,
  columns,
  EditProjectCenter,
} from './components';
import { IsInvalid } from 'helpers';
const useStyles = () => ({
  root: {
    marginRight: 'auto',
    marginBottom: 0,
  },
});
class TabProjectSettingCenters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idProject: null,
      open_delete: false,
      page: 0,
      limit: 50,
      total: 0,
      selected: [],
      project_centers: [],
      selected_center: null,
      open_edit: false,
    };
    this.holder = {
      getSelected: () => this.state.selected,
      setSelected: null,
    };
  }
  componentDidMount() {
    if (!this.props.isActive) return null;
    const { project_setting } = this.props;
    if (IsInvalid(project_setting)) return;
    const { idProject } = project_setting;
    if (!this.state.idProject && idProject) {
      this.queryCenters(idProject);
    }
  }
  componentDidUpdate() {
    if (!this.props.isActive) return null;
    const { project_setting } = this.props;
    if (IsInvalid(project_setting)) return;
    const { idProject } = project_setting;
    if (!this.state.idProject && idProject) {
      this.queryCenters(idProject);
    }
  }
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleChangePage = newPage => {
    const { idProject } = this.props;
    this.queryCenters(idProject, null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = limit => {
    const { idProject } = this.props;
    this.queryCenters(idProject, null, 0, limit);
  };
  queryCenters = (
    idProject = null,
    newName = null,
    newPage = null,
    newLimit = null
  ) => {
    console.log('queryCenters', { idProject });
    const { loading } = this.state;
    if (loading) return;
    let { name, page, limit } = this.state;
    if (idProject === undefined || !idProject) {
      idProject = this.state.idProject;
    }
    if (idProject < 0) {
      idProject = this.props.project.id;
    }
    if (idProject <= 0) return;
    if (newName && newName !== '') {
      name = newName;
    }
    if (newPage !== undefined && newPage !== null) {
      page = newPage;
      limit = newLimit;
    }
    if (page < 1) page = 0;
    this.setState({ loading: true });
    (async () => {
      QueryProjectCenter(idProject, name, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, project_centers, total } = data.projectCenters;
          if (ok) {
            this.setState({
              loading: false,
              idProject,
              project_centers: project_centers ? project_centers : [],
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, idProject, added: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleDeleteSelected = () => {
    let { deleting, selected, idProject, project_centers } = this.state;
    if (deleting || !selected.length) return;
    let ids = selected.map(x => x.id);
    if (ids.length) {
      this.setState({ deleting: true });
    } else {
      this.setState({ open_delete: false, selected });
      return;
    }
    (async () => {
      MutationDeleteProjectCenter(idProject, ids)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeCenterFromProject;
          if (ok) {
            this.setState({
              deleting: false,
              project_centers: project_centers.filter(
                x => ids.indexOf(x.id) === -1
              ),
              selected: [],
              open_delete: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false, idProject, new_centers: [] });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRowClicked = row => {
    if (row === undefined || !row) {
      return;
    }
    const { project_centers } = this.state;
    if (project_centers === undefined || !project_centers) {
      return;
    }
    // const index = project_centers.map(x => x.id).indexOf(row.id);
  };
  isHighLighted = row => {
    const { selected_center } = this.state;
    if (!selected_center) return false;
    if (!row || row === undefined) return false;
    if (row.id === undefined) return false;
    return selected_center.idCenter === row.id;
  };
  handleUpdateProjectCenters = new_project_centers => {
    const { project_centers, selected } = this.state;
    for (let i = 0; i < new_project_centers.length; i++) {
      let index = project_centers
        .map(x => x.id)
        .indexOf(new_project_centers[i].id);
      if (index !== -1) {
        project_centers[index] = {
          ...new_project_centers[i],
        };
      }
      index = selected.map(x => x.id).indexOf(new_project_centers[i].id);
      if (index !== -1) {
        selected[index] = {
          ...new_project_centers[i],
        };
      }
    }
    this.setState({ project_centers, selected });
  };
  handelAddNewProjectCenters = newProjectCenters => {
    const { project_centers } = this.state;
    for (let i = 0; i < newProjectCenters.length; i++) {
      const { id } = newProjectCenters[i];
      const index = project_centers.map(x => x.id).indexOf(id);
      if (index === -1) {
        project_centers.unshift(newProjectCenters[i]);
      }
    }
    this.setState({ project_centers });
  };
  render() {
    if (!this.props.isActive) return null;
    const { classes, idProject, height } = this.props;
    const { selected } = this.state;
    const title_dialog = selected.length > 1 ? 'Centers' : 'Center';
    let style = null;
    let table_height = height - 140;
    if (table_height < 100) {
      table_height = null;
    }
    if (height) {
      const container_height = height - 5;
      style = {
        height: container_height,
        maxHeight: container_height,
        overflowY: 'auto',
        marginTop: 5,
        paddingTop: 5,
        paddingBottom: 1,
      };
    }
    if (this.state.open_edit) {
      return (
        <EditProjectCenter
          columns={columns}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_edit: false })}
          handleUpdateProjectCenters={this.handleUpdateProjectCenters}
          height={height}
          selected={this.state.selected}
          user={this.props.user}
        />
      );
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ProjectSettingCenterToolBar
            access_code={this.props.access_code}
            classes={classes}
            deleting_centers={this.state.deleting}
            handelAddNewProjectCenters={this.handelAddNewProjectCenters}
            handleAddError={this.props.handleAddError}
            handleClearSelected={() => this.setState({ selected: [] })}
            handleDeleteSelectedCenters={() =>
              this.setState({ open_delete: true })
            }
            handleOpenEdit={() => this.setState({ open_edit: true })}
            handleReLoadCenter={() => this.queryCenters(idProject)}
            holder={this.holder}
            idProject={idProject}
            loading_centers={this.state.loading}
            project_centers={this.state.project_centers}
            selected={this.state.selected}
            user={this.props.user}
          />
        </Grid>
        <Grid item style={style} xs={12}>
          <Grid item sx={{ width: '100%' }} xs={12}>
            <TableGrid
              checkboxSelection
              dense
              disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              height={height - 65}
              loading={this.state.loading}
              onPageChange={this.handleChangePage}
              onRowClicked={this.handleRowClicked}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              onSelectedChanged={selected => this.setState({ selected })}
              page={this.state.page}
              rowCount={this.state.total}
              rows={this.state.project_centers}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageBig}
            />
          </Grid>
        </Grid>
        <DialogDeleteWarning
          handleClose={() =>
            this.setState({ open_delete: !this.state.open_delete })
          }
          handleDeleteFolders={this.handleDeleteSelected}
          loading={this.state.deleting}
          open={this.state.open_delete}
          question_text={`You are about to delete the selected ${title_dialog}, this action will remove the Center from the project, you will lose the linking data to these centers`}
          title_text={`Delete ${title_dialog}`}
        />
      </Grid>
    );
  }
}

TabProjectSettingCenters.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TabProjectSettingCenters, useStyles);
