import { IsInvalid } from 'helpers';

export const columns = [
  {
    field: 'idSequence',
    headerName: 'ID',
    width: 75,
    align: 'left',
    //  valueGetter: params => {
    //    if (IsInvalid(params)) return '';
    //    if (params.getValue === undefined) return '';
    //    let value = params.getValue( 'Sequence');
    //    if (IsInvalid(value)) return '';
    //    return value.idSequence;
    //  },
  },
  {
    field: 'Sequence',
    headerName: 'Sequence Name',
    // width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      let value = params.getValue('Sequence');
      if (IsInvalid(value)) return '';
      return value.name;
    },
  },
  { field: 'total', headerName: '#Series', align: 'left' },
  {
    field: 'button',
    headerName: 'Open',
    align: 'center',
    type: 'button',
    width: 75,
    style: { textTransform: 'none' },
  },
];
