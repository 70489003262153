import React from 'react';
import { Grid, IconButton, styled } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const IconEditButton = styled(IconButton)(() => ({
  borderRadius: '8px',
  height: 'var(--IconButton-size)',
  width: 'var(--IconButton-size)',
  color: 'var(--mui-palette-secondary-main)',
  '--IconButton-size': '40px',
  '--mui-palette-secondary-main': '#32383e',
}));
const CellActions = ({
  row,
  close = false,
  save = false,
  edit = true,
  onClickSave = () => '',
  onClickClose = () => '',
  onClickEdit = () => '',
}) => {
  let ComponentClose = null;
  let width = 0;
  if (close) {
    ComponentClose = (
      <Grid item>
        <IconButton color="secondary" onClick={onClickClose} size="medium">
          <CloseIcon sx={{ fill: 'red' }} />
        </IconButton>
      </Grid>
    );
    width += 40;
  }
  let ComponentSave = null;
  if (save) {
    ComponentSave = (
      <Grid item>
        <IconEditButton color="secondary" onClick={onClickSave} size="medium">
          <SaveIcon sx={{ fill: 'blue' }} />
        </IconEditButton>
      </Grid>
    );
    width += 40;
  }
  let ComponentEdit = null;
  if (edit) {
    ComponentEdit = (
      <Grid item>
        <IconEditButton
          color="secondary"
          onClick={() => onClickEdit(row)}
          size="medium"
          sx={{
            borderRadius: '8px',
            height: 'var(--IconButton-size)',
            width: 'var(--IconButton-size)',
            color: 'var(--mui-palette-secondary-main)',
            '--IconButton-size': '40px',
            '--mui-palette-secondary-main': '#32383e',
          }}>
          <CreateIcon sx={{ fill: '#107569' }} />
        </IconEditButton>
      </Grid>
    );
    width += 40;
  }
  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        width,
      }}>
      {ComponentClose}
      {ComponentSave}
      {ComponentEdit}
    </Grid>
  );
};
export default CellActions;
