import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Chart from 'react-apexcharts';
import useMediaQuery from '@mui/material/useMediaQuery';
const TypoTitle = styled(Typography)(() => ({
  width: '100%',
  textAlign: 'center',
  pt: 1,
  fontWeight: 600,
  maxWidth: 150,
}));
const GridItem = ({ children, ...res }) => {
  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      item
      justifyContent="center"
      {...res}>
      {children}
    </Grid>
  );
};
const box = {
  paddingLeft: '20px',
  paddingRight: '20px',
  gap: '24px',
  width: '100%',
  display: 'flex',
  MozBoxPack: 'center',
  justifyContent: 'center',
  alignItems: 'center',
};
class GridChartItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      textRight,
      textLeft,
      title,
      options,
      running,
      sx = {},
    } = this.props;
    const { isMdActive } = this.props;
    // console.log({ isMdActive });
    let style = {};
    let style_running = {};
    let series = [...options.series]; //...options.series
    if (!isMdActive) style = { maxWidth: '100%' };
    if (running) {
      style = { ...style, color: 'gray' };
      style_running = { color: 'gray' };
      series = [0];
    }
    return (
      <Box sx={{ ...box, ...sx }}>
        <Grid container direction="column">
          <GridItem xs={12}>
            <Grid
              alignItems="center"
              container
              item
              justifyContent="center"
              xs={12}>
              <TypoTitle sx={style}>{title}</TypoTitle>
            </Grid>
            <Chart
              options={{ ...options, series }}
              series={series}
              type="radialBar"
            />
          </GridItem>
          <Grid
            container
            direction="row"
            item
            sx={{
              position: 'relative',
              top: -20,
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              paddingLeft: 1,
              paddingRight: 0,
              ...style_running,
            }}
            xs={12}>
            <Grid item sx={{ fontWeight: 600, maxWidth: 50 }}>
              {textRight}
            </Grid>
            <Grid item sx={{ fontWeight: 600, maxWidth: 50 }}>
              {textLeft}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

GridChartItem.propTypes = {
  textRight: PropTypes.string,
};
const ContainerGridChartItem = props => {
  const theme = useTheme();
  const isMdActive = useMediaQuery(theme.breakpoints.up('md'));
  return <GridChartItem isMdActive={isMdActive} theme={theme} {...props} />;
};

export default ContainerGridChartItem;
