export const IsProjectFullAccess = (admin, access_code, user) => {
  if (user && user.type === 1) {
    return true;
  }
  return admin || access_code === 1 || access_code === 3;
};
export const IsProjectAdmin = (admin, access_code, user) => {
  if (user && user.type === 1) {
    return true;
  }
  return admin || access_code === 3;
};
