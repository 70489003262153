import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

export const MUTATION_RUN_DOCKER_TEMP_ACCESS = gql`
  mutation($inputs: InputDockerTempAccess!) {
    runDockerTempAccess(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      results
    }
  }
`;
export const MutationRunDockerTempAccess = inputs => {
  console.log('MutationRunDockerTempAccess', { inputs });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_RUN_DOCKER_TEMP_ACCESS),
    variables: {
      inputs,
    },
  });
};
