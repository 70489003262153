import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUTATION_REMOVE_FROM_FOLDER = gql`
  mutation($ids: [Int!]!, $delete_series: Boolean) {
    RemoveFromFolder(ids: $ids, delete_series: $delete_series) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationRemoveFromFolder = (ids, delete_series = false) => {
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_REMOVE_FROM_FOLDER),
    variables: { ids, delete_series },
  });
};

const QUERY_SUB_FOLDERS = gql`
  query($idFolder: LongLong) {
    subfolders(idFolder: $idFolder) {
      idFolder
      idParent
      idUser
      link {
        idFolder
        idFolderContent
        code
      }
      download_link
      Size
      name
      total_files
      total_sub_folders
      Owner {
        id
        FullName
      }
      folders_paths {
        idFolder
        name
      }
      ParentFolder {
        idParent
        name
        link {
          idFolder
          idFolderContent
          code
        }
        download_link
        folders_paths {
          idFolder
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QuerySubFolders = idFolder => {
  //
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SUB_FOLDERS),
    variables: { idFolder },
  });
};

const QUERY_HOME_FOLDER = gql`
  query {
    home {
      ok
      Folder {
        idFolder
        idParent
        idUser
        link {
          idFolder
          idFolderContent
          code
        }
        download_link
        Size
        name
        total_files
        total_sub_folders
        Owner {
          id
          FullName
        }
        folders_paths {
          idFolder
          name
        }
        ParentFolder {
          idParent
          name
          link {
            idFolder
            idFolderContent
            code
          }
          download_link
          folders_paths {
            idFolder
            name
          }
          createdAt
          updatedAt
        }
        SubFolders {
          idFolder
          idParent
          idUser
          name
          Size
          name
          folders_paths {
            idFolder
            name
          }
          total_files
          total_sub_folders
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      errors {
        path
        message
      }
    }
  }
`;
export const QueryHomeFolder = () => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_HOME_FOLDER),
    // variables: { idFolder },
  });
};
const QUERY_HOME_SHARED_FOLDER = gql`
  query($idFolder: LongLong!, $level: Int!) {
    shared(idFolder: $idFolder, level: $level) {
      ok
      Folder {
        idFolder
        idParent
        idUser
        link {
          idFolder
          idFolderContent
          code
        }
        download_link
        Size
        name
        total_files
        total_sub_folders
        createdAt
        updatedAt
        Owner {
          id
          FullName
        }
        folders_paths {
          idFolder
          name
        }
        ParentFolder {
          idParent
          name
          folders_paths {
            idFolder
            name
          }
          link {
            idFolder
            idFolderContent
            code
          }
          download_link
          createdAt
          updatedAt
        }
        SubFolders {
          idFolder
          idParent
          idUser
          Owner {
            id
            FullName
          }
          name
          Size
          name
          folders_paths {
            idFolder
            name
          }
          total_files
          total_sub_folders
          createdAt
          updatedAt
        }
        Files {
          idFolderContent
          Owner {
            id
            FullName
          }
          Series {
            idSeries
            idStudy
            Patient {
              PatientName
              PatientBirthDate
              PatientID
            }
            Study {
              StudyDescription
              StudyInstanceUID
              AccessionNumber
            }
            ImageSize {
              Size
            }
            ImageResolution {
              Spacing
            }
            SeriesDescription
            SeriesInstanceUID
            NumberOfSeriesRelatedInstances
            SeriesDate
            SeriesTime
            StationName
            Modality
            Thumbnail

            Size

            createdAt
            updatedAt
          }
          File {
            idFileObject
            original_name
            mimetype
            Size
            Thumbnail
            updatedAt
            createdAt
          }
          Rights {
            idRight
            idFolder
            idFolderContent

            idUser
            idProject
            idCenterTeam
            idCenter

            User {
              id
              FullName
            }
            Project {
              id
              name
            }
            CenterTeam {
              id
              team {
                name
              }
            }
            Center {
              id
              name
            }
            write
            download
            delete
            read
            full
            createdAt
            updatedAt
          }
        }
      }
      errors {
        path
        message
      }
    }
  }
`;
export const QueryHomeShareFolder = (idFolder = -1, level = 0) => {
  console.log('QueryHomeShareFolder', { idFolder, level });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_HOME_SHARED_FOLDER),
    variables: { idFolder, level },
  });
};
const MUTATION_WGET_FOLDER = gql`
  mutation($idFolder: LongLong!) {
    wgetFolder(idFolder: $idFolder) {
      ok
      errors {
        path
        message
      }
      wgetFiles {
        Filename
        link
      }
    }
  }
`;

export const MutationWgetFolder = (idFolder) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_WGET_FOLDER),
    variables: { idFolder },
  });
};
