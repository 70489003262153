import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@mui/material';
import { getComponentValue } from './components/TableCellEdits/utils_edits';
import { getComponentCell } from './components';
import { TableEditCell } from './components/TableCellEdits/utils_cell';

class TableBlockRowEditing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row ? { ...props.row } : null,
      created: null,
    };
  }
  componentDidMount() {
    const { row } = this.state;
    if (row) return;
    this.setState({ row: { ...this.props.row } });
  }
  onClickSave = () => {
    const { row } = this.state;
    this.props.onClickSave(this.props.row, row);
  };
  render() {
    const { headers, onClickClose } = this.props;
    const { row } = this.state;
    return (
      <TableRow>
        <TableEditCell />
        {headers.map((header, index) => {
          const { edit, field } = header;
          if (
            edit === false ||
            field === 'createdAt' ||
            field === 'updatedAt'
          ) {
            const { CellComponent, value, sx } = getComponentCell(
              header,
              row,
              this.props
            );
            return (
              <TableEditCell
                key={`tb-cell-edit-${index}`}
                sx={{ ...sx, backgroundColor: '#eceff1' }}>
                <CellComponent key={`tb-row-cell${index}`} {...value} />
              </TableEditCell>
            );
          }
          const { value, ComponentCellEdit, sx } = getComponentValue(
            header,
            row,
            onClickClose,
            this.onClickSave,
            this
          );
          return (
            <TableEditCell key={`tb-cell-edit-${index}`} sx={{ ...sx }}>
              <ComponentCellEdit {...value} />
            </TableEditCell>
          );
        })}
      </TableRow>
    );
  }
}

TableBlockRowEditing.propTypes = {
  classes: PropTypes.object,
  onClickClose: PropTypes.func,
  onClickSave: PropTypes.func,
};
TableBlockRowEditing.defaultProps = {
  onClickClose: () => '',
  onClickSave: () => '',
};
export default TableBlockRowEditing;
