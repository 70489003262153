import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_USER_TEMP_ACCESS = gql`
  query($email: String!, $page: Int, $limit: Int) {
    allUserTempAccess(email: $email, page: $page, limit: $limit) {
      ok
      total
      errors {
        path
        message
      }
      users {
        id
        email
        code
        runs
        docker {
          id
          name
          operation
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const QueryUsersTempAccess = (email, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_USER_TEMP_ACCESS),
    variables: {
      email,
      page,
      limit,
    },
  });
};

const MUTATION_DELETE_USER_TEMP_ACCESS = gql`
  mutation($ids: [LongLong!]) {
    deleteUserTempAccess(ids: $ids) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteUsersTempAccess = ids => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_USER_TEMP_ACCESS),
    variables: {
      ids,
    },
  });
};
const MUTATION_UPDATE_USER_TEMP_ACCESS = gql`
  mutation($inputs: [InputSettingTemp!]!) {
    updateUserTempAccess(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateUsersTempAccess = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_USER_TEMP_ACCESS),
    variables: {
      inputs,
    },
  });
};
