import { IsInvalid } from 'helpers';
export * from './utils_home_changes';
export const columns_explorer = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    styles: {
      minWidth: 140,
      padding: 0,
      margin: 0,
    },
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const name = params.getValue('name');
      if (name) return name;
      const Series = params.getValue('Series');
      if (Series) return Series.SeriesDescription;
      const File = params.getValue('File');
      if (File) return File.original_name;
      return '';
    },
  },
  {
    id: 'rights',
    field: 'rights',
    label: 'Rights',
    styles: null,
  },
  {
    id: 'updatedAt',
    field: 'updatedAt',
    label: 'Date Modified',
    styles: null,
    valueGetter: params => {
      if (IsInvalid(params)) return 0;
      if (params.getValue === undefined) return 0;
      const value = params.getValue('updatedAt');
      if (value === undefined || !value) return 0;
      return parseInt(value, 10);
    },
  },
  {
    id: 'files',
    field: 'files',
    label: '#Elements',
    styles: null,
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const File = params.getValue('File');
      let text = 0;
      if (File) {
        text = 1;
      } else {
        const Series = params.getValue('Series');
        if (Series) {
          text = Series.NumberOfSeriesRelatedInstances;
        } else {
          const idFolder = params.getValue('idFolder');
          if (idFolder) {
            text = 0;
            const total_sub_folders = params.getValue(
              params.id,
              'total_sub_folders'
            );
            if (total_sub_folders) text += total_sub_folders;
            const total_files = params.getValue('total_files');
            if (total_files) text += total_files;
          }
        }
      }
      return text;
    },
  },
  {
    id: 'Size',
    field: 'Size',
    label: 'Size',
    styles: null,
    align: 'right',
    valueGetter: params => {
      if (IsInvalid(params)) return 0;
      if (params.getValue === undefined) return 0;
      const Size = params.getValue('Size');
      if (Size) return Size;
      const Series = params.getValue('Series');
      if (Series) return Series.Size;
      const File = params.getValue('File');
      if (File) return File.Size;
      return 0;
    },
  },
  {
    id: 'Kind',
    field: 'Kind',
    label: 'Kind',
    align: 'right',
    styles: null,
    valueGetter: params => {
      if (IsInvalid(params)) return 0;
      if (params.getValue === undefined) return 0;
      const idFolder = params.getValue('idFolder');
      if (idFolder) return '--';
      const Series = params.getValue('Series');
      if (Series) return 'dcm';
      const File = params.getValue('File');
      if (File) return File.mimetype;
      return '';
    },
  },
];
