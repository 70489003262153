import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogRemoveFromFolder,
  SnackMessage,
  DialogRenameFolder,
} from 'components';

class FloatingDialogs extends React.Component {
  render() {
    const { state } = this.props;
    const {
      selected_content,
      warning,
      open_rename,
      ReNameFolder,
      open_remove,
    } = state;
    const {
      handleCloseRemove,
      handleRemoveFromFolder,
      holder,
      handleCloseReName,
      handleCloseSnak,
    } = this.props;
    return (
      <React.Fragment>
        <DialogRemoveFromFolder
          handleClose={handleCloseRemove}
          handleRemoveFromFolder={handleRemoveFromFolder}
          holder={holder}
          open={open_remove}
          selected_content={selected_content}
        />
        <DialogRenameFolder
          handleClose={handleCloseReName}
          open={open_rename}
          ReNameFolder={ReNameFolder}
        />
        <SnackMessage
          handleClose={() => handleCloseSnak(false)}
          horizontal="left"
          message_text={warning}
          open={warning !== '' ? true : false}
          type={'warning'}
        />
      </React.Fragment>
    );
  }
}

FloatingDialogs.propTypes = {
  classes: PropTypes.object,
};

export default FloatingDialogs;
