import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@mui/material';
import { cell_check, cell_check_icon, CheckBoxSyan } from '../styles';
import { getComponentCell } from './components';

class TableBlockRowNormal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { row, idSelected, isCheckEnabled, headers, dense } = this.props;
    let CellCheck = null;
    if (isCheckEnabled) {
      CellCheck = (
        <TableCell sx={cell_check}>
          <CheckBoxSyan
            checked={idSelected}
            onClick={() => this.props.handleCheckbox(row)}
            sx={cell_check_icon}
          />
        </TableCell>
      );
    }
    return (
      <TableRow>
        {CellCheck}
        {headers.map((header, index) => {
          let { CellComponent, value, sx } = getComponentCell(
            header,
            row,
            this.props
          );
          if (dense) {
            sx = { paddingTop: 0, paddingBottom: 0 };
          }
          return (
            <TableCell key={`tb-row-cell${index}`} sx={sx}>
              <CellComponent {...value} />
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
}

TableBlockRowNormal.propTypes = {
  classes: PropTypes.object,
  isCheckEnabled: PropTypes.bool,
  onClickEdit: PropTypes.func,
};
TableBlockRowNormal.defaultProps = {
  isCheckEnabled: true,
  onClickEdit: () => '',
};
export default TableBlockRowNormal;
