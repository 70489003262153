import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Paper,
  TextField,
  Autocomplete,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { GetData, getUserFullName, IsInvalid, QueryAllUsers } from 'helpers';

import { useStyles } from '../Styles';
import { inputProps } from 'common';

class SearchUsersInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      email: '',
      loading: false,
      users: [],
      open: false,
      length: 0,
      total: 0,
    };
    if (props.holder) {
      props.holder.clear = () => {
        this.setState({
          user: null,
          email: '',
          users: [],
        });
      };
    }
  }

  componentDidUpdate() {
    const { length, email } = this.state;
    if (length !== email.length) {
      this.runSearchUser(email);
    }
  }

  handleUserChanged = user => {
    if (IsInvalid(user)) return;
    this.setState({ user, length: user.FullName.length });
  };
  handleUserNameChanged = email => {
    this.setState({ email });
    this.runSearchUser(email);
  };
  runSearchUser = email => {
    const { loading } = this.state;
    if (loading) return;
    const { filter } = this.props;
    this.setState({ loading: true, length: email.length, users: [] });
    let name = null;
    if (this.props.withname) {
      name = email;
      email = null;
    }
    (async () => {
      QueryAllUsers(email, name, 0, 5, filter)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, users: input_users } = data.allUsers;
          if (ok) {
            let users = input_users.map(x => {
              let address = {
                city: 'Undefined',
                country: 'Undefined',
              };
              if (x.address) {
                address = {
                  city: x.address.city,
                  country: x.address.country,
                };
              }
              return {
                id: x.id,
                FullName: getUserFullName(x),
                email: x.email,
                avatar: x.avatar,
                createdAt: `${parseInt(x.createdAt, 10)}`,
                phone: x.phone,
                address,
              };
            });
            if (!users.length) {
              users.push({ FullName: 'Not found' });
            }
            // console.log({ users, input_users });
            this.setState({ users, loading: false, total: users.length });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ users: [], loading: false });
        });
    })();
  };
  handleOpen = open => {
    this.setState({ open });
  };
  handleAddUser = () => {
    if (!this.state.user) return;
    const { user } = this.state;
    if (user && Object.keys(user).length === 0) {
      return;
    }
    console.log({ user });
    this.setState({ email: '', users: [], length: 0, user: null }, () => {
      this.props.handleAddUser(user);
    });
  };

  render() {
    const { classes, style } = this.props;
    const { users, open, loading, email } = this.state;
    // console.log({ users });
    if (this.state.user) {
      const index = users.map(x => x.id).indexOf(this.state.user.id);
      if (index === -1) users.push(this.state.user);
    }
    return (
      <Paper className={classes.root} style={{ ...style, padding: 10 }}>
        <SearchIcon className={classes.icon} />
        <Autocomplete
          getOptionLabel={option => {
            if (option) return option.FullName;
            return '';
          }}
          id="search-users-input"
          inputValue={email}
          isOptionEqualToValue={(option, value) => {
            if (
              email === '' ||
              value.FullName === undefined ||
              option.FullName === undefined
            )
              return false;
            return option.FullName === value.FullName;
          }}
          loading={loading}
          onChange={(event, newValue) => {
            this.handleUserChanged(newValue);
          }}
          onClose={() => {
            this.handleOpen(false);
          }}
          onInputChange={(event, newInputValue) => {
            this.handleUserNameChanged(newInputValue);
          }}
          onOpen={() => {
            this.handleOpen(true);
          }}
          open={open}
          options={users}
          renderInput={params => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
                style: {
                  borderWidth: 0,
                  margin: 0,
                  border: 'none !important',
                  padding: 0,
                },
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </React.Fragment>
                ),
                ...inputProps,
              }}
              name="user"
              onKeyDown={event => {
                event.persist();
                const { key } = event;
                if (key === 'Enter') {
                  this.handleAddUser();
                }
              }}
              placeholder="Search Users..."
              style={{
                // marginLeft: '6px',
                margin: 0,
                // height,
                borderWidth: 0,
                border: 'none !important',
                // paddingTop: -10
              }}
              variant="standard"
            />
          )}
          size="small"
          style={{ width: '100%', border: 'none !important' }}
          value={this.state.user}
        />
      </Paper>
    );
  }
}

SearchUsersInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  withname: PropTypes.bool,
};
SearchUsersInput.defaultProps = {
  filter: null,
  handleAddUser: () => '',
  withname: true,
};

export default withStyles(SearchUsersInput, useStyles);
