import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';
import { ComboBox, ButtonLoading } from 'components';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';
import { QueryProjectAccessLevels } from 'graphql/Projects';
const default_member = {
  idUser: -1,
  access_level: { id: -1, description: 'Not found' },
  email_notification: false,
  upload_notification: false,
};
class MemberSettingEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      member_setting: { ...default_member },
      access_levels: [],
      empty: false,
    };
  }
  componentDidMount() {
    this.handleLoadAccessLevels();
  }
  handleLoadAccessLevels = () => {
    const { loading, empty } = this.state;
    if (loading || empty) return;
    this.setState({ loading: true });
    (async () => {
      QueryProjectAccessLevels()
        .then(res => {
          const data = GetData(res);
          const { ok, errors, access_levels } = data.projectAccessLevels;
          if (ok) {
            const blockedIndex = access_levels.findIndex(
              level => level.access_code === 0
            );
            if (blockedIndex !== -1) {
              const [blocked] = access_levels.splice(blockedIndex, 1);
              access_levels.unshift(blocked);
            }
            this.setState({ access_levels, loading: false, empty: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  componentDidUpdate() {
    const { member_setting } = this.state;
    const { member } = this.props;
    console.log({ member, member_setting });
    if (IsInvalid(member)) {
      if (member_setting.idUser !== -1) {
        this.setState({ member_setting: { ...default_member } });
      }
    } else if (member_setting.idUser !== member.idUser) {
      this.setState({ member_setting: member });
    }
  }
  handleCheckChange = event => {
    let { member_setting } = this.state;
    if (!member_setting) return;
    const name = event.currentTarget.name;
    const checked = event.currentTarget.checked;
    member_setting = { ...member_setting, [name]: checked };
    this.setState({ member_setting });
    this.props.handleMemberSettingChanged(member_setting);
  };
  handleAccessLevelChanged = access_description => {
    let { member_setting, access_levels } = this.state;
    if (!member_setting) return;
    const index = access_levels.findIndex(
      x => x.description === access_description
    );
    console.log({ access_description, index });
    if (index !== -1) {
      member_setting = {
        ...member_setting,
        idAccessLevel: access_levels[index].id,
        access_level: { ...access_levels[index] },
      };
      this.setState({ member_setting });
      this.props.handleMemberSettingChanged(member_setting);
    }
  };
  render() {
    const { member_setting, access_levels } = this.state;
    const { access_level } = member_setting;
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        spacing={3}>
        <Grid item md={4} sm={4} xs={12}>
          <ComboBox
            disabled={!this.state.member_setting}
            label="Access Level"
            onChange={this.handleAccessLevelChanged}
            placeholder="To select click member"
            size="small"
            titles={access_levels.map(x => x.description)}
            value={access_level.description}
          />
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={member_setting.email_notification}
                disabled={this.state.member_setting.access_level.id < 0}
                name="email_notification"
                onChange={this.handleCheckChange}
              />
            }
            label="Email Notification"
          />
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={member_setting.upload_notification}
                disabled={this.state.member_setting.access_level.id < 0}
                name="upload_notification"
                onChange={this.handleCheckChange}
              />
            }
            label="Upload Notification"
          />
        </Grid>
        <Grid item xs={2}>
          <ButtonLoading
            disable_button={!this.props.IsChanged()}
            handleClick={this.props.handleUpdateSettings}
            loading={this.props.updating}
            name="Save"
            transform={false}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }
}

MemberSettingEditor.propTypes = {
  member: PropTypes.object,
};
MemberSettingEditor.defaultProps = {
  handleMemberSettingChanged: () => '',
};
export default MemberSettingEditor;
