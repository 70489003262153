import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const columns = [
  { field: 'id', headerName: 'ID', width: 75, align: 'left' },
  { field: 'name', headerName: 'Name', align: 'left', width: 110 },
  { field: 'os', headerName: 'O.S.', align: 'left', width: 110 },
  {
    field: 'architecture',
    headerName: 'Architecture',
    align: 'left',
    width: 150,
  },
  { field: 'version', headerName: 'Version', align: 'left', width: 110 },
  {
    field: 'filename',
    headerName: 'FileName',
    align: 'left',
    width: 130,
  },
  {
    field: 'downloads',
    headerName: 'Downloads',
    align: 'left',
    width: 140,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 222,
    type: 'datetime',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('updatedAt');
      if (value === undefined || !value) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
