import { IsInvalid, getUserFullName } from 'helpers';

export const columns = [
  {
    field: 'id',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 30,
    },
    align: 'left',
  },
  {
    field: 'unitname',
    headerName: 'Unit Name',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('unit');
      if (value === undefined || !value) return '';
      const { name } = value;
      if (name === undefined || !name) return '';
      return name;
    },
  },
  {
    field: 'admin',
    headerName: 'Admin',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('unit');
      if (value === undefined || !value) return '';
      const { Admin } = value;
      if (Admin === undefined || !Admin) return '';
      return getUserFullName(Admin);
    },
  },
  {
    field: 'access',
    headerName: 'Access',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('unit');
      if (value === undefined || !value) return '';
      const { setting } = value;
      if (setting === undefined || !setting) return '';
      return setting.read;
    },
  },
  {
    field: 'api',
    headerName: 'API',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const value = params.getValue('unit');
      if (value === undefined || !value) return '';
      const { api } = value;
      if (api === undefined || !api) return '';
      return api;
    },
  },
  {
    field: 'users',
    headerName: '#Users',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const users = params.getValue('users');
      if (users === undefined || !users) return '';
      return users.value;
    },
  },
  {
    field: 'patients',
    headerName: '#Patients',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const patients = params.getValue('patients');
      if (patients === undefined || !patients) return '';
      return patients.value;
    },
  },
  {
    field: 'studies',
    headerName: '#Studies',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const studies = params.getValue('studies');
      if (studies === undefined || !studies) return '';
      return studies.value;
    },
  },
  {
    field: 'series',
    headerName: '#Series',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const series = params.getValue('series');
      if (series === undefined || !series) return '';
      return series.value;
    },
  },
  {
    field: 'dicoms',
    headerName: '#DICOMs',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const dicoms = params.getValue('dicoms');
      if (dicoms === undefined || !dicoms) return '';
      return dicoms.value;
    },
  },
  {
    field: 'files',
    headerName: '#Files',
    align: 'left',
    valueGetter: params => {
      if (IsInvalid(params)) return '';
      if (params.getValue === undefined) return '';
      const files = params.getValue('files');
      if (files === undefined || !files) return '';
      return files.value;
    },
  },
];
