import React from 'react';
import PropTypes from 'prop-types';
import TableBlockHeader from './TableBlockHeader';
import { Table, TableBody, TableContainer } from '@mui/material';
import { TableBlockRow } from './components';

class TableBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      data: [],
      selected: [],
      page: 0,
      limit: 10,
      rows_editing: [],
    };
    const { holder } = props;
    if (holder) {
      holder.clean = () => this.setState({ selected: [] });
    }
  }
  componentDidMount() {
    const { data, headers } = this.props;
    this.setState({ data, headers });
  }
  componentDidUpdate() {
    const { data, page, limit } = this.props;
    let state = {};
    if (data.length !== this.state.data.length) {
      state = { data };
    }
    if (page !== undefined && page !== this.state.page) state.page = page;
    if (limit !== undefined && limit !== this.state.limit) state.limit = limit;
    if (Object.keys(state).length) this.setState(state);
  }
  IsSelected = row => {
    const { selected } = this.state;
    const { idKey } = this.props;
    return selected.findIndex(r => r[idKey] === row[idKey]) !== -1;
  };
  IsEditing = row => {
    const { rows_editing } = this.state;
    return rows_editing.findIndex(r => r.user.name === row.user.name) !== -1;
  };
  handleEdit = row => {
    if (this.IsEditing(row)) return;
    const { rows_editing } = this.state;
    rows_editing.push(row);
    this.setState({ rows_editing });
  };
  handleSave = (row, new_row) => {
    let { rows_editing, data } = this.state;
    const { idKey } = this.props;
    let index = rows_editing.findIndex(r => r[idKey] === row[idKey]);
    if (index !== -1) {
      rows_editing.splice(index, 1);
    }
    index = data.findIndex(r => r[idKey] === row[idKey]);
    if (index !== -1) {
      data[index] = { ...new_row };
    }
    this.setState({ rows_editing, data }, () => {
      this.props.handleSave(data, new_row);
    });
  };
  handleClose = row => {
    let { rows_editing } = this.state;
    const { idKey } = this.props;
    const index = rows_editing.findIndex(r => r[idKey] === row[idKey]);
    if (index !== -1) {
      rows_editing.splice(index, 1);
    }
    this.setState({ rows_editing });
  };
  handleCheckbox = row => {
    let { selected } = this.state;
    const { idKey } = this.props;
    const index = selected.findIndex(r => r[idKey] === row[idKey]);
    if (index === -1) selected.push(row);
    else selected.splice(index, 1);
    this.setState({ selected }, () => {
      this.props.onChangeSelected(selected);
    });
  };
  render() {
    const { height_table, dense, paginationMode } = this.props;
    let data = this.state.data;
    if (paginationMode === 'client') {
      const { page, limit } = this.state;
      data = this.state.data.slice(page * limit, page * limit + limit);
    }
    return (
      <TableContainer
        sx={{
          height: height_table,
          overflowY: 'auto', // Enables vertical scrolling
          overflow: 'auto',
        }}>
        <Table>
          <TableBlockHeader
            setState={state => this.setState(state)}
            state={this.state}
          />
          <TableBody>
            {data.map((row, index) => {
              const idSelected = this.IsSelected(row);
              return (
                <TableBlockRow
                  dense={dense}
                  handleCheckbox={this.handleCheckbox}
                  handleClose={this.handleClose}
                  handleEdit={this.handleEdit}
                  handleSave={this.handleSave}
                  headers={this.state.headers}
                  idSelected={idSelected}
                  key={index}
                  row={row}
                  setState={state => this.setState(state)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

TableBlock.propTypes = {
  classes: PropTypes.object,
  paginationMode: PropTypes.string,
};
TableBlock.defaultProps = {
  idKey: 'id',
  onChangeSelected: () => '',
  paginationMode: 'client',
};
export default TableBlock;
