import React from 'react';
import PropTypes from 'prop-types';
import TableBlockRowEditing from './TableBlockRowEditing';
import TableBlockRowNormal from './TableBlockRowNormal';

class TableBlockRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }
  handleEdit = row => {
    const { isEditing } = this.state;
    if (isEditing) return;
    this.setState({ isEditing: true }, () => {
      this.props.handleEdit(row);
    });
  };
  handleSave = (row, new_row) => {
    this.setState({ isEditing: false }, () => {
      this.props.handleSave(row, new_row);
    });
  };
  handleClose = row => {
    this.setState({ isEditing: false }, () => {
      this.props.handleClose(row);
    });
  };
  render() {
    const { isEditing } = this.state;
    const { row, idSelected, dense } = this.props;
    if (isEditing) {
      return (
        <TableBlockRowEditing
          dense={dense}
          headers={this.props.headers}
          idSelected={idSelected}
          onClickClose={this.handleClose}
          onClickSave={this.handleSave}
          row={row}
        />
      );
    } else {
      return (
        <TableBlockRowNormal
          dense={dense}
          handleCheckbox={this.props.handleCheckbox}
          headers={this.props.headers}
          idSelected={idSelected}
          onClickEdit={this.handleEdit}
          row={row}
          setState={state => this.setState(state)}
        />
      );
    }
  }
}

TableBlockRow.propTypes = {
  classes: PropTypes.object,
};

export default TableBlockRow;
